import { ChainId } from '@ape.swap/sdk'
import addresses from 'config/constants/contracts'
import tokens from 'config/constants/tokens'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getCakeAddress = () => {
  return getAddress(tokens.cake.address)
}
export const getSyrupAddress = () => {
  return getAddress(tokens.syrup.address)
}
export const getVNAddress = () => {
  return getAddress(tokens.vn.address)
}
export const getSSTAddress = () => {
  return getAddress(tokens.bethustlers.address)
}
export const getlgcAddress = () => {
  return getAddress(tokens.lgc.address)
}
export const getfpsAddress = () => {
  return getAddress(tokens.fps.address)
}
export const getbtntyAddress = () => {
  return getAddress(tokens.btnty.address)
}
export const gettripAddress = () => {
  return getAddress(tokens.trip.address)
}
export const getskeletonAddress = () => {
  return getAddress(tokens.skeleton.address)
}
export const getbuildAddress = () => {
  return getAddress(tokens.build.address)
}
export const getBusdAddress = () => {
  return getAddress(tokens.busd.address)
}
export const getHmttAddress = () => {
  return getAddress(tokens.hmtt.address)
}
export const geLiraAddress = () => {
  return getAddress(tokens.lira.address)
}
export const getVIZSLAHVIAddress = () => {
  return getAddress(tokens.lp.address)
}
export const getFarm1Address = () => {
  return getAddress(tokens.farm1.address)
}
export const getFarm2Address = () => {
  return getAddress(tokens.farm2.address)
}
export const getFarm3Address = () => {
  return getAddress(tokens.farm3.address)
}
export const getFarm4Address = () => {
  return getAddress(tokens.farm4.address)
}
export const getFarm5Address = () => {
  return getAddress(tokens.farm5.address)
}
export const getFarm6Address = () => {
  return getAddress(tokens.farm6.address)
}
export const getFarm7Address = () => {
  return getAddress(tokens.farm7.address)
}
export const getFarm8Address = () => {
  return getAddress(tokens.farm8.address)
}
export const getFarmNAddress = () => {
  return getAddress(tokens.farmN.address)
}
export const getFarm9Address = () => {
  return getAddress(tokens.farm9.address)
}
export const getFarm10Address = () => {
  return getAddress(tokens.farm10.address)
}
export const getFarm11Address = () => {
  return getAddress(tokens.farm11.address)
}
export const getFarm12Address = () => {
  return getAddress(tokens.farm12.address)
}
export const getFarm13Address = () => {
  return getAddress(tokens.farm13.address)
}
export const getFarm14Address = () => {
  return getAddress(tokens.farm14.address)
}
export const getFarm15Address = () => {
  return getAddress(tokens.farm15.address)
}
export const getFarm16Address = () => {
  return getAddress(tokens.farm16.address)
}
export const getFarm17Address = () => {
  return getAddress(tokens.farm17.address)
}
export const getFarm18Address = () => {
  return getAddress(tokens.farm18.address)
}
export const getFarm19Address = () => {
  return getAddress(tokens.farm19.address)
}
export const getFarm20Address = () => {
  return getAddress(tokens.farm20.address)
}
export const getFarm21Address = () => {
  return getAddress(tokens.farm21.address)
}
export const getFarm22Address = () => {
  return getAddress(tokens.farm22.address)
}
export const getFarm23Address = () => {
  return getAddress(tokens.farm23.address)
}
export const getFarm24Address = () => {
  return getAddress(tokens.farm24.address)
}
export const getFarm26Address = () => {
  return getAddress(tokens.farm26.address)
}
export const getFarm27Address = () => {
  return getAddress(tokens.farm27.address)
}
export const getFarm28Address = () => {
  return getAddress(tokens.farm28.address)
}
export const getFarm29Address = () => {
  return getAddress(tokens.farm29.address)
}
export const getFarm25Address = () => {
  return getAddress(tokens.farm25.address)
}
export const getLAMEAAddress = () => {
  return getAddress(tokens.lamea.address)
}
export const getFRTCAddress = () => {
  return getAddress(tokens.FartCoin.address)
}
export const getbeethAddress = () => {
  return getAddress(tokens.bethustlers.address)
}
export const getHVIBNBAddress = () => {
  return getAddress(tokens.lp2.address)
}
export const getVIZSLASWAPLAMEAAddress = () => {
  return getAddress(tokens.lp3.address)
}
export const getHVIAddress = () => {
  return getAddress(tokens.hvi.address)
}
export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getWbnbAddress = () => {
  return getAddress(tokens.wbnb.address)
}
export const getbabyAddress = () => {
  return getAddress(tokens.baby.address)
}
export const getbtcAddress = () => {
  return getAddress(tokens.btcb.address)
}
export const getusdtAddress = () => {
  return getAddress(tokens.usdt.address)
}
export const getcubyAddress = () => {
  return getAddress(tokens.cuby.address)
}
export const getethAddress = () => {
  return getAddress(tokens.eth.address)
}
export const getetcAddress = () => {
  return getAddress(tokens.etc.address)
}
export const getltcAddress = () => {
  return getAddress(tokens.ltc.address)
}
export const getbvcAddress = () => {
  return getAddress(tokens.bvc.address)
}
export const getbabyvizslaAddress = () => {
  return getAddress(tokens.babyvizsla.address)
}
export const getataliraAddress = () => {
  return getAddress(tokens.atalira.address)
}
export const getliraAddress = () => {
  return getAddress(tokens.lira.address)
}
export const getaceAddress = () => {
  return getAddress(tokens.ace.address)
}
export const getexzoAddress = () => {
  return getAddress(tokens.exzo.address)
}
export const getLotteryV2Address = () => {
  return getAddress(addresses.lotteryV2)
}
export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile)
}
export const getPancakeRabbitsAddress = () => {
  return getAddress(addresses.pancakeRabbits)
}
export const getBunnyFactoryAddress = () => {
  return getAddress(addresses.bunnyFactory)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getPointCenterIfoAddress = () => {
  return getAddress(addresses.pointCenterIfo)
} 
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial)
}
export const getTradingCompetitionAddress = () => {
  return getAddress(addresses.tradingCompetition)
}
export const getEasterNftAddress = () => {
  return getAddress(addresses.easterNft)
}
export const getLockerAddress = () => {
  return getAddress(addresses.locker)
}
export const getCakeVaultAddress = () => {
  return getAddress(addresses.cakeVault)
}
export const getCakeVaultAddress2 = () => {
  return getAddress(addresses.cakeVault2)
}
export const getCakeVaultAddress3 = () => {
  return getAddress(addresses.cakeVault11)
}
export const getCakeVaultAddress4 = () => {
  return getAddress(addresses.cakeVault12)
}
export const getCakeVaultAddress5 = () => {
  return getAddress(addresses.cakeVault13)
}
export const getCakeVaultAddress6 = () => {
  return getAddress(addresses.cakeVault14)
}
export const getCakeVaultAddressAirSST = () => {
  return getAddress(addresses.cakeVaultAirSST)
}
export const getCakeVaultAddress7 = () => {
  return getAddress(addresses.cakeVault15)
}
export const getCakeVaultAddress8 = () => {
  return getAddress(addresses.cakeVault16)
}
export const getCakeVaultAddress9 = () => {
  return getAddress(addresses.cakeVault17)
}
export const getCakeVaultAddress10 = () => {
  return getAddress(addresses.cakeVault18)
}
export const getPredictionsAddress = () => {
  return getAddress(addresses.predictions)
}
export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle)
}
export const getBunnySpecialCakeVaultAddress = () => {
  return getAddress(addresses.bunnySpecialCakeVault)
}
export const getBunnySpecialPredictionAddress = () => {
  return getAddress(addresses.bunnySpecialPrediction)
}
export const getFarmAuctionAddress = () => {
  return getAddress(addresses.farmAuction)
}

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import BigNumber from 'bignumber.js/bignumber'
import { useWeb3React } from '@web3-react/core/core/core'
import { Heading, Flex, Box, Text, Skeleton} from '@pancakeswap/uikit'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import { useTranslation } from 'contexts/Localization'
import usePersistState from 'hooks/usePersistState'
import { useFetchPublicPoolsData, usePools, useFetchCakeVault, useCakeVault } from 'state/pools2/hooks'
import { usePollFarmsData} from 'state/farms/hooks'
import { latinise } from 'utils/latinise'
import FlexLayout from 'components/Layout/Flex'
import Page from 'components/Layout/Page'
import PageHeader from 'components/PageHeader'
import SearchInput from 'components/SearchInput'
import Select, { OptionProps } from 'components/Select/Select'
import { Pool } from 'state/types'
import Loading from 'components/Loading'
import PoolCard from './components/PoolCard'
import PoolCard2 from './components/PoolCard2'
import PoolCard3 from './components/PoolCard3'
import PoolCard4 from './components/PoolCard4'
import PoolCard5 from './components/PoolCard5'
import PoolCard6 from './components/PoolCard6'
import PoolCard7 from './components/PoolCard7'
import PoolTabButtons from './components/PoolTabButtons'
import BountyCard from './components/BountyCard'
import PoolsTable from './components/PoolsTable/PoolsTable'
import { ViewMode } from './components/ToggleView/ToggleView'
import { getAprData, getCakeVaultEarnings } from './helpers'
import FarmsPoolsRowLoader from '../Home/components/FarmsPoolsRowLoader'

const runningLightAnimation = keyframes`
  0% {
    text-shadow: 0 0 2.5px #62d5a3, 0 0 5px #62d5a3, 0 0 7.5px #62d5a3, 0 0 10px #62d5a3;
  }
  50% {
    text-shadow: 0 0 2.5px #62d5a3, 0 0 5px #62d5a3, 0 0 7.5px #62d5a3, 0 0 10px #62d5a3;
  }
  100% {
    text-shadow: 0 0 2.5px #62d5a3, 0 0 5px #62d5a3, 0 0 7.5px #62d5a3, 0 0 10px #62d5a3;
  }
`;

const Title = styled(Heading)`
    animation: ${runningLightAnimation} 2s infinite; /* Alkalmazzuk az animációt */
    color: #fff;
`;

const CardLayout = styled(FlexLayout)`
  justify-content: center;
`

const PoolControls = styled(Flex)`
display: flex;
width: 100%;
align-items: center;
position: relative;

justify-content: space-between;
flex-direction: column;
margin-bottom: 50px;
border-radius: 16px;


${({ theme }) => theme.mediaQueries.xl} {
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 32px;
  background-color: rgb(19,47,46) ;
  border: solid 1.5px ${({ theme }) => theme.colors.cardBorder};
  margin-bottom: 50;
}
${({ theme }) => theme.mediaQueries.md} {
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 32px;
  background-color: rgb(19,47,46) ;
  border: solid 1.5px ${({ theme }) => theme.colors.cardBorder};
  margin-bottom: 50;
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0px;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
    padding: 0;
  }
`

const LabelWrapper = styled.div`
  > ${Text} {
    font-size: 12px;
  }
`

const ControlStretch = styled(Flex)`
  > div {
    flex: 1;
  }
`

const NUMBER_OF_POOLS_VISIBLE = 12

const FarmPools: React.FC = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { pools: poolsWithoutAutoVault, userDataLoaded } = usePools(account)
  const [stakedOnly, setStakedOnly] = usePersistState(false, { localStorageKey: 'pancake_pool_staked' })
  const [numberOfPoolsVisible, setNumberOfPoolsVisible] = useState(NUMBER_OF_POOLS_VISIBLE)
  const [observerIsSet, setObserverIsSet] = useState(false)
  const loadMoreRef = useRef<HTMLDivElement>(null)
  const [viewMode, setViewMode] = usePersistState(ViewMode.TABLE, { localStorageKey: 'pancake_pool_view' })
  const [searchQuery, setSearchQuery] = useState('')
  const [sortOption, setSortOption] = useState('hot')
  const chosenPoolsLength = useRef(0)
  const chosenPoolsLength3 = useRef(0)
  const chosenPoolsLength2 = useRef(0)
  const chosenPoolsLength4 = useRef(0)
  const chosenPoolsLength5 = useRef(0)
  const chosenPoolsLength6 = useRef(0)
  const chosenPoolsLength7 = useRef(0)
  const {
    userData: { cakeAtLastUserAction, userShares },
    fees: { performanceFee },
    pricePerFullShare,
    totalCakeInVault,
  } = useCakeVault()
  const accountHasVaultShares = userShares && userShares.gt(0)
  const performanceFeeAsDecimal = performanceFee && performanceFee / 100

  const pools = useMemo(() => {
    const cakePool = poolsWithoutAutoVault.find((pool) => pool.sousId !== -1)
    const cakeAutoVault = { ...cakePool, isAutoVault: false }
    return [cakeAutoVault, ...poolsWithoutAutoVault]
  }, [poolsWithoutAutoVault])

  const all = pools.filter((pool) => pool.sousId !== -1 && pool.tokensymbol5  === 'ALL2')
  const all2 = pools.filter((pool) => pool.sousId !== -1 && pool.tokensymbol5  === 'ALL3')
  const all3 = pools.filter((pool) => pool.sousId !== -1 && pool.tokensymbol5  === 'ALL4')
  const all4 = pools.filter((pool) => pool.sousId !== -1 && pool.tokensymbol5  === 'ALL5')
  const all5 = pools.filter((pool) => pool.sousId !== -1 && pool.tokensymbol5  === 'ALL6')
  const all6 = pools.filter((pool) => pool.sousId !== -1 && pool.tokensymbol5  === 'ALL7')
  const all7 = pools.filter((pool) => pool.sousId !== -1 && pool.tokensymbol5  === 'ALL8')
  const finished = pools.filter((pool) => pool.sousId !== -1 && pool.tokensymbol5  === 'FINISHED2')
  const finished2 = pools.filter((pool) => pool.sousId !== -1 && pool.tokensymbol5  === 'FINISHED3')
  const finished3 = pools.filter((pool) => pool.sousId !== -1 && pool.tokensymbol5  === 'FINISHED4')
  const finished4 = pools.filter((pool) => pool.sousId !== -1 && pool.tokensymbol5  === 'FINISHED5')
  const finished5 = pools.filter((pool) => pool.sousId !== -1 && pool.tokensymbol5  === 'FINISHED6')
  const finished6 = pools.filter((pool) => pool.sousId !== -1 && pool.tokensymbol5  === 'FINISHED7')
  const finished7 = pools.filter((pool) => pool.sousId !== -1 && pool.tokensymbol5  === 'FINISHED8')


  // TODO aren't arrays in dep array checked just by reference, i.e. it will rerender every time reference changes?
  const [finishedPools, openPools] = useMemo(() => partition(pools, (pool) => pool.isFinished), [pools])
  const stakedOnlyFinishedPools = useMemo(
    () =>
      finishedPools.filter((pool) => {
        if (pool.isAutoVault) {
          return accountHasVaultShares
        }
        return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
      }),
    [finishedPools, accountHasVaultShares],
  )
  const stakedOnlyfinished = useMemo(
    () =>
      finished.filter((pool) => {
        if (pool.isAutoVault) {
          return accountHasVaultShares
        }
        return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
      }),
    [finished, accountHasVaultShares],
  )
  const stakedOnlyAllPools  = useMemo(
    () =>
      all .filter((pool) => {
        if (pool.isAutoVault) {
          return accountHasVaultShares
        }
        return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
      }),
    [all, accountHasVaultShares],
  )
  const stakedOnlyAl2lPools = useMemo(
    () =>
      all2.filter((pool) => {
        if (pool.isAutoVault) {
          return accountHasVaultShares
        }
        return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
      }),
    [all2, accountHasVaultShares],
  )
  const stakedOnlyAl3lPools = useMemo(
    () =>
      all3.filter((pool) => {
        if (pool.isAutoVault) {
          return accountHasVaultShares
        }
        return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
      }),
    [all3, accountHasVaultShares],
  )
  const stakedOnlyAl4lPools = useMemo(
    () =>
      all4.filter((pool) => {
        if (pool.isAutoVault) {
          return accountHasVaultShares
        }
        return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
      }),
    [all4, accountHasVaultShares],
  )
  const stakedOnlyAl5lPools = useMemo(
    () =>
      all5.filter((pool) => {
        if (pool.isAutoVault) {
          return accountHasVaultShares
        }
        return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
      }),
    [all5, accountHasVaultShares],
  )
  const stakedOnlyAl6lPools = useMemo(
    () =>
      all6.filter((pool) => {
        if (pool.isAutoVault) {
          return accountHasVaultShares
        }
        return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
      }),
    [all6, accountHasVaultShares],
  )
  const stakedOnlyAl7lPools = useMemo(
    () =>
      all7.filter((pool) => {
        if (pool.isAutoVault) {
          return accountHasVaultShares
        }
        return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
      }),
    [all7, accountHasVaultShares],
  )
  const hasStakeInFinishedPools = stakedOnlyFinishedPools.length > 0

  usePollFarmsData()
  useFetchCakeVault()
  useFetchPublicPoolsData()

  useEffect(() => {
    const showMorePools = (entries) => {
      const [entry] = entries
      if (entry.isIntersecting) {
        setNumberOfPoolsVisible((poolsCurrentlyVisible) => {
          if (poolsCurrentlyVisible <= chosenPoolsLength.current) {
            return poolsCurrentlyVisible + NUMBER_OF_POOLS_VISIBLE
          }
          if (poolsCurrentlyVisible <= chosenPoolsLength2.current) {
            return poolsCurrentlyVisible + NUMBER_OF_POOLS_VISIBLE
          }
          if (poolsCurrentlyVisible <= chosenPoolsLength3.current) {
            return poolsCurrentlyVisible + NUMBER_OF_POOLS_VISIBLE
          }
          if (poolsCurrentlyVisible <= chosenPoolsLength4.current) {
            return poolsCurrentlyVisible + NUMBER_OF_POOLS_VISIBLE
          }
          if (poolsCurrentlyVisible <= chosenPoolsLength5.current) {
            return poolsCurrentlyVisible + NUMBER_OF_POOLS_VISIBLE
          }
          if (poolsCurrentlyVisible <= chosenPoolsLength6.current) {
            return poolsCurrentlyVisible + NUMBER_OF_POOLS_VISIBLE
          }
          if (poolsCurrentlyVisible <= chosenPoolsLength7.current) {
            return poolsCurrentlyVisible + NUMBER_OF_POOLS_VISIBLE
          }
          return poolsCurrentlyVisible
        })
      }
    }

    if (!observerIsSet) {
      const loadMoreObserver = new IntersectionObserver(showMorePools, {
        rootMargin: '0px',
        threshold: 1,
      })
      loadMoreObserver.observe(loadMoreRef.current)
      setObserverIsSet(true)
    }
  }, [observerIsSet])

  const showFinishedPools = location.pathname.includes('history')
  const showvizslapools = location.pathname.includes('vizslapools')
  const showallpools = location.pathname.includes('all')
  const showall2pools = location.pathname.includes('all')
  const showall3pools = location.pathname.includes('all')
  const showall4pools = location.pathname.includes('all')
  const showall5pools = location.pathname.includes('all')
  const showall6pools = location.pathname.includes('all')
  const showall7pools = location.pathname.includes('all')
  const showfinished = location.pathname.includes('finished')
  const showantiwhalepools = location.pathname.includes('antiwhalepools')

  const handleChangeSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleSortOptionChange = (option: OptionProps): void => {
    setSortOption(option.value)
  }

  const sortPools = (poolsToSort: Pool[]) => {
    switch (sortOption) {
      case 'apr':
        // Ternary is needed to prevent pools without APR (like MIX) getting top spot
        return orderBy(
          poolsToSort,
          (pool: Pool) => (pool.apr ? getAprData(pool, performanceFeeAsDecimal).apr : 0),
          'desc',
        )
      case 'earned':
        return orderBy(
          poolsToSort,
          (pool: Pool) => {
            if (!pool.userData || !pool.earningTokenPrice) {
              return 0
            }
            return pool.isAutoVault
              ? getCakeVaultEarnings(
                  account,
                  cakeAtLastUserAction,
                  userShares,
                  pricePerFullShare,
                  pool.earningTokenPrice,
                ).autoUsdToDisplay
              : pool.userData.pendingReward.times(pool.earningTokenPrice).toNumber()
          },
          'desc',
        )
      case 'totalStaked':
        return orderBy(
          poolsToSort,
          (pool: Pool) => (pool.isAutoVault ? totalCakeInVault.toNumber() : pool.totalStaked.toNumber()),
          'desc',
        )
      default:
        return poolsToSort
    }
  }

  let chosenPools
  if (showFinishedPools) {
    chosenPools = stakedOnly ? stakedOnlyFinishedPools : finishedPools
  
  }
  if (showallpools) {
    chosenPools = stakedOnly ? stakedOnlyAllPools  : all
  } 
  if (showfinished) {
    chosenPools =  finished
  } 

  let chosenPools2
  if (showall2pools) {
    chosenPools2 = stakedOnly ? stakedOnlyAl2lPools  : all2
  } 
  if (showfinished) {
    chosenPools2 = finished2
  } 
  let chosenPools3
  if (showall3pools) {
    chosenPools3 = stakedOnly ? stakedOnlyAl3lPools  : all3
  } 
  if (showfinished) {
    chosenPools3 =  finished3
  } 
  let chosenPools4
  if (showall4pools) {
    chosenPools4 = stakedOnly ? stakedOnlyAl4lPools  : all4
  } 
  if (showfinished) {
    chosenPools4 =  finished4
  } 
  let chosenPools5
  if (showall5pools) {
    chosenPools5 = stakedOnly ? stakedOnlyAl5lPools  : all5
  } 
  if (showfinished) {
    chosenPools5 =  finished5
  } 
  let chosenPools6
  if (showall6pools) {
    chosenPools6 = stakedOnly ? stakedOnlyAl6lPools  : all6
  } 
  if (showfinished) {
    chosenPools6 =  finished6
  } 
  let chosenPools7
  if (showall7pools) {
    chosenPools7 = stakedOnly ? stakedOnlyAl7lPools  : all7
  } 
  if (showfinished) {
    chosenPools7 =  finished7
  } 
  if (searchQuery) {
    const lowercaseQuery = latinise(searchQuery.toLowerCase())
    chosenPools = chosenPools.filter((pool) =>
      latinise(pool.earningToken.symbol.toLowerCase()).includes(lowercaseQuery),
    )
  }
  if (searchQuery) {
    const lowercaseQuery = latinise(searchQuery.toLowerCase())
    chosenPools2 = chosenPools2.filter((pool) =>
      latinise(pool.earningToken.symbol.toLowerCase()).includes(lowercaseQuery),
    )
  }
  if (searchQuery) {
    const lowercaseQuery = latinise(searchQuery.toLowerCase())
    chosenPools3 = chosenPools3.filter((pool) =>
      latinise(pool.earningToken.symbol.toLowerCase()).includes(lowercaseQuery),
    )
  }
  if (searchQuery) {
    const lowercaseQuery = latinise(searchQuery.toLowerCase())
    chosenPools4 = chosenPools4.filter((pool) =>
      latinise(pool.earningToken.symbol.toLowerCase()).includes(lowercaseQuery),
    )
  }
  if (searchQuery) {
    const lowercaseQuery = latinise(searchQuery.toLowerCase())
    chosenPools5 = chosenPools5.filter((pool) =>
      latinise(pool.earningToken.symbol.toLowerCase()).includes(lowercaseQuery),
    )
  }
  if (searchQuery) {
    const lowercaseQuery = latinise(searchQuery.toLowerCase())
    chosenPools6 = chosenPools6.filter((pool) =>
      latinise(pool.earningToken.symbol.toLowerCase()).includes(lowercaseQuery),
    )
  }
  if (searchQuery) {
    const lowercaseQuery = latinise(searchQuery.toLowerCase())
    chosenPools7 = chosenPools7.filter((pool) =>
      latinise(pool.earningToken.symbol.toLowerCase()).includes(lowercaseQuery),
    )
  }


  chosenPools = sortPools(chosenPools).slice(0, numberOfPoolsVisible)
  chosenPools6 = sortPools(chosenPools6).slice(0, numberOfPoolsVisible)
  chosenPools5 = sortPools(chosenPools5).slice(0, numberOfPoolsVisible)
  chosenPools4 = sortPools(chosenPools4).slice(0, numberOfPoolsVisible)
  chosenPools3 = sortPools(chosenPools3).slice(0, numberOfPoolsVisible)
  chosenPools2 = sortPools(chosenPools2).slice(0, numberOfPoolsVisible)
  chosenPools7 = sortPools(chosenPools7).slice(0, numberOfPoolsVisible)
  chosenPoolsLength.current = chosenPools.length
  chosenPoolsLength2.current = chosenPools2.length
  chosenPoolsLength3.current = chosenPools3.length
  chosenPoolsLength4.current = chosenPools4.length
  chosenPoolsLength5.current = chosenPools5.length
  chosenPoolsLength6.current = chosenPools6.length
  chosenPoolsLength7.current = chosenPools7.length

  const cardLayout = (
    <CardLayout>
      {chosenPools.map((pool) =>
 (
          <PoolCard key={pool.sousId} pool={pool} account={account} />
          
        ),

      )}
        {chosenPools2.map((pool) =>
 (
          <PoolCard2 key={pool.sousId} pool={pool} account={account} />
          
        ),

      )}
       {chosenPools7.map((pool) =>
 (
          <PoolCard7 key={pool.sousId} pool={pool} account={account} />
          
        ),

      )}
        {chosenPools5.map((pool) =>
 (
          <PoolCard5 key={pool.sousId} pool={pool} account={account} />
          
        ),

      )}
          {chosenPools6.map((pool) =>
 (
          <PoolCard6 key={pool.sousId} pool={pool} account={account} />
          
        ),

      )}
         {chosenPools3.map((pool) =>
 (
          <PoolCard3 key={pool.sousId} pool={pool} account={account} />
          
        ),

      )}
       {chosenPools4.map((pool) =>
 (
          <PoolCard4 key={pool.sousId} pool={pool} account={account} />
          
        ),

      )}
      
    </CardLayout>
  )

  const tableLayout = <PoolsTable pools={chosenPools} pools2={chosenPools2} pools7={chosenPools7} pools5={chosenPools5} pools6={chosenPools6} pools3={chosenPools3} pools4={chosenPools4} account={account} userDataLoaded={userDataLoaded} />


  return (
    <>
      <PageHeader>
        <Flex justifyContent="space-between" flexDirection={['column', null, null, 'row']}>
          <Flex flex="1" flexDirection="column" mr={['8px', 0]}>
            <Title as="h1" scale="xxl" mb="24px">
              {t('Be2Mining')}
            </Title>
             <Heading scale="md" color="text">
              {t('Stake one token and immediately get 2 as a reward.')}
            </Heading>
            <Heading scale="md" color="text">
              {t('High APR, low risk.')}
            </Heading>
          </Flex>
        </Flex>
      </PageHeader>
      <Page>
        <PoolControls>
          <PoolTabButtons
            stakedOnly={stakedOnly}
            setStakedOnly={setStakedOnly}
            hasStakeInFinishedPools={hasStakeInFinishedPools}
            viewMode={viewMode}
            setViewMode={setViewMode}
          />
          <FilterContainer>

            <LabelWrapper style={{ marginLeft: 16 }}>
              <Text fontSize="12px" bold color="textSubtle" textTransform="uppercase">
                {t('Search')}
              </Text>
              <SearchInput onChange={handleChangeSearchQuery} placeholder="Search Pools" />
            </LabelWrapper>
          </FilterContainer>
        </PoolControls>
        {showFinishedPools && (
          <Text fontSize="20px" color="failure" pb="32px">
            {t('These pools are no longer distributing rewards. Please unstake your tokens.')}
          </Text>
        )}
        {account && !userDataLoaded && stakedOnly && (
          <Flex justifyContent="center" mb="4px">
            <Loading />
          </Flex>
        )}
        {viewMode === ViewMode.CARD ? cardLayout : tableLayout}
        <div ref={loadMoreRef} />
      </Page>
    </>
  )
}

export default FarmPools

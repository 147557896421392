import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { createClient } from '@supabase/supabase-js';
import { Button, Input } from '@pancakeswap/uikit';
import { ethers } from 'ethers';
import contractABI from './contractABI.json';
import NumberPurchaseABI from './NumberPurchaseABI.json';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org');
const contractAddress = '0x674e3eDc898Aa4b486Bd2E5aa2D1C8653677F471';
const contractAddress2 = '0xBE8C7E554C89e064ED7229952036c3678cd10180';
const contract = new ethers.Contract(contractAddress, contractABI, provider);
const contract2 = new ethers.Contract(contractAddress2, NumberPurchaseABI, provider);

// Felhasználói elem a toplistában
export const UserItem = styled.li`
  display: flex;
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;
  background-color: black;
  color: white;
  border: 1px solid ${({ rank }) =>
    rank === 1 ? 'gold' :
    rank === 2 ? 'silver' :
    rank === 3 ? '#CD7F32' : 'white'};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  flex-direction: row;
  text-align: left;

  &:hover {
    transform: translateY(-5px);
  }

  // Mobilnézet stílusai
  @media (max-width: 768px) {
    flex-direction: column; /* Elemei egymás alá kerülnek mobilon */
    text-align: center;
    padding: 10px;  /* Csökkentett padding a kisebb képernyőkön */
  }
`;

// Üzenetszám stílusa (automatikusan igazodik a szélességhez)
export const MessageCount = styled.span`
  font-size: 14px;
  color: white;
  margin-left: auto;

  @media (max-width: 768px) {
    margin-left: 0; /* Mobilon középre igazítás */
    margin-top: 10px; /* Kis térköz az elem alatt */
  }
`;

// Felhasználói név (középre igazítás mobilon)
export const UserName = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${({ rank }) => (rank <= 10 ? 'gold' : 'white')};
  margin-right: 10px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-top: 5px;
        margin-bottom: -10px;
  }
`;

// Avatar kép (középre igazítás mobilon)
export const UserAvatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 5px;
  }
`;


// Jutalom kijelzője
export const RewardText = styled.span`
  font-size: 12px;
  color: gold;
  margin-left: 10px;
`;

// Container a toplistához és az időzítőhöz
export const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
`;

// Toplista cím
export const TopListTitle = styled.h2`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: white;
`;

// Input mező stílusa
const StyledInput = styled(Input)`
  margin-bottom: 10px;
`;

// Időzítő stílus
export const CountdownTimer = styled.div`
  font-size: 24px;
  color: white;
  text-align: center;
  margin-top: 10px;
    margin-bottom: 10px;

`;

const Toplist = () => {
  const [topList, setTopList] = useState([]);
  const [names, setNames] = useState({});
  const [userNumbers, setUserNumbers] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [timeLeft, setTimeLeft] = useState('');

  // Új függvény a jutalmak számítására
const calculateReward = (count, totalMessages) => {
  const totalTokens = 4000;
  const reward = Math.floor((count / totalMessages) * totalTokens);
  return `${reward} VizslaSwap Tokens`;
};
  // Időzítő frissítése
  useEffect(() => {
    const targetDate = new Date('2024-11-18T00:00:00');
    const updateCountdown = () => {
      const now = new Date();
      const timeDifference = targetDate - now;

      if (timeDifference <= 0) {
        setTimeLeft('The event has ended.');
        return;
      }

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
      const seconds = Math.floor((timeDifference / 1000) % 60);

      setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchTopList = async () => {
      const { data, error } = await supabase
        .from('messages')
        .select('*')
        .order('timestamp', { ascending: true })
        .limit(5000);  // Limittel csökkentheted a lekérdezési időt
  
      if (!error) {
        const userMessageCount = new Map();
        data.forEach((msg) => {
          if (msg.text && msg.text.trim() !== '') {
            userMessageCount.set(msg.sender, (userMessageCount.get(msg.sender) || 0) + 1);
          }
        });
  
        const sortedTopList = Array.from(userMessageCount.entries()).sort((a, b) => b[1] - a[1]);
        setTopList(sortedTopList.slice(0, 15));  // Csak az első 15 felhasználó megjelenítése
      }
    };
  
    fetchTopList();
  }, []);  // Ez a rész biztosítja, hogy a lekérdezés a komponens betöltésekor fusson
  

  useEffect(() => {
    const fetchUserData = async () => {
      const newNames = {};
      const newNumbers = {};
  
      await Promise.all(
        topList.slice(0, 15).map(async ([user]) => {  // Csak az első 15 felhasználóhoz hívja a contractokat
          try {
            let name = "";
            try {
              name = await contract.getName(user);
            } catch (error) {
              console.warn(`No name found for ${user}, using address.`);
              name = `${user.substring(0, 5)}...${user.substring(user.length - 5)}`;
            }
            newNames[user] = name;
  
            const userPurchase = await contract2.userPurchases(user);
            const userNumber = userPurchase.number.toNumber();
            newNumbers[user] = userNumber;
          } catch (error) {
            console.error(`Error fetching data for ${user}:`, error);
          }
        })
      );
  
      setNames(newNames);
      setUserNumbers(newNumbers);
    };
  
    if (topList.length > 0) {
      fetchUserData();
    }
  }, [topList]);
  

  const getUserImage = (user) => {
    const userNumber = userNumbers[user];

    if (userNumber) {
      let formattedNumber;
      if (userNumber < 10) {
        formattedNumber = userNumber.toString().padStart(2, '0');
      } else {
        formattedNumber = userNumber.toString().padStart(3, '0');
      }
      return `images/pixel/${formattedNumber}.jpg`;
    }

    return 'images/pixel/200.png';
  };

  const filteredTopList = topList.filter(([user]) => {
    const userName = names[user]?.toLowerCase() || '';
    return userName.includes(searchQuery.toLowerCase()) || user.toLowerCase().includes(searchQuery.toLowerCase());
  });
  const totalMessages = filteredTopList.reduce((acc, [, c]) => acc + c, 0);


  return (
    <Container>
      <Link to="/chat">
        <Button style={{ backgroundColor: 'gold', color: 'black' }}>&lt; Back to Chat</Button>
      </Link>

      <Container>
        <TopListTitle>Top List</TopListTitle>
        <CountdownTimer>Event ends in: {timeLeft}</CountdownTimer>
        <p style={{ color: 'gold', textAlign: 'center', marginBottom: '20px' }}>
  Only the last 5000 messages will be fetched.
</p>
        <StyledInput
          placeholder="Search by name or address"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

<ul>
    {filteredTopList.map(([user, count], index) => (
      <UserItem key={user} rank={index + 1}>
        <UserAvatar src={getUserImage(user)} alt={`${names[user] || user}'s avatar`} />
        <UserName rank={index + 1}>{names[user] || `${user.substring(0, 5)}...${user.substring(user.length - 5)}`}</UserName>
        <MessageCount>{count} messages</MessageCount>
        {index + 1 <= 10 && (
          <RewardText>{calculateReward(count, totalMessages)}</RewardText>
        )}
      </UserItem>
    ))}
  </ul>

        {/* Időzítő hozzáadása */}
      </Container>
    </Container>
  );
};

export default Toplist;

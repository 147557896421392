import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import Board from "./components/Board/Board";
import GameInfoBoard from "./components/GameInfoBoard/GameInfoBoard";
import candyData, { CandyData } from "./data/candyColors";
import { createBoard } from "./components/Board/Board.utils";
import GameOver from "./components/GameOver/GameOver";
import { generateUnbreakableTiles, UNBREAKABLE_TILE_ID } from "./components/Board/UnbreakableTile.utils";
import { levels } from "./data/levels";
  
const App = () => {
  const [level, setLevel] = useState(0); // Kezdjük az első szinttel
  const [board, setBoard] = useState<number[][]>(createBoard(levels[level].BOARD_SIZE, levels[level].NUMBERS));
  const [isPlayable, setIsPlayable] = useState(true);
  const [moves, setMoves] = useState(levels[level].MAX_MOVES);
  const [tilesToGo, setTilesToGo] = useState(levels[level].NUM_TILES_TO_GO);
  const [tileToSearch, setTileToSearch] = useState<CandyData | null>(null);
  const [resetTime, setResetTime] = useState(false); // Idő újraindítása

  const initializeLevel = (levelIndex: number) => {
    setMoves(levels[levelIndex].MAX_MOVES);
    setTilesToGo(levels[levelIndex].NUM_TILES_TO_GO);

    // Létrehozzuk a táblát, és hozzáadjuk a törhetetlen csempéket
    let initialBoard = createBoard(levels[levelIndex].BOARD_SIZE, levels[levelIndex].NUMBERS);
    initialBoard = generateUnbreakableTiles(initialBoard, levels[levelIndex].UNBREAKABLE_TILE_COUNT); // 5 törhetetlen csempét adunk hozzá
 
    setBoard(initialBoard);
    setIsPlayable(true);

    const targetTileIds = levels[levelIndex].targetTileIds;
    const randomTargetId = _.sample(targetTileIds);
    if (randomTargetId !== undefined) {
      const tileData = candyData.find(candy => candy.tileId === randomTargetId);
      setTileToSearch(tileData || null);
    }
  };

  useEffect(() => {
    initializeLevel(level); // Kezdeti szint inicializálása
  }, [level]); 

  const move = useCallback(() => setMoves(prev => prev - 1), []);

  const subsTilesToGo = (id: number, quan: number) => {
    // Ha a csempe törhetetlen, nem célcsempe, vagy üres, ne csökkentsük a számlálót
    if (id === UNBREAKABLE_TILE_ID || id !== tileToSearch?.tileId || id === 0) {
      console.log(`Skipping tile with ID ${id} - either unbreakable, not the target type, or empty`);
      return;
    }
    console.log(`Decreasing tilesToGo by ${quan} for tile type ${id}`);
    setTilesToGo(prev => Math.max(prev - quan, 0));
  };
  
  const newGame = () => {
    initializeLevel(level);
    setResetTime(true); // Az időzítő visszaállítása
    setTimeout(() => setResetTime(false), 0); // Azonnal visszaállítjuk, hogy új szint kezdetekor újra induljon az időzítő
  };

  const nextLevel = () => {
    if (level < levels.length - 1) {
      setLevel(prevLevel => prevLevel + 1);
    } else {
      alert("Gratulálunk! Teljesítetted az összes szintet!");
    }
  };

  const gameOver = moves === 0 || tilesToGo === 0;
 
  return (
    <div>
      <h1
        style={{
          textAlign: "center",
          fontSize: "3rem",
          margin: "0",
          color: "#f70707",
        }}
      >
        Candy Crush Clone
      </h1>
      <GameInfoBoard
        moves={moves}
        tilesToGo={tilesToGo}
        tileToSearch={tileToSearch}
        level={level + 1}
        resetTime={resetTime} // Az új resetTime prop
      />
      {tileToSearch?.tileId ? (
        <Board
          move={move}
          subsTilesToGo={subsTilesToGo}
          isPlayable={isPlayable}
          setIsPlayable={setIsPlayable}
          board={board}
          setBoard={setBoard}
          numbers={levels[level].NUMBERS}
        />
      ) : (
        "loading"
      )}
      {gameOver && (
        <GameOver isWin={tilesToGo === 0} newGame={newGame} nextLevel={nextLevel} />
      )}
    </div>
  );
};

export default App;

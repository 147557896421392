import styled from "styled-components";

interface WrapperProps {
    size: number
}

export const Wrapper = styled.div<WrapperProps>`
  width: 90vw;
  height: 90vw;
  max-width: 600px;
  max-height: 600px;
  min-width: 300px;
  min-height: 300px;
  margin: 30px auto;
  display: grid;
background: #2b3a42; /* Nyugtató világosszürke átlátszó háttér */
  backdrop-filter: blur(8px); /* Blur hatás */
  border: 1px solid #aaa; /* Szürke szegély */
  grid-template: repeat(${({ size }) => size}, 1fr) / repeat(${({ size }) => size}, 1fr);
  position: relative;
  box-sizing: content-box;
  padding: 5px;
  gap: 4px;
  border-radius: 15px;

  @media (max-width: 768px) {
    width: 80vw;
    height: 80vw;
    max-width: 400px;
    max-height: 400px;
    min-width: 200px;
    min-height: 200px;
  }

  @media (max-width: 480px) {
    width: 90vw;
    height: 90vw;
    max-width: 300px;
    max-height: 300px;
    min-width: 150px;
    min-height: 150px;
    gap: 2px;
  }
`;

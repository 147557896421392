import React, { useState, useEffect, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import { useWeb3React } from '@web3-react/core/core/core';
import { createClient } from '@supabase/supabase-js';
import { Button, Text, Box, Card } from '@pancakeswap/uikit';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

// Csak a kártya szélének villogásához animáció
const glowingBorderAnimation = keyframes`
  0% {
    box-shadow: 0 0 2px #62d5a3, 0 0 4px #62d5a3, 0 0 6px #62d5a3, 0 0 8px #62d5a3;
  }
  50% {
    box-shadow: 0 0 4px #62d5a3, 0 0 6px #62d5a3, 0 0 8px #62d5a3, 0 0 10px #62d5a3;
  }
  100% {
    box-shadow: 0 0 2px #62d5a3, 0 0 4px #62d5a3, 0 0 6px #62d5a3, 0 0 8px #62d5a3;
  }
`;

// Egy div a kártya körül, amin a határ animáció történik
const GlowingCardWrapper = styled.div`
  border-radius: 15px;
  animation: ${glowingBorderAnimation} 2s infinite;
  padding: 10px;  /* Ez az extra párnázás a kártya körül */
`;

const InnerCard = styled.div`
  background-color: #111;
  padding: 30px;
  border-radius: 10px;
`;

const VoteClosed: React.FC = () => {
  const { account } = useWeb3React();
  const [generatedLink, setGeneratedLink] = useState('');
  const [clickCount, setClickCount] = useState(0);
  const [topList, setTopList] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
    const [timeLeft, setTimeLeft] = useState('');

  // Fetch user-generated link and click count
  const fetchUserLink = useCallback(async () => {
    if (!account) return;

    try {
      const { data, error } = await supabase
        .from('user_links')
        .select('generated_link, click_count')
        .eq('user_id', account)
        .single();

      if (error) throw error;

      if (data) {
        setGeneratedLink(data.generated_link);
        setClickCount(data.click_count);
      }
    } catch (error) {
      setErrorMessage('Failed to fetch your link. Please try again later.');
    }
  }, [account]);

  // Fetch toplist based on click count
  const fetchTopList = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('user_links')
        .select('user_id, generated_link, click_count')
        .order('click_count', { ascending: false })
        .limit(10);

      if (error) throw error;

      setTopList(data);
    } catch (error) {
      setErrorMessage('Failed to load top list. Please try again later.');
    }
  }, []);

  useEffect(() => {
    if (account) {
      fetchUserLink();
      fetchTopList();

      const intervalId = setInterval(() => {
        fetchUserLink();
      }, 10000);

      return () => clearInterval(intervalId);
    }

    return undefined;
  }, [account, fetchUserLink, fetchTopList]);

    // Countdown timer for 31 October 0:00
    useEffect(() => {
      const targetDate = new Date('2024-11-30T00:00:00');
      
      const updateCountdown = () => {
        const now = new Date();
        const difference = targetDate.getTime() - now.getTime();
  
        if (difference > 0) {
          const days = Math.floor(difference / (1000 * 60 * 60 * 24));
          const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((difference % (1000 * 60)) / 1000);
  
          setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
        } else {
          setTimeLeft('Event has ended');
        }
      };
  
      const timerId = setInterval(updateCountdown, 1000);
  
      return () => clearInterval(timerId);
    }, []);
  // Generate new referral link
  const generateLink = async () => {
    if (!account) {
      setErrorMessage('Please connect your wallet.');
      return;
    }

  const baseUrl = 'https://vizslaswap.com';
  const newLink = `${baseUrl}/ref?userId=${account}`; // Query paraméterként használjuk a userId-t

    try {
      const { data: existingLink, error: fetchError } = await supabase
        .from('user_links')
        .select('generated_link')
        .eq('user_id', account)
        .single();

      if (existingLink) {
        setGeneratedLink(existingLink.generated_link);
        return;
      }

      const { error: insertError } = await supabase
        .from('user_links')
        .insert([{ user_id: account, generated_link: newLink, click_count: 0 }]);

      if (insertError) throw insertError;

      setGeneratedLink(newLink);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('An error occurred while generating your link. Please try again.');
    }
  };

  return ( 
    <Box style={styles.container}>
  <GlowingCardWrapper>
  <InnerCard>
          <Text style={styles.title}>
          {account ? 'Your Referral Link' : 'Please Connect Your Wallet'}
        </Text>

        {account && (
          <>
            {generatedLink && (
              <Box style={styles.linkBox}>
                <Text style={styles.linkText}>
                  <a href={generatedLink} target="_blank" rel="noopener noreferrer">
                    {generatedLink}
                  </a>
                </Text>
              </Box>
            )}

            {!generatedLink && (
              <Button onClick={generateLink} style={styles.button}>
                Generate Your Link
              </Button>
            )}

            <Text style={styles.clicksText}>
              Total Clicks: <span>{clickCount}</span>
            </Text>

            <Box style={styles.rewardsBox}>
              <Text style={styles.rewardsTitle}>🏆 Rewards 🏆</Text>
              <ul style={styles.rewardsList}>
                <li>🥇 1st place: 10,000 VIZSLASWAP</li>
                <li>🥈 2nd place: 7,500 VIZSLASWAP</li>
                <li>🥉 3rd place: 5,000 VIZSLASWAP</li>
                <li>4th to 10th place: 2,500 VIZSLASWAP</li>
              </ul>
              <Text style={styles.rewardsTitle}>⏳ Time Left: {timeLeft}</Text>
            </Box>

            <Text style={styles.title}>Top 10 Users</Text>

            <ul style={styles.topList}>
      {topList.map((user) => (
        <li key={user.user_id} style={styles.listItem}>
          <span style={styles.listItemText}>{user.user_id}</span> {/* Contract cím */}
          <span style={styles.listItemCount}>{user.click_count} clicks</span> {/* Kattintásszám */}
        </li>
      ))}
    </ul>

            {errorMessage && (
              <Text style={{ color: 'red' }}>{errorMessage}</Text>
            )}
          </>
        )}
          </InnerCard>
          </GlowingCardWrapper> 
    </Box>
  );
}; 

const styles = {
  container: {
    backgroundColor: '#000', // Fekete háttér
    padding: '40px',
    margin: '20px auto',
    maxWidth: '800px',
    borderRadius: '15px',
    boxShadow: '0 10px 20px rgba(0,0,0,0.15)',
  },
  card: {
    padding: '30px',
    backgroundColor: '#111', // Sötétszürke háttér a kártyán
    borderRadius: '10px',
    boxShadow: '0 5px 15px rgba(0,0,0,0.1)',
  },
  title: {
    fontSize: '24px',
    fontWeight: '700',
    color: '#fff', // Fehér szöveg
  },
  linkBox: {
    padding: '15px',
    border: '1px solid #fff', // Fehér szegély
    borderRadius: '8px',
    backgroundColor: '#222', // Sötétebb szürke háttér a linkhez
    marginBottom: '20px',
  },
  linkText: {
    fontSize: '16px',
    color: '#FFD700', // Arany szöveg a linkhez
    wordWrap: 'break-word',
  },
  button: {
    backgroundColor: '#FFD700', // Arany gomb
    color: '#000', // Fekete szöveg
    padding: '12px 20px',
    borderRadius: '8px',
    cursor: 'pointer',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: '#FFC107', // Világosabb arany hover effekt
    },
  },
  clicksText: {
    fontSize: '18px',
    color: '#fff', // Fehér szöveg a "Total Clicks"-hez
    marginBottom: '20px',
  },
  rewardsBox: {
    padding: '20px',
    border: '1px solid #fff', // Fehér szegély a jutalmak dobozához
    borderRadius: '8px',
    backgroundColor: '#222', // Sötétszürke háttér
    marginBottom: '20px',
  },
  rewardsTitle: {
    fontSize: '20px',
    fontWeight: '600',
    marginBottom: '10px',
    color: '#fff', // Fehér szöveg
  },
  rewardsList: {
    listStyle: 'none',
    padding: '0',
    color: '#fff', // Fehér szöveg a listához
    marginBottom: '20px',
  },
  topList: {
    listStyle: 'none',
    padding: '0',
    color: '#fff', // Fehér szöveg a toplistához
    maxHeight: '100%', // Maximális magasság a listához
    width: '100%', // A lista szélessége a szülő elemhez igazodik
    maxWidth: '100%', // A lista maximális szélessége nem lépi túl a szülő szélességét
  },
  listItem: {
    padding: '10px 0',
    borderBottom: '1px solid #444', // Sötétszürke vonal a listaelemek között
    display: 'block', // Biztosítjuk, hogy a tartalom egymás alatt jelenjen meg
  },
  listItemText: {
    fontSize: '14px',
    color: '#fff',
    wordBreak: 'break-word', // Szavak tördelése, ha túl hosszúak
    whiteSpace: 'normal', // Tördelés, ha a szöveg hosszú
    marginBottom: '5px', // Kis térköz a kattintásszám előtt
  },
  listItemCount: {
    fontSize: '12px', // A kattintásszám kisebb méretű lesz
    color: '#62d5a3', // Egyéni szín a kattintásszámnak
  },
};

export default VoteClosed;

import React from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { Button, ButtonMenu, ButtonMenuItem, Flex, Text } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'

const StyledNav = styled.nav`
  margin-bottom: 20px;
`
const ButtonText = styled(Text)`
  display: none;
  ${({ theme }) => theme.mediaQueries.xs} {
    display: block;
  }
`
const getActiveIndex = (pathname: string): number => {
  if (
    pathname.includes('/pool') ||
    pathname.includes('/create') ||
    pathname.includes('/add') ||
    pathname.includes('/remove') ||
    pathname.includes('/find') ||
    pathname.includes('/liquidity') ||
    pathname.includes('/activity')
  ) { 
    return 1
  }
  return 0
}
const Nav = () => {
  const location = useLocation()
  const { t } = useTranslation()
  return (
    <StyledNav>
      <ButtonMenu activeIndex={getActiveIndex(location.pathname)} scale="md" variant="subtle">
      <Flex  flexDirection="column">
  
        <ButtonMenuItem  id="swap-nav-link" to="/swap" as={Link}>
        <ButtonText color="text"   >
        {t('Swap')}
  </ButtonText>
        </ButtonMenuItem>
        <ButtonMenuItem id="pool-nav-link" scale="md" to="/limit-orders" as={Link}>
        <ButtonText color="text"  >

          {t('Limit Orders')}
          </ButtonText> 

        </ButtonMenuItem>
        </Flex>
        <Flex  flexDirection="column">
        <Button    scale="md"
                        variant="primary"
                        id="enable-order-button"
                        width="120px"
                        to="/pool" as={Link}
                      >
         <ButtonText color="tertiary" bold >

          {t('Liquidity')} 
          </ButtonText>

         </Button >

        <ButtonMenuItem id="pool-nav-link" scale="md" to="/charts" as={Link}>
        <ButtonText color="text"  >
          {t('Charts')}
          </ButtonText>
        </ButtonMenuItem>
        </Flex>
      </ButtonMenu>
      
    </StyledNav>
  )
}

export default Nav

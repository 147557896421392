import React from 'react'
import { Modal, Text, Flex} from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'

interface ModalInfoProps {
  onDismiss?: () => void
  TooltipComponent: React.ElementType
}



const ModalInfo: React.FC<ModalInfoProps> = ({ onDismiss }) => {
  const { t } = useTranslation()



  return (
    <Modal title={t('About bethustlers Token')} onDismiss={onDismiss}>

          <Flex alignItems="left" justifyContent="left">
  <Text fontSize="15px" >  {t('Bethustlers is a pioneering platform where sports betting meets the security and transparency of cryptocurrency.')} </Text>
      </Flex>

    </Modal>
  )
}

export default ModalInfo


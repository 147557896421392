import React from 'react'
import { Redirect, useParams } from 'react-router-dom'
import AddLiquidity5 from './index'

export function RedirectToAddLiquidity4() {
  return <Redirect to="/addbiswapliquidity/" />
}

const OLD_PATH_STRUCTURE = /^(0x[a-fA-F0-9]{40}|BNB)-(0x[a-fA-F0-9]{40}|BNB)$/
export function RedirectOldAddLiquidityPathStructure4() {
  const { currencyIdA } = useParams<{ currencyIdA: string }>()
  const match = currencyIdA.match(OLD_PATH_STRUCTURE)
  if (match?.length) {
    return <Redirect to={`/addbiswapliquidity/${match[1]}/${match[2]}`} />
  }

  return <AddLiquidity5 />
}

export function RedirectDuplicateTokenIds4() {
  const { currencyIdA, currencyIdB } = useParams<{ currencyIdA: string; currencyIdB: string }>()
  if (currencyIdA.toLowerCase() === currencyIdB.toLowerCase()) {
    return <Redirect to={`/addbiswapliquidity/${currencyIdA}`} />
  }
  return <AddLiquidity5 />
}

import React,{ Dispatch, useEffect, useState } from "react";
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
import {
  checkForAllMatches,
  hasEmptyTiles, 
  addNewRow,
  getValuesDown,
  swipeTiles,
  calculateDirection,
  calculateNewPosition,
  isValidMove,
} from "./Board.utils";
import * as S from "./Board.style";
import { TilePos } from "./Board.types";
import Tile from "../Tile/Tile";

const TILE_SPEED_MS = 300; 

interface BoardProps {
  move: () => void;
  subsTilesToGo: (id: number, quan: number) => void;
  isPlayable: boolean;
  setIsPlayable: Dispatch<React.SetStateAction<boolean>>;
  board: number[][];
  setBoard: Dispatch<React.SetStateAction<number[][]>>;
  numbers: number[];
}

const Board: React.FC<BoardProps> = ({
  move,
  subsTilesToGo,
  isPlayable,
  setIsPlayable,
  board,
  setBoard,
  numbers,
}) => {
  const [firstDraggedDiv, setFirstDraggedDiv] = useState<TilePos | null>(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (hasEmptyTiles(board)) {
        setBoard(prevBoard => addNewRow(getValuesDown(prevBoard), numbers));
        setIsPlayable(true);
      } else if (!isPlayable && !_.isEqual(board, checkForAllMatches(board))) {
        const newBoard = checkForAllMatches(board, subsTilesToGo);
        setBoard(newBoard);
      } else {
        setBoard(getValuesDown);
      }
    }, TILE_SPEED_MS);

    if (
      !_.isEqual(board, getValuesDown(board)) ||
      !_.isEqual(board, checkForAllMatches(board))
    ) {
      setIsPlayable(false);
    }

    return () => clearInterval(timeoutId); // Garantált visszatérés

}, [board, isPlayable, numbers, setBoard, setIsPlayable, subsTilesToGo]);

const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
  if (!isPlayable) {
      return;
  }
  setFirstDraggedDiv({
      x: e.screenX,
      y: e.screenY,
  });
};


  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const dataTransfer = e.dataTransfer;
    dataTransfer.effectAllowed = "copyMove"; // Az esemény helyett az e.dataTransfer-t közvetlenül módosítjuk
};


const handleDragEnd = (
  e: React.DragEvent<HTMLDivElement>,
  tilePos: TilePos
) => {
  if (!isPlayable || !firstDraggedDiv) {
      return;
  }

  const offsetX = e.screenX - firstDraggedDiv.x;
  const offsetY = e.screenY - firstDraggedDiv.y;

  const direction = calculateDirection(offsetX, offsetY);

  if (!direction) {
      return;
  }

  const newPosition = calculateNewPosition(tilePos, direction);

  if (!isValidMove(newPosition, board.length)) {
      return;
  }

  const boardBeforeMove = _.cloneDeep(board);
  const boardAfterMove = swipeTiles(board, tilePos, direction);
  setBoard(boardAfterMove);

  // Ellenőrizd, hogy történt-e változás
  if (_.isEqual(boardAfterMove, checkForAllMatches(boardAfterMove))) {
      setIsPlayable(false); // Itt letiltjuk az újabb lépéseket
      setTimeout(() => {
          setBoard(boardBeforeMove); // Visszaállítjuk az eredeti állapotot, ha nincs érvényes mozgás
          setIsPlayable(true); // Újra lehet mozgatni a cukorkákat
      }, TILE_SPEED_MS * 2);
  } else {
      move();
  }

  setFirstDraggedDiv(null);
};


return (
  <div>
    <S.Wrapper size={board.length}>
      {/* eslint-disable react/no-array-index-key */}
      {board.map((row, y) =>
        row.map((tile, x) => (
          <Tile
            key={`${y}-${x}`} // Using y and x coordinates as a unique key
            id={`${y}${x}`}
            dragStart={handleDragStart}
            dragOver={handleDragOver}
            dragEnd={e => handleDragEnd(e, { y, x })}
            tile={tile}
            isPlayable={isPlayable}
          />
        ))
      )}
      {/* eslint-enable react/no-array-index-key */}
    </S.Wrapper>
  </div>
);
};

export default Board;

import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
  font-family: 'Rajdhani', sans-serif;
body {
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,1) 5%, rgba(17,49,50,1) 99%, rgba(18,50,51,1) 100%);
     
       }  
    
    @media only screen and (max-width: 1350px) {
    body {
      background: rgb(0,0,0);
      background: linear-gradient(90deg, rgba(0,0,0,1) 5%, rgba(17,49,50,1) 99%, rgba(18,50,51,1) 100%);
         
      height:100%;
      width:100%; 
    }

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle

const decimal = (num: number): string => {
    return num.toString().padStart(2, "0");
};

export const displayTime = (time: number | null): string => {
    const parsedTime = Number(time);
    if (parsedTime < 0) {
        return `00:00`;
    }
    const mn = Math.floor(parsedTime / 60);
    const sc = parsedTime % 60;
    return `${decimal(mn)}:${decimal(sc)}`;
};

import React, { useState, useCallback } from 'react'
import { Box, Button, Flex, InjectedModalProps, LinkExternal, Message, Text, Skeleton, AutoRenewIcon } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core/core/core'
import useTokenBalance, { useGetBnbBalance } from 'hooks/useTokenBalance'
import { getCakeAddress } from 'utils/addressHelpers'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'
import { getBscScanLink } from 'utils'
import useToast from 'hooks/useToast'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { useMasterchef } from 'hooks/useContract'
import { harvestFarm } from 'utils/calls'
import Balance from 'components/Balance'
import BigNumber from 'bignumber.js/bignumber'
import useFarmsWithBalance from 'views/Home/hooks/useFarmsWithBalance'
import { usePriceCakeBusd } from 'state/farms/hooks'
import CopyAddress from './CopyAddress'

interface WalletHarvestProps {
  hasLowBnbBalance: boolean
  onDismiss: InjectedModalProps['onDismiss']
}

const WalletHarvest: React.FC<WalletHarvestProps> = ({ hasLowBnbBalance, onDismiss }) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const [pendingTx, setPendingTx] = useState(false)
  const { toastSuccess, toastError } = useToast()
  const { balance } = useGetBnbBalance()
  const { balance: cakeBalance } = useTokenBalance(getCakeAddress())
  const { logout } = useAuth()
  const { farmsWithStakedBalance, earningsSum: farmEarningsSum } = useFarmsWithBalance()

  const masterChefContract = useMasterchef()
  const cakePriceBusd = usePriceCakeBusd()
  const earningsBusd = new BigNumber(farmEarningsSum).multipliedBy(cakePriceBusd)
  const numFarmsToCollect = farmsWithStakedBalance.length
  
  const earningsText = t('%earningsBusd% Collect from %count% %farms%', {
    earningsBusd: earningsBusd.toString(),
    count: numFarmsToCollect > 0 ? numFarmsToCollect : '',
    farms: numFarmsToCollect === 0 || numFarmsToCollect > 1 ? 'farms' : 'farm',
  })
  const [preText, toCollectText] = earningsText.split(earningsBusd.toString())
  const earningsBusd2 = earningsBusd.div(cakePriceBusd)
  
  const handleLogout = () => {
    onDismiss()
    logout()
  }
  const harvestAllFarms = useCallback(async () => {
    setPendingTx(true)
    // eslint-disable-next-line no-restricted-syntax
    for (const farmWithBalance of farmsWithStakedBalance) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await harvestFarm(masterChefContract, farmWithBalance.pid)
        toastSuccess(
          `${t('Harvested')}!`,
          t('Your %symbol% earnings have been sent to your wallet!', { symbol: 'VIZSLASWAP' }),
        )
      } catch (error) {
        toastError(t('Error'), t('Please try again. Confirm the transaction and make sure you are paying enough gas!'))
      }
    }
    setPendingTx(false)
  }, [farmsWithStakedBalance, masterChefContract, toastSuccess, toastError, t])

  return (
    <>
      <Text color="white" textAlign="left"  fontSize="14px" textTransform="uppercase" fontWeight="bold" mb="8px">
        {t('Harvest from Liquidity Mining')}
      </Text>      
      <Flex alignItems="center" justifyContent="space-between" >
      <Text  color="textSubtle">
              {toCollectText}
            </Text>     
            {preText && (
              <Text  color="textSubtle">
                {preText}
              </Text>
            )}
             {earningsBusd && !earningsBusd.isNaN() ? (
              <Balance
                decimals={earningsBusd.gt(0) ? 2 : 0}
                fontSize="18px"
                bold
                prefix={earningsBusd.gt(0) ? '~$' : '$'}
                lineHeight="1"
                value={earningsBusd.toNumber()}
              />
              ) : (
                <Skeleton width={96} height={24} my="2px" />
              )}
              </Flex>
              <Flex alignItems="center" justifyContent="space-between"  mb="24px">
        <Text color="textSubtle">{t('Collect in VIZSLASWAP')}</Text>
        {earningsBusd2 && !earningsBusd2.isNaN() ? (
              <Balance
                decimals={earningsBusd2.gt(0) ? 3 : 0}
                fontSize="18px"
                bold
                lineHeight="1"
                value={earningsBusd2.toNumber()}
              />
              ) : (
                <Skeleton width={96} height={24} my="2px" />
              )}
      </Flex>
                 <Button
              width={['100%', null, null, 'auto']}
              id="harvest-all"
              mb="2px"
              isLoading={pendingTx}
              endIcon={pendingTx ? <AutoRenewIcon spin color="currentColor" /> : null}
              disabled={pendingTx}
              onClick={harvestAllFarms}
            >
              <Text color="invertedContrast" bold>
                {pendingTx ? t('Harvesting') : t('Harvest all')}
              </Text>
            </Button>
 
    </>
  )
}

export default WalletHarvest

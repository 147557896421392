import styled, { keyframes } from "styled-components";

interface CellProps {
    playable: boolean;
    isBreaking?: boolean; // Új tulajdonság az animációhoz
}

export const Cell = styled.div<CellProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
     padding: 5px;
    cursor: ${({ playable }) => (playable ? "grab" : "default")};
    border-radius: 10px; /* Lekerekített sarkok hozzáadása */
    
`;

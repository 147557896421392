import styled from 'styled-components';
import { Text } from '@pancakeswap/uikit';

export const Container = styled.div`
  padding: 20px;
  background: linear-gradient(135deg, #0d0d0d, #1a1a1a); /* Sötétebb, egységesebb háttér */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
`;

export const UserListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(25, 25, 25, 0.85); /* Enyhén áttetsző fekete háttér */
  border-radius: 12px;
  padding: 25px;
  backdrop-filter: blur(10px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.7);
  width: 100%;
  max-width: 700px;
  margin-top: 20px;
`;

export const UserItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 10px;
  background-color: rgba(15, 15, 15, 0.9); /* Még sötétebb háttér */
  font-size: 16px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  width: 100%;
  max-width: 600px;
  margin: 8px 0;

  &:hover {
    transform: scale(1.03);
    background-color: rgba(45, 45, 45, 0.5); /* Világosabb árnyalat hover hatásra */
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  }
`;

export const UserAvatar = styled.img`
  width: 55px;
  height: 55px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
  border: 2px solid #333333; /* Sötétszürke keret */
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.1);
`;

export const UserName = styled(Text)`
  font-size: 15px;
  font-weight: bold;
  color: #f2f2f2; /* Enyhén világosított fehér */
  text-align: left;
  flex: 1;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const BackButton = styled.button`
  background: linear-gradient(135deg, #333333, #444444); /* Sötét, árnyalt szürke */
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 10px 18px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(51, 51, 51, 0.4);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #555555;
    box-shadow: 0 6px 18px rgba(85, 85, 85, 0.6);
  }
`;

export const Title = styled.h1`
  font-size: 30px;
  color: #e0e0e0; /* Halvány fehér szín */
  margin-left: 20px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #444444;
  background-color: rgba(30, 30, 30, 0.9); /* Sötétszürke háttér */
  color: #f2f2f2;
  margin-bottom: 20px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 12px rgba(255, 255, 255, 0.1);
  }

  &:focus {
    outline: none;
    border-color: #666666;
    box-shadow: 0 0 10px rgba(102, 102, 102, 0.5);
  }
`;

import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.vizslaswap,
    number:'0',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'VIZSLASWAP',
    tokensymbol4: '',
    tokensymbol5: 'ALL1',
    image: '0',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '6%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.05',
    sortOrder: 1,
    isFinished: false,
   },
   
   {
    sousId: 10,
    stakingToken: tokens.hvi,
    earningToken: tokens.vizslaswap,
    number:'10',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'HVI',
    tokensymbol4: '',
    tokensymbol5: 'ALL1',
    image: '10',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '4%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0xD24bffa1a9e3632A10CF07037aCf34bFe468Ff30',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.02',
    sortOrder: 200,
    isFinished: false,
   },
   {
    sousId: 14,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.hvi,
    number:'14',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'VIZSLASWAP',
    tokensymbol4: '',
    tokensymbol5: 'ALL1',
    image: '14',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '4%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x44c44e691e523378fec15Ba101AE0618fCA21cC4',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1000000000',
    sortOrder: 200,
    isFinished: false,
   },
   {
    sousId: 54,
    stakingToken: tokens.bethustlers,
    earningToken: tokens.bethustlers,
    number:'54',
    tokensymbol2: 'OPEN',
    tokensymbol3: '',
    tokensymbol4: '',
    tokensymbol5: 'ALL1',
    image: '54',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '4%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x7E7E1eD77ed403488d631758a83fcCd86a0aa787',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.001040',
    sortOrder: 200,
    isFinished: false,
   },
   {
    sousId: 55,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.bethustlers,
    number:'55',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'VIZSLASWAP',
    tokensymbol4: '',
    tokensymbol5: 'ALL1',
    image: '55',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '4%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x3804FcF2F09163a3459780298F1Cc6d7ee44165D',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.000243',
    sortOrder: 200,
    isFinished: false,
   },
   {
    sousId: 56,
    stakingToken: tokens.hvi,
    earningToken: tokens.bethustlers,
    number:'56',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'HVI',
    tokensymbol4: '',
    tokensymbol5: 'ALL1',
    image: '56',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '4%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0xD97462E7CD68C5e408cAf745F43C06Bc02bd4BC4',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.000243',
    sortOrder: 200,
    isFinished: false,
   },
   {
    sousId: 51,
    stakingToken: tokens.FartCoin,
    earningToken: tokens.FartCoin,
    number:'51',
    tokensymbol2: 'OPEN',
    tokensymbol3: '',
    tokensymbol4: '',
    tokensymbol5: '',
    image: '51',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x034dcDCFa84f5CEdc63729ec9f21fcd5A88aB653',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '4037',
    sortOrder: 200,
    isFinished: true,
   },
   {
    sousId: 52,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.FartCoin,
    number:'52',
    tokensymbol2: 'OPEN',
    tokensymbol3: '',
    tokensymbol4: '',
    tokensymbol5: '',
    image: '52',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0xc564DB3aF22e6FA2ab81B01C3a8125E0E6b0f3Fd',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '4037',
    sortOrder: 200,
    isFinished: true,
   },
   {
    sousId: 53,
    stakingToken: tokens.hvi,
    earningToken: tokens.FartCoin,
    number:'53',
    tokensymbol2: 'OPEN',
    tokensymbol3: '',
    tokensymbol4: '',
    tokensymbol5: '',
    image: '53',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0xEECec9fEFfE3F47C5b9E618A0e0f86Ae1D399c79',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '4037',
    sortOrder: 200,
    isFinished: true,
   },
   {
    sousId: 48,
    stakingToken: tokens.cuby,
    earningToken: tokens.cuby,
    number:'48',
    tokensymbol2: 'OPEN',
    tokensymbol3: '',
    tokensymbol4: '',
    tokensymbol5: '',
    image: '48',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0xc630A6D241717bB4f2244966932D348A8b64d7D3',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '462',
    sortOrder: 200,
    isFinished: true,
   },
   {
    sousId: 49,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.cuby,
    number:'49',
    tokensymbol2: 'OPEN',
    tokensymbol3: '',
    tokensymbol4: '',
    tokensymbol5: '',
    image: '49',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0xDe08251B5B40aa9dcF0fECb9720b64ec65eD5876',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '462',
    sortOrder: 200,
    isFinished: true,
   },
  
   {
    sousId: 50,
    stakingToken: tokens.hvi,
    earningToken: tokens.cuby,
    number:'50',
    tokensymbol2: 'OPEN',
    tokensymbol3: '',
    tokensymbol4: '',
    tokensymbol5: '',
    image: '50',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0xD8E32E24539c203a0739B0E278F3605279874c42',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '462',
    sortOrder: 200,
    isFinished: true,
   },

   {
    sousId: 40,
    stakingToken: tokens.lgc,
    earningToken: tokens.lgc,
    number:'40',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'LGC',
    tokensymbol4: '',
    tokensymbol5: 'ALL1',
    image: '40',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '4%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x74339CB10Ff9E68918d2e4E23a4597e1046e89cA',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0694',
    sortOrder: 200,
    isFinished: false,
   },
   {
    sousId: 41,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.lgc,
    number:'41',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'VIZSLASWAP',
    tokensymbol4: '',
    tokensymbol5: 'ALL1',
    image: '41',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '4%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x4fC86dC3E0B03971B4d08990843c754055C8c3D9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.347',
    sortOrder: 200,
    isFinished: false,
   },
   {
    sousId: 42,
    stakingToken: tokens.hvi,
    earningToken: tokens.lgc,
    number:'42',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'HVI',
    tokensymbol4: '',
    tokensymbol5: 'ALL1',
    image: '42',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '4%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0xC79EDA00d5EC26320693eaEE7b09987457CAeB50',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.347',
    sortOrder: 200,
    isFinished: false,
   },
   
   {
    sousId: 37,
    stakingToken: tokens.lira,
    earningToken: tokens.lira,
    number:'37',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'LIRA',
    tokensymbol4: '',
    tokensymbol5: '',
    image: '37',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0xbCE3aFbc9d57F20fb9068258e7E5dE23199470A4',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '3.3',
    sortOrder: 200,
    isFinished: false,
   },

   {
    sousId: 21,
    stakingToken: tokens.lamea,
    earningToken: tokens.lamea,
    number:'21',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'LAMEA',
    tokensymbol4: '',
    tokensymbol5: '',
    image: '21',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0xaddf3F4CC56bDF78851D77206108c5Ca346Aa0ac',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1.38',
    sortOrder: 200,
    isFinished: false,
   },
   {
    sousId: 22,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.lamea,
    number:'22',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'VIZSLASWAP',
    tokensymbol4: '',
    tokensymbol5: 'ALL1',
    image: '22',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '4%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x495Cd5Fc0dF972A028AD50c7be23d2350702B228',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '5.2',
    sortOrder: 200,
    isFinished: false,
   },
   {
    sousId: 23,
    stakingToken: tokens.hvi,
    earningToken: tokens.lamea,
    number:'23',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'HVI',
    tokensymbol4: '',
    tokensymbol5: 'ALL1',
    image: '23',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '4%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x7027A5F70a93fDB1A6488f49db6f3e543E4881B7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '5.2',
    sortOrder: 200,
    isFinished: false,
   },

   {
    sousId: 20,
    stakingToken: tokens.hvi,
    earningToken: tokens.etc,
    number:'20',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'HVI',
    tokensymbol4: 'ANTIWHALE',
    tokensymbol5: 'ALL1',
    image: '20',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '4%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x78D1d79449da238bA76FB47CAff92De04C468622',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00000075',
    sortOrder: 200,
    isFinished: false,
    },
    {
      sousId: 19,
      stakingToken: tokens.vizslaswap,
      earningToken: tokens.etc,
      number:'19',
      tokensymbol2: 'OPEN',
      tokensymbol3: 'VIZSLASWAP',
      tokensymbol4: 'ANTIWHALE',
      tokensymbol5: 'ALL1',
      image: '19',
      withdrawtime: 'WITHDRAWAL FEE',
      withdrawtime2: '4%',
      withdrawtime3: '',
      withdrawtime4: '',
      contractAddress: {
        97: '',
        56: '0xd4B328Ee8a6801ac9e93175C6dB55b70B08aef5E',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.00000075',
      sortOrder: 200,
      isFinished: false,
      },
   {
    sousId: 16,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.shib,
    number:'16',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'VIZSLASWAP',
    tokensymbol4: 'ANTIWHALE',
    tokensymbol5: 'ALL1',
    image: '16',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '4%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0xebcF793c2cF3aeD607Fd64e71ac6BEdAAf7F915A',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.5',
    sortOrder: 200,
    isFinished: false,
   },
  
   {
    sousId: 11,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.ltc,
    number:'11',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'VIZSLASWAP',
    tokensymbol4: '',
    tokensymbol5: 'ALL1',
    image: '11',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '4%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0xf142BD28CaCd766c24201bD2FF4513CEc032e882',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0000002',
    sortOrder: 200,
    isFinished: false,
   },
   {
    sousId: 5,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.eth,
    number:'5',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'VIZSLASWAP',
    tokensymbol4: '',
    tokensymbol5: 'ALL1',
    image: '5',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '4%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0xa05E95D44d746FC833041fCb62103A04850e1851',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00000001',
    sortOrder: 200,
    isFinished: false,
   },
  
   {
    sousId: 4,
    stakingToken: tokens.hvi,
    earningToken: tokens.hvi,
    number:'4',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'HVI',
    tokensymbol4: '',
    tokensymbol5: 'ALL1',
    image: '4',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '4%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0xe78Bf25A181A9b60Aef08a9F5e2079CB745234cb',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '2400000000',
    sortOrder: 200,
    isFinished: false,
   },
   {
    sousId: 7,
    stakingToken: tokens.babyvizsla,
    earningToken: tokens.babyvizsla,
    number:'7',
    tokensymbol2: 'OPEN',
    tokensymbol3: '',
    tokensymbol4: '',
    tokensymbol5: 'ALL1',
    image: '7',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '4%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x646086C73D560B7a4581F6229738c88733C92e4B',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '152000',
    sortOrder: 200,
    isFinished: false,
   },
   {
    sousId: 8,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.babyvizsla,
    number:'8',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'VIZSLASWAP',
    tokensymbol4: '',
    tokensymbol5: 'ALL1',
    image: '8',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '4%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x6688B8f6D2c0aEdeADf8c84d2aCDC87C2E1f02A9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '152000',
    sortOrder: 200,
    isFinished: false,
   },
   {
    sousId: 9,
    stakingToken: tokens.hvi,
    earningToken: tokens.babyvizsla,
    number:'9',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'HVI',
    tokensymbol4: '',
    tokensymbol5: 'ALL1',
    image: '9',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '4%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x0340fdb81618874936535CA5276294fB84A719Ee',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '152000',
    sortOrder: 200,
    isFinished: false,
   },
   {
    sousId: 25,
    stakingToken: tokens.exzo,
    earningToken: tokens.exzo,
    number:'25',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'EXZO',
    tokensymbol4: '',
    tokensymbol5: '',
    image: '25',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x73629E49dC282ef1Fc7AA7A58722C45BBb3496FC',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '11.97',
    sortOrder: 200,
    isFinished: true,
   },
   {
    sousId: 26,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.exzo,
    number:'26',
    tokensymbol2: 'OPEN',
    tokensymbol3: '',
    tokensymbol4: '',
    tokensymbol5: '',
    image: '26',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0xB04284523BbB8c5945C3fcB797A4Af0d8cafb720',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '8.97',
    sortOrder: 200,
    isFinished: true,
   },
   {
    sousId: 27,
    stakingToken: tokens.hvi,
    earningToken: tokens.exzo,
    number:'27',
    tokensymbol2: 'OPEN',
    tokensymbol3: '',
    tokensymbol4: '',
    tokensymbol5: '',
    image: '27',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0xD2E4A090098a7005EA45f4D57DE6aEDc636c0624',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '8.97',
    sortOrder: 200,
    isFinished: true,
   },
   {
    sousId: 32,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.trip,
    number:'32',
    tokensymbol2: 'OPEN',
    tokensymbol3: '',
    tokensymbol4: '',
    tokensymbol5: '',
    image: '32',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x40f69629aeaC357826188Ce3d63a1720B7D351ab',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.694',
    sortOrder: 200,
    isFinished: true,
   },
   {
    sousId: 18,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.ace,
    number:'18',
    tokensymbol2: 'OPEN',
    tokensymbol3: '',
    tokensymbol4: '',
    tokensymbol5: '',
    image: '18',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x89Cf97e3D99FDE78670BB9D87266BA366B626bca',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '370',
    sortOrder: 200,
    isFinished: false,
    },
     {
      sousId: 33,
      stakingToken: tokens.ltc,
      earningToken: tokens.vizslaswap,
      number:'33',
      tokensymbol2: 'OPEN',
      tokensymbol3: '',
      tokensymbol4: '',
      tokensymbol5: '',
      image: '33',
      withdrawtime: 'WITHDRAWAL FEE',
      withdrawtime2: '0%',
      withdrawtime3: '',
      withdrawtime4: '',
      contractAddress: {
        97: '',
        56: '0x59118c750a5c704001574c0693c1790196993c23',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.0035',
      sortOrder: 200,
      isFinished: true,
     },
     {
      sousId: 35,
      stakingToken: tokens.eth,
      earningToken: tokens.vizslaswap,
      number:'35',
      tokensymbol2: 'OPEN',
      tokensymbol3: '',
      tokensymbol4: '',
      tokensymbol5: '',
      image: '35',
      withdrawtime: 'WITHDRAWAL FEE',
      withdrawtime2: '0%',
      withdrawtime3: '',
      withdrawtime4: '',
      contractAddress: {
        97: '',
        56: '0xfd191349e26e939d0c424F5BA1069Da1Ddb07602',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.0035',
      sortOrder: 200,
      isFinished: true,
     },
     {
      sousId: 34,
      stakingToken: tokens.etc,
      earningToken: tokens.vizslaswap,
      number:'34',
      tokensymbol2: 'OPEN',
      tokensymbol3: '',
      tokensymbol4: '',
      tokensymbol5: '',
      image: '34',
      withdrawtime: 'WITHDRAWAL FEE',
      withdrawtime2: '0%',
      withdrawtime3: '',
      withdrawtime4: '',
      contractAddress: {
        97: '',
        56: '0x4beBa40b480cF3d60caACC23C3a07043AD72880F',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.0035',
      sortOrder: 200,
      isFinished: true,
     },
     {
      sousId: 36,
      stakingToken: tokens.wbnb,
      earningToken: tokens.vizslaswap,
      number:'36',
      tokensymbol2: 'OPEN',
      tokensymbol3: '',
      tokensymbol4: '',
      tokensymbol5: '',
      image: '36',
      withdrawtime: 'WITHDRAWAL FEE',
      withdrawtime2: '0%',
      withdrawtime3: '',
      withdrawtime4: '',
      contractAddress: {
        97: '',
        56: '0xD2d8b41A9F502e50C13839A43bc76cDA1d6646bA',
      },
      poolCategory: PoolCategory.CORE,
      harvest: true,
      tokenPerBlock: '0.001',
      sortOrder: 200,
      isFinished: true,
     },
   {
    sousId: 29,
    stakingToken: tokens.atalira,
    earningToken: tokens.atalira,
    number:'29',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'ATALIRA',
    tokensymbol4: '',
    tokensymbol5: '',
    image: '29',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0xc17c5D59ea8475d6eA987F654247Fba120166303',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0249',
    sortOrder: 200,
    isFinished: true,
   },
   {
    sousId: 24,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.atalira,
    number:'24',
    tokensymbol2: 'OPEN',
    tokensymbol3: '',
    tokensymbol4: '',
    tokensymbol5: '',
    image: '24',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0xe8B5c3eD7E3e399C7B6563A6eb981fa0B9CC195A',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.5',
    sortOrder: 200,
    isFinished: true,
   },
   {
    sousId: 30,
    stakingToken: tokens.hvi,
    earningToken: tokens.atalira,
    number:'30',
    tokensymbol2: 'OPEN',
    tokensymbol3: '',
    tokensymbol4: '',
    tokensymbol5: '',
    image: '30',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x6Ee2F91786Df82f2B7d69C7DBd270D6F180243e6',
    }, 
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.5',
    sortOrder: 200,
    isFinished: true,
   },
   {
    sousId: 38,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.lira,
    number:'38',
    tokensymbol2: 'OPEN',
    tokensymbol3: '',
    tokensymbol4: '',
    tokensymbol5: '',
    image: '38',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x8e50EC840DB5a4A5994B9c2D4A7840474E6e6d5a',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '3.3',
    sortOrder: 200,
    isFinished: true,
   },
   {
    sousId: 39,
    stakingToken: tokens.hvi,
    earningToken: tokens.lira,
    number:'39',
    tokensymbol2: 'OPEN',
    tokensymbol3: '',
    tokensymbol4: '',
    tokensymbol5: '',
    image: '39',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x3Bb72c6BF377211B52D67f931692415D97148e09',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '3.3',
    sortOrder: 200,
    isFinished: true,
   },
   {
    sousId: 1,
    stakingToken: tokens.bvc,
    earningToken: tokens.bvc,
    number:'1',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'BVC',
    tokensymbol4: '',
    tokensymbol5: '',
    image: '1',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0xb903485C032E93655B5d5CFe4A439018ACb9E211',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.125',
    sortOrder: 200,
    isFinished: true,
   },
   {
    sousId: 2,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.bvc,
    number:'2',
    tokensymbol2: 'OPEN',
    tokensymbol3: '',
    tokensymbol4: '',
    tokensymbol5: '',
    image: '2',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0xceaBfaA080d55E7375E836eEfc005cAF26CacFDb',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0625',
    sortOrder: 200,
    isFinished: true,
   },
   {
    sousId: 3,
    stakingToken: tokens.hvi,
    earningToken: tokens.bvc,
    number:'3',
    tokensymbol2: 'OPEN',
    tokensymbol3: '',
    tokensymbol4: '',
    tokensymbol5: '',
    image: '3',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0xe1445b4Bdd342d45C4DcAe52D4DDD1F30601C393',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0625',
    sortOrder: 200,
    isFinished: true,
   },
   {
    sousId: 47,
    stakingToken: tokens.lgc,
    earningToken: tokens.btc,
    number:'47',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'LGC',
    tokensymbol4: 'ANTIWHALE',
    tokensymbol5: '',
    image: '47',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x80608eCbD49bA59d8BCE666c7fd561496046528B',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00000000165',
    sortOrder: 200,
    isFinished: false,
   },
   {
    sousId: 46,
    stakingToken: tokens.lgc,
    earningToken: tokens.usdt,
    number:'46',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'LGC',
    tokensymbol4: 'ANTIWHALE',
    tokensymbol5: '',
    image: '46',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '0%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x8994Fc781b727b463220a8B2af235cb11059dA74',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.000034',
    sortOrder: 200,
    isFinished: false,
   },
   // B2MINING

   {
    sousId: 100,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.lp,
    number:'100',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'VIZSLASWAP2',
    tokensymbol4: '',
    tokensymbol5: 'ALL2',
    image: '100',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '4%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x96e41f4e0F885121756b84fe966C39ED92047968',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.086',
    sortOrder: 200,
    isFinished: false,
   },
   {
    sousId: 104,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.lp5,
    number:'104',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'VIZSLASWAP2',
    tokensymbol4: '',
    tokensymbol5: 'ALL6',
    image: '104',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '4%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x37031eD3a1adf0C4C72d3EB6832fDc3D1af42d2a',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0108',
    sortOrder: 200,
    isFinished: false,
   },
   {
    sousId: 105,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.lp6,
    number:'105',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'VIZSLASWAP2',
    tokensymbol4: '',
    tokensymbol5: 'ALL7',
    image: '105',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '4%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x1F125E10C83024D40B50077D76Cc119f63eF967A',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.082',
    sortOrder: 200,
    isFinished: false,
   },
   {
    sousId: 102,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.lp3,
    number:'102',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'VIZSLASWAP2',
    tokensymbol4: '',
    tokensymbol5: 'ALL4',
    image: '102',
    withdrawtime: 'WITHDRAWAL FEE',
    withdrawtime2: '4%',
    withdrawtime3: '',
    withdrawtime4: '',
    contractAddress: {
      97: '',
      56: '0x47cE6b2Ec96D1576Da6D1B714C6ffd455E31132a',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.01',
    sortOrder: 200,
    isFinished: false,
   },

   // VOTE
   {
    sousId: 100001,
    stakingToken: tokens.hvi,
    earningToken: tokens.hvi,
    number:'100001',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'VIZSLASWAP',
    tokensymbol4: '',
    tokensymbol5: 'ALL99999',
    image: '1000001',
    withdrawtime: '3',
    withdrawtime2: '30%',
    withdrawtime3: 'Total locked',
    withdrawtime4: '1000000',
    contractAddress: {
      97: '',
      56: '0x2BCB14C24F95E1D6978e1d9dcA5A1a01F4a68D88',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0',
    sortOrder: 200,
    isFinished: false,
   },
   {
    sousId: 100000,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.vizslaswap,
    number:'100000',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'VIZSLASWAP',
    tokensymbol4: '',
    tokensymbol5: 'ALL99999', 
    image: '1000000',
    withdrawtime: '1',
    withdrawtime2: '0%',
    withdrawtime3: 'Total locked',
    withdrawtime4: '1000000',
    contractAddress: {
      97: '',
      56: '0x7F4ddf4F41bf7306D76bC2DDf46f286DBfBC5c4C',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0',
    sortOrder: 200,
    isFinished: true,
   },
   {
    sousId: 100003,
    stakingToken: tokens.vizslaswap,
    earningToken: tokens.vizslaswap,
    number:'100003',
    tokensymbol2: 'OPEN',
    tokensymbol3: 'VIZSLASWAP',
    tokensymbol4: '',
    tokensymbol5: 'ALL99998', 
    image: '1000000',
    withdrawtime: '1',
    withdrawtime2: '50%',
    withdrawtime3: 'Total locked',
    withdrawtime4: '2000000',
    contractAddress: {
      97: '',
      56: '0x215BeF6E6dDF19ACE123610EC8b4B49E9d8A68BA',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true, 
    tokenPerBlock: '0',
    sortOrder: 200,
    isFinished: false,
   },    
]

export default pools

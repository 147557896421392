import styled, { css,keyframes } from 'styled-components'
import { Button, Text, Heading } from '@pancakeswap/uikit';

export const QuizContainer = styled.div`
  padding: 30px;
  border-radius: 15px;  
  background: #1e1e2e;
  color: #eaeaea;            
  text-align: center;
  max-width: 600px; 
  margin: 0 auto;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  gap: 20px; 
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const ScoreText = styled(Text)`
  font-size: 22px;
  font-weight: bold;   
  color: #fff;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
`;

export const QuestionText = styled(Text)`
  font-size: 16px;
    font-weight: bold;
      text-align: left;
  color: #ffffff;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
`;
export const QuestionText2 = styled(Text)`
  font-size: 18px;
    font-weight: bold;
  color: #ffffff;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  gap: 15px;
`;


export const OptionButton = styled(Button)`
  padding: 15px;
  border-radius: 15px; /* Make the button corners sharp */
  background-color: gold; /* Set background color */
  color: #fff;
    text-align: left; /* Align text to the left */
      justify-content: flex-start; /* Ensure content aligns to the left */
ň
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  
  &:hover {
    background-color: gold; /* Keep hover color the same */
    transform: scale(1.05);
  }
  
  &:active {
    transform: scale(1);
  }
  
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
export const SkipButtonWrapper = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
`;

export const SkipButton = styled(Button)`
  width: 100%; // Allow buttons to size based on content
  padding: 6px 12px; // Smaller padding for a compact look
  font-size: 14px; // Adjust font size
  margin: 5px;
  min-width: 80px; // Set a minimum width to keep them consistent
`;

export const TimerText = styled(Text)`
  font-size: 18px;
  color: gold;
  margin-top: -10px;
  font-weight: bold;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
`;

export const GameOverContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;
export const QuestionBox = styled.div`
  padding: 20px;
  border-radius: 10px;
  background: #16161f;
  color: #ffffff;
  text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  font-size: 20px; 
  font-weight: bold;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px; /* Távolság a cím és a kép között */
  margin-top: 32px;
`;
const runningLightAnimation = keyframes`
  0% {
    text-shadow: 0 0 2.5px gold, 0 0 5px gold, 0 0 7.5px gold, 0 0 10px gold;
  }
  50% {
    text-shadow: 0 0 2.5px gold, 0 0 5px gold, 0 0 7.5px gold, 0 0 10px gold;
  }
  100% {
    text-shadow: 0 0 2.5px gold, 0 0 5px gold, 0 0 7.5px gold, 0 0 10px goldT;
  }
`;
export const Title = styled(Heading)`
    color: #fff;
        text-shadow: 0 0 2.5px gold, 0 0 5px gold, 0 0 7.5px gold, 0 0 10px gold;
    margin-top: 32px;
        margin-bottom: 20px;
`;
export const PlayAgainButton = styled(Button)`
  padding: 12px 24px;
  border-radius: 10px;
  font-weight: bold;
  margin-top: 20px;
   transition: background-color 0.3s, box-shadow 0.3s;
  &:hover {
    background: #e6ac00; /* Sötétebb arany hover állapotban */
        color: #fff; /* Sötétebb arany hover állapotban */
   }
  &:active {
    transform: scale(0.98);
  }
`;

import React, { useMemo, useState } from 'react'
import { useLocation, NavLink } from 'react-router-dom'
import BigNumber from 'bignumber.js/bignumber'
import { useWeb3React } from '@web3-react/core/core/core'
import styled, { DefaultTheme, keyframes, css } from "styled-components";
import { Modal, Text, Flex, Button, AutoRenewIcon, Box , Card, Link, useModal, VerifiedIcon} from '@pancakeswap/uikit'
import { getBalanceNumber } from 'utils/formatBalance'
import { useCakeVaultContract5 } from 'hooks/useContract3'
import useTheme from 'hooks/useTheme'
import useToast from 'hooks/useToast'
import { useTranslation } from 'contexts/Localization'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Balance from 'components/Balance'
import { usePriceCakeBusd } from 'state/farms/hooks'
import {useFetchPublicPoolsData, usePools, useFetchCakeVault, useCakeVault } from 'state/poolsDrop3/hooks'
import { getCakeAddress, getHVIAddress, getHmttAddress, getlgcAddress } from 'utils/addressHelpers'
import useTokenBalance,{ useFaucet2,useGetBnbBalance } from 'hooks/useTokenBalance'
import getTimePeriods from 'utils/getTimePeriods'
import ModalInfo from './ModalInfo'
import ModalInfo2 from './ModalInfo2'
import ModalInfo3 from './ModalInfo3'
import ModalInfo4 from './ModalInfo4'
import ModalInfo5 from './ModalInfo5'

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;
const ImageWrapper = styled.div`
   animation: ${fadeIn} 2s ease-in-out;


${({ theme }) => theme.mediaQueries.md} {
   animation: ${fadeIn} 2s ease-in-out;

}
`
const StyledButton = styled(Button)`
background: black; /* Fekete háttér */
 border: 2px solid #62d5a3; 
 color: white;
 font-weight: bold;
 border-radius: 10px;
 padding: 10px 20px;
 transition: transform 0.3s ease, box-shadow 0.3s ease;
 position: relative;
 overflow: hidden;

 &:hover {
   transform: translateY(-2px);
   box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
   background: gold; /* Arany színre változtatjuk a hátteret */
   color: black; /* A szöveg színe megváltozik, hogy kontrasztos legyen */
 }

 &:disabled {
   background: linear-gradient(45deg, #d3d3d3, #b0b0b0);
   cursor: not-allowed;
 }

 &::before {
   content: 'GO';
   position: absolute;
   left: -100%;
   top: 50%;
   transform: translateY(-50%);
   color: gold;
   font-weight: bold;
   opacity: 0;
   transition: transform 0.5s, opacity 0.5s;
 }

 &:hover::before {
   transform: translateY(-50%) translateX(100%);
   opacity: 1;
   color: black; /* A "GO" szöveg színe megváltozik, hogy kontrasztos legyen */
 }

 & span {
   transition: opacity 0.5s;
 }

 &:hover span {
   opacity: 0; /* Az eredeti szöveg elhalványítása hover állapotban */
 }
`;
interface BountyModalProps {
  onDismiss?: () => void
}
const RainbowLight = keyframes`
0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
`;
 
const StyledModalContainer = styled(Box)`
  max-width: 1200px;
  width: 100%;
  border-radius: 25px;

  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 1200px;
    width: 100%;
    border-radius: 25px;
  }
`
const StyledModalContainer4 = styled(Card)`
  width: 100%;
  background-color: rgba(0, 0, 0);

  ${({ theme }) => theme.mediaQueries.md} {
    width: 100%;
    background-color: rgba(0, 0, 0);
  }
`
const StyledModalContainer5 = styled(Card)`
  width: 100%;
  background-color: rgba(0, 0, 0);
  background: conic-gradient(
    from 0deg,
    rgba(80, 155, 212, 1) 0%, /* #509bd4 1 */
    rgba(80, 155, 212, 1) 36deg, /* #509bd4 4 */
    rgba(80, 155, 212, 1) 36deg, /* #509bd4 5 */
    rgba(0, 40, 0, 1) 216deg, /* Green 5 (full circle) */
    rgba(0, 40, 0, 1) 288deg, /* Green 5 */
    rgba(80, 155, 212, 1) 288deg, /* #509bd4 3 */
    rgba(80, 155, 212, 1) 288deg, /* #509bd4 4 */
    rgba(0, 60, 0, 1) 288deg, /* Green 4 */
    rgba(0, 60, 0, 1) 360deg, /* Green 4 (full circle) */
    rgba(80, 155, 212, 1) 360deg, /* #509bd4 2 (full circle) */
    rgba(80, 155, 212, 1) 360deg, /* #509bd4 3 (full circle) */
    rgba(0, 80, 0, 1) 360deg, /* Green 3 (full circle) */
    rgba(0, 80, 0, 1) 432deg, /* Green 3 */
    rgba(80, 155, 212, 1) 432deg, /* #509bd4 1 (full circle) */
    rgba(0, 100, 0, 1) 432deg, /* Green 2 (full circle) */
    rgba(0, 100, 0, 1) 504deg, /* Green 2 */
    rgba(80, 155, 212, 1) 504deg, /* #509bd4 1 */
    rgba(80, 155, 212, 1) 504deg, /* #509bd4 4 */
    rgba(80, 155, 212, 1) 504deg, /* #509bd4 5 */
    rgba(0, 40, 0, 1) 684deg, /* Green 5 (full circle) */
    rgba(0, 40, 0, 1) 756deg, /* Green 5 */
    rgba(80, 155, 212, 1) 756deg, /* #509bd4 3 */
    rgba(80, 155, 212, 1) 756deg, /* #509bd4 4 */
    rgba(0, 60, 0, 1) 756deg, /* Green 4 */
    rgba(0, 60, 0, 1) 828deg, /* Green 4 (full circle) */
    rgba(80, 155, 212, 1) 828deg, /* #509bd4 2 (full circle) */
    rgba(80, 155, 212, 1) 828deg, /* #509bd4 3 (full circle) */
    rgba(0, 80, 0, 1) 828deg, /* Green 3 (full circle) */
    rgba(0, 80, 0, 1) 900deg /* Green 3 (full circle) */
);
  background-size: 290% 290%;
  opacity: 100%;
  animation: ${RainbowLight} 2s linear infinite;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 100%;
    background: conic-gradient(
      from 0deg,
      rgba(80, 155, 212, 1) 0%, /* #509bd4 1 */
      rgba(80, 155, 212, 1) 36deg, /* #509bd4 4 */
      rgba(80, 155, 212, 1) 36deg, /* #509bd4 5 */
      rgba(0, 40, 0, 1) 216deg, /* Green 5 (full circle) */
      rgba(0, 40, 0, 1) 288deg, /* Green 5 */
      rgba(80, 155, 212, 1) 288deg, /* #509bd4 3 */
      rgba(80, 155, 212, 1) 288deg, /* #509bd4 4 */
      rgba(0, 60, 0, 1) 288deg, /* Green 4 */
      rgba(0, 60, 0, 1) 360deg, /* Green 4 (full circle) */
      rgba(80, 155, 212, 1) 360deg, /* #509bd4 2 (full circle) */
      rgba(80, 155, 212, 1) 360deg, /* #509bd4 3 (full circle) */
      rgba(0, 80, 0, 1) 360deg, /* Green 3 (full circle) */
      rgba(0, 80, 0, 1) 432deg, /* Green 3 */
      rgba(80, 155, 212, 1) 432deg, /* #509bd4 1 (full circle) */
      rgba(0, 100, 0, 1) 432deg, /* Green 2 (full circle) */
      rgba(0, 100, 0, 1) 504deg, /* Green 2 */
      rgba(80, 155, 212, 1) 504deg, /* #509bd4 1 */
      rgba(80, 155, 212, 1) 504deg, /* #509bd4 4 */
      rgba(80, 155, 212, 1) 504deg, /* #509bd4 5 */
      rgba(0, 40, 0, 1) 684deg, /* Green 5 (full circle) */
      rgba(0, 40, 0, 1) 756deg, /* Green 5 */
      rgba(80, 155, 212, 1) 756deg, /* #509bd4 3 */
      rgba(80, 155, 212, 1) 756deg, /* #509bd4 4 */
      rgba(0, 60, 0, 1) 756deg, /* Green 4 */
      rgba(0, 60, 0, 1) 828deg, /* Green 4 (full circle) */
      rgba(80, 155, 212, 1) 828deg, /* #509bd4 2 (full circle) */
      rgba(80, 155, 212, 1) 828deg, /* #509bd4 3 (full circle) */
      rgba(0, 80, 0, 1) 828deg, /* Green 3 (full circle) */
      rgba(0, 80, 0, 1) 900deg /* Green 3 (full circle) */
  );
    background-size: 290% 290%;
    animation: ${RainbowLight} 2s linear infinite;
    min-width: 228px;
    background-color: rgba(0, 0, 0);
    opacity: 100%;
    animation: ${RainbowLight} 3s linear infinite;
  }
`
 

const ButtonText = styled(Text)`
  display: none;
  ${({ theme }) => theme.mediaQueries.xs} {
    display: block;
  }
`
const BountyCard: React.FC<BountyModalProps> = ({ onDismiss }) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { theme } = useTheme()
   const TooltipComponent = ({ fee }: { fee: number }) => (
    <>
      <Text mb="16px">{t('This bounty is given as a reward for providing a service to other users.')}</Text>
      <Text mb="16px">
        {t(
          'Whenever you successfully claim the bounty, you’re also helping out by activating the Auto VIZSLASWAP Pool’s compounding function for everyone.',
        )}
      </Text>
      <Text style={{ fontWeight: 'bold' }}>
        {t('Auto-Compound Bounty: %fee%% of all Auto VIZSLASWAP pool users pending yield', { fee: fee / 100 })}
      </Text>
    </>
  )
  const [onPresentBountyModal] = useModal(<ModalInfo TooltipComponent={TooltipComponent} />)
  const [onPresentBountyModal2] = useModal(<ModalInfo2 TooltipComponent={TooltipComponent} />)
  const [onPresentBountyModal3] = useModal(<ModalInfo3 TooltipComponent={TooltipComponent} />)
  const [onPresentBountyModal4] = useModal(<ModalInfo4 TooltipComponent={TooltipComponent} />)
  const [onPresentBountyModal5] = useModal(<ModalInfo5 TooltipComponent={TooltipComponent} />)

  const cakeVaultContract = useCakeVaultContract5()
  const [pendingTx, setPendingTx] = useState(false)
  const {
    estimatedCakeBountyReward,
    totalPendingCakeHarvest,
    userData: { lastDepositedTime },
    fees: { callFee },
  } = useCakeVault()
   const nem =  parseInt(lastDepositedTime)
  
   useFetchCakeVault()
   useFetchPublicPoolsData()
  const cakePriceBusd = usePriceCakeBusd()
  const callFeeAsDecimal = callFee / 100
  const FULL3 = getBalanceNumber(useFaucet2(getlgcAddress()))
  const FULL4 =  FULL3 /7000
  const totalYieldToDisplay = getBalanceNumber(totalPendingCakeHarvest, 18)

  const estimatedDollarBountyReward = useMemo(() => {
    return new BigNumber(estimatedCakeBountyReward).multipliedBy(cakePriceBusd)
  }, [cakePriceBusd, estimatedCakeBountyReward])

  const hasFetchedDollarBounty = estimatedDollarBountyReward.gte(0)
  const hasFetchedCakeBounty = estimatedCakeBountyReward ? estimatedCakeBountyReward.gte(0) : false
  const dollarBountyToDisplay = hasFetchedDollarBounty ? getBalanceNumber(estimatedDollarBountyReward, 18) : 0
  const cakeBountyToDisplay = hasFetchedCakeBounty ? getBalanceNumber(estimatedCakeBountyReward, 18) : 0
 
  const shouldShowTimer = account && lastDepositedTime
  const goo = shouldShowTimer 
  const goo0 = parseInt(goo)
  const goo3 = goo0
  const currentTimestamp = () => new Date().getTime()
  const anyad  = currentTimestamp()
  const anyad2 = anyad / 1000
  const goo2 = goo3 - anyad2
  const { months,days, hours, minutes } = getTimePeriods(goo2)
  const getRowText = () => {
    if ( goo2 > 0) {
          return   <Text bold fontSize="14px" >{t('%day%d : %hour%h : %minute%m', { day: days, hour: hours, minute: minutes })}</Text>
    }
    if ( goo2 < 0) {
      return   <Text textAlign="left" fontSize="14px" bold color='contrast' >   
      {t('NOW')}
       </Text>  
    }
    return <Text bold fontSize="14px">{t('%day%d : %hour%h : %minute%m', { day: days, hour: hours, minute: minutes })}</Text>
  }
  // VIZSLA + HVI
  const stakeAmount = ('20000000000000000000000') 
  const LOW_GOD_BALANCE = stakeAmount // 2 Gwei  
  const { balance, fetchStatus } = useGetBnbBalance()  
  const { balance: cakeBalance3 } = useTokenBalance(getCakeAddress())  
  const hasLowGod2Balance = cakeBalance3.isGreaterThanOrEqualTo(LOW_GOD_BALANCE)
  const stakeAmount2 = ('50000000000000000000000')
  const LOW_GOD_BALANCE2 = stakeAmount2 // 2 Gwei
  const { balance: cakeBalance4 } = useTokenBalance(getCakeAddress())
  const hasLowGod3Balance = cakeBalance4.isGreaterThanOrEqualTo(LOW_GOD_BALANCE2)
  const stakeAmount3 = ('5000000000000000000000000')
  const LOW_GOD_BALANCE3 = stakeAmount3 // 2 Gwei
  const { balance: cakeBalance5 } = useTokenBalance(getHVIAddress())
  const hasLowGod4Balance = cakeBalance5.isGreaterThanOrEqualTo(LOW_GOD_BALANCE3)
  const stakeAmount4 = ('100000000000000000000000') 
  const LOW_GOD_BALANCE4 = stakeAmount4 // 2 Gwei 
  const { balance: cakeBalance6 } = useTokenBalance(getCakeAddress())
  const hasLowGod5Balance = cakeBalance6.isGreaterThanOrEqualTo(LOW_GOD_BALANCE4)

 
   
  const getRowText3 = () => { 
    if (hasLowGod3Balance  && !hasLowGod4Balance) {
      return <Text fontSize="15px">{t('50 VIZSLASWAP + 1,250,000,000,000 HVI')}</Text>
    }   
  
    if (hasLowGod5Balance && hasLowGod4Balance) { 
      return <Text fontSize="15px">{t('50 VIZSLASWAP + 1,250,000,000,000 HVI')}</Text>
    }    

  
    return <Text fontSize="15px">{t('50 VIZSLASWAP + 1,250,000,000,000 HVI')}</Text>
  } 

  const getRowText4 = () => { 
    if (hasLowGod3Balance  && !hasLowGod4Balance) {
      return <Text fontSize="15px">{t('1,500,000,000 BABYVIZSLA')}</Text>
    }   
  
    if (hasLowGod5Balance && hasLowGod4Balance) { 
      return <Text fontSize="15px">{t('1,500,000,000 BABYVIZSLA')}</Text>
    }    

    return <Text fontSize="15px">{t('1,500,000,000 BABYVIZSLA')}</Text>
  } 
  const handleConfirmClick = async () => {
    setPendingTx(true)
    try {
      const tx = await cakeVaultContract.harvest()
      const receipt = await tx.wait()
      if (receipt.status) {
         setPendingTx(false)
        onDismiss()
      }
    } catch (error) {
       setPendingTx(false) 
    }
  }

  return (
    <StyledModalContainer>   

      <Flex alignItems="center" justifyContent="center">
      <StyledModalContainer5>
      <StyledModalContainer4>
      <Flex alignItems="center" justifyContent="center" mb="10px" mt="20px">
      <ImageWrapper>
        <img src="/images/pixel/372.png" alt="ifo bunny" width="220px" height="187px"/>
      </ImageWrapper>
      </Flex>   

      <Flex alignItems="center" justifyContent="center">
  
      <Flex alignItems="center" justifyContent="center"   mt="5px">
      
      <Flex   flexDirection="column" mr={['8px', 0]}>

      <Flex alignItems="center" justifyContent="center">
      {account ? ( 
        <StyledButton
          isLoading={pendingTx}
          endIcon={pendingTx ? <AutoRenewIcon spin color="currentColor" /> : null}
          onClick={handleConfirmClick}
          id="autoCakeConfirmBounty"
          width="100px" height="25px"
          disabled={FULL3 < 0  || goo2 > 0}
          variant="secondary"
        >
             <ButtonText color="primary" bold fontSize="14px">
             <Flex alignItems="center" justifyContent="space-between"  >
             {pendingTx ? t('Claiming') : t('Claim')}
                </Flex>
         </ButtonText>
        </StyledButton>
      ) : (
        <ConnectWalletButton width="100px" height="25px"/>
      )}
               <Link external href="https://livegreencoin.com/">
               <Flex ml="4px"  mt="3px" >
         <img src="/images/pixel/365.png" alt="ifo bunny" width="20px" height="20px"/>
         </Flex> 
         </Link> 
         <Link external href="https://bscscan.com/address/0xE06c18446Ee2A08bCd47d84Ec034636cAc5B97C0">
               <Flex ml="4px"  mt="3px" >
         <img src="/images/pixel/69.png" alt="ifo bunny" width="20px" height="20px"/>
         </Flex> 
         </Link> 
         <Flex ml="4px"  mt="4px" onClick={onPresentBountyModal}> 
         <img src="/images/pixel/366.png" alt="ifo bunny" width="20px" height="20px"  />
         </Flex> 
 
         </Flex> 
         <Flex alignItems="center" justifyContent="center" mt="5px" mb="-5px">
         {!hasLowGod5Balance && !hasLowGod3Balance && 
         <Text color="gold"  bold fontSize="15px">
              {t('7000 LGC')} 
            </Text>
            }
                 {hasLowGod3Balance &&  hasLowGod2Balance && !hasLowGod5Balance && 
         <Text color="gold"  bold fontSize="15px">
              {t('14000 LGC')} 
            </Text>
            }
                   {hasLowGod3Balance &&  hasLowGod2Balance && hasLowGod5Balance && 
         <Text color="gold"  bold fontSize="15px">
              {t('28000 LGC')} 
            </Text> 
            } 
      </Flex>    
  
        </Flex>  
        </Flex>
        </Flex>  
      <Flex  alignItems="center" flexDirection="column" mr="15px" mt="5px">
      <Flex  alignItems="center" justifyContent="center" >
 
         <Text color="isten" bold fontSize="14px">
              {t('Remaining:')} 
            </Text>
            <Balance
              bold
              ml="4px"
              fontSize="14px"
              decimals={0}
              unit=" Users"
              prefix="for "
              value={FULL4}
            />

            </Flex>
            <Flex ml="17px" alignItems="center" justifyContent="center"  >
            <Text color="isten" bold fontSize="14px" mr="4px">
              {t('Claim again:')}
            </Text>
            <Text  color="text" fontSize='14px' ml="4px" >
          {getRowText()}
            </Text>
             </Flex>
               </Flex>
               <Flex ml="17px" alignItems="center" justifyContent="center" >
            <Text color="isten" bold fontSize="14px" mr="4px">
              {t('Chance for double reward:')}
            </Text>
            <Text  color="text" bold fontSize='14px' ml="4px" >
            {t('15%')} 
            </Text>
             </Flex> 
   
               <Flex alignItems="center" justifyContent="center"  mt="15px">
 
        <Flex alignItems="center" justifyContent="center" ml="15px" mt="5px">
        <ImageWrapper>
        <img src="/images/pixel/000.png" alt="ifo bunny" width="60px" height="60px"/>
      </ImageWrapper>
      <Flex  flexDirection="column" mr={['8px', 0]}>
      {!hasLowGod5Balance && !hasLowGod3Balance && 
         <Text   bold fontSize="15px">
              {t('50 VIZSLASWAP + 1,250,000,000,000 HVI')} 
            </Text>
            }
                 {hasLowGod3Balance &&  hasLowGod2Balance && !hasLowGod5Balance && 
         <Text   bold fontSize="15px">
              {t('100 VIZSLASWAP + 2,500,000,000,000 HVI')} 
            </Text>
            }
                   {hasLowGod3Balance &&  hasLowGod2Balance && hasLowGod5Balance && 
         <Text   bold fontSize="15px">
              {t('200 VIZSLASWAP + 5,000,000,000,000 HVI')} 
            </Text> 
            } 
      <Flex   alignItems="center" justifyContent="left" > 

      <StyledButton
          width="100px" height="25px"
           variant="secondary"
          to="/airdrops" 
           as={NavLink}
        >
             {t('Open')}
        </StyledButton>
        <Link external href="https://vizslaswap.com/">
        <Flex ml="4px"  mt="3px" >
         <img src="/images/pixel/365.png" alt="ifo bunny" width="20px" height="20px"/>
         </Flex> 
         </Link> 
         <Link external href="https://bscscan.com/address/0x8FdfDd2871944f5155c1801B4B31D1698cCdBd32">
               <Flex ml="4px"  mt="3px" >
         <img src="/images/pixel/69.png" alt="ifo bunny" width="20px" height="20px"/>
         </Flex> 
         </Link> 
         <Flex ml="4px"  mt="4px" onClick={onPresentBountyModal3}> 
         <img src="/images/pixel/366.png" alt="ifo bunny" width="20px" height="20px"  />
         </Flex> 
        </Flex>
        </Flex>  
        </Flex>
        </Flex>
        <Flex alignItems="center" justifyContent="center"  >

<Flex alignItems="center" justifyContent="center" ml="15px" mt="5px">
<ImageWrapper>
<img src="/images/pixel/006.png" alt="ifo bunny" width="60px" height="60px"/>
</ImageWrapper>
<Flex  flexDirection="column" mr={['8px', 0]}>
{!hasLowGod5Balance && !hasLowGod3Balance && 
 <Text   bold fontSize="15px">
      {t('1 bethustlers')} 
    </Text>
    }
         {hasLowGod3Balance &&  hasLowGod2Balance && !hasLowGod5Balance && 
 <Text   bold fontSize="15px">
      {t('2 bethustlers')} 
    </Text>
    }
           {hasLowGod3Balance &&  hasLowGod2Balance && hasLowGod5Balance && 
 <Text  bold fontSize="15px">
      {t('4 bethustlers')} 
    </Text> 
    }
<Flex   alignItems="center" justifyContent="center" > 
<StyledButton
  width="100px" height="25px"
   variant="secondary"
  to="/airdropsbethustlers" 
   as={NavLink}
>
     {t('Open')} 
</StyledButton>
<Link external href="https://bethustlerstoken.com/">
<Flex ml="4px"  mt="3px" >

 <img src="/images/pixel/365.png" alt="ifo bunny" width="20px" height="20px"/>
 </Flex>  
 
             </Link> 
             <Link external href="https://bscscan.com/address/0x8c01b0f92c47ac610bceefccf0abb877ab7991e0">
       <Flex ml="4px"  mt="3px" >
 <img src="/images/pixel/69.png" alt="ifo bunny" width="20px" height="20px"/>
 </Flex> 
 </Link> 
             <Flex ml="4px"  mt="4px" onClick={onPresentBountyModal5}> 
 <img src="/images/pixel/366.png" alt="ifo bunny" width="20px" height="20px"  />
  </Flex> 

</Flex>
</Flex>
</Flex>   
</Flex>

         <Flex alignItems="center" justifyContent="center" >

        <Flex alignItems="center" justifyContent="center" ml="15px" mt="5px">
        <ImageWrapper>
        <img src="/images/pixel/003.png" alt="ifo bunny" width="60px" height="60px"/>
      </ImageWrapper>
      <Flex  flexDirection="column" mr={['8px', 0]}>
      {!hasLowGod5Balance && !hasLowGod3Balance && 
         <Text   bold fontSize="15px">
              {t('3,500,000,000 BABYVIZSLA')} 
            </Text>
            }
                 {hasLowGod3Balance &&  hasLowGod2Balance && !hasLowGod5Balance && 
         <Text   bold fontSize="15px">
              {t('7,000,000,000 BABYVIZSLA')} 
            </Text>
            }
                   {hasLowGod3Balance &&  hasLowGod2Balance && hasLowGod5Balance && 
         <Text   bold fontSize="15px">
              {t('14,000,000,000 BABYVIZSLA')} 
            </Text>  
            } 
      <Flex   alignItems="center" justifyContent="center" > 
        <StyledButton
          width="100px" height="25px"
           variant="secondary"
          to="/airdropsbabyvizsla" 
           as={NavLink}
        >
             {t('Open')} 
        </StyledButton>
        <Link external href="https://www.babyvizsla.com/">
        <Flex ml="4px"  mt="3px" >

         <img src="/images/pixel/365.png" alt="ifo bunny" width="20px" height="20px"/>
         </Flex>  
         
                     </Link> 
                     <Link external href="https://bscscan.com/token/0xA745b8072d4012A6a4380b8aA614FE96e15de2b7">
               <Flex ml="4px"  mt="3px" >
         <img src="/images/pixel/69.png" alt="ifo bunny" width="20px" height="20px"/>
         </Flex> 
         </Link> 
                     <Flex ml="4px"  mt="4px" onClick={onPresentBountyModal2}> 
         <img src="/images/pixel/366.png" alt="ifo bunny" width="20px" height="20px"  />
          </Flex> 

        </Flex>
        </Flex>
        </Flex>   
        </Flex>


    <Flex alignItems="center" justifyContent="center" mb="15px">
    {hasLowGod3Balance &&  hasLowGod2Balance && hasLowGod5Balance && 

 <Flex alignItems="center" justifyContent="center" ml="15px" mt="5px">
 <ImageWrapper>
 <img src="/images/pixel/008.png" alt="ifo bunny" width="60px" height="60px"/>
</ImageWrapper>
<Flex  flexDirection="column" mr={['8px', 0]}>
   <Text   bold fontSize="15px">
       {t('0.00001 BTC')} 
     </Text>
  
<Flex   alignItems="center" justifyContent="left" > 

<StyledButton
   width="100px" height="25px"
    variant="secondary"
   to="/airdropsbtc" 
    as={NavLink}
 >
      {t('Open')}
 </StyledButton>
 <Link external href="https://bitcoin.org">
   <Flex ml="4px"  mt="3px" >
  <img src="/images/pixel/365.png" alt="ifo bunny" width="20px" height="20px"/>
  </Flex> 
  </Link> 
  <Link external href="https://bscscan.com/address/0x36bE3b03f8FFC367871b08fb24769D7F78e622b6">
        <Flex ml="4px"  mt="3px" >
  <img src="/images/pixel/69.png" alt="ifo bunny" width="20px" height="20px"/>
  </Flex> 
  </Link> 
  <Flex ml="4px"  mt="4px" onClick={onPresentBountyModal4}> 
  <img src="/images/pixel/366.png" alt="ifo bunny" width="20px" height="20px"  />
  </Flex> 
 </Flex>
 </Flex>  
 </Flex>
       }   
 </Flex>

          </StyledModalContainer4>
          </StyledModalContainer5>
          </Flex>

    </StyledModalContainer>

  )
}

export default BountyCard
import React, { useState, useCallback } from 'react'
import { Box, Button, Flex, InjectedModalProps, LinkExternal, useTooltip, HelpIcon, Message, Text, Skeleton, AutoRenewIcon, Spinner } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core/core/core'
import useTokenBalance from 'hooks/useTokenBalance'
import useAuth from 'hooks/useAuth'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import BigNumber from 'bignumber.js/bignumber'
import useFarmsWithBalance from 'views/Home/hooks/useFarmsWithBalance'
import { usePriceCakeBusd} from 'state/farms/hooks'
import CopyAddress from './CopyAddress'


interface WalletInfoProps {
  hasLowBnbBalance: boolean
  onDismiss: InjectedModalProps['onDismiss']
}

const WalletInfo: React.FC<WalletInfoProps> = ({hasLowBnbBalance, onDismiss }) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { logout } = useAuth()
  const { farmsWithStakedBalance, earningsSum: farmEarningsSum } = useFarmsWithBalance()


  const cakePriceBusd = usePriceCakeBusd()
  const earningsBusd = new BigNumber(farmEarningsSum).multipliedBy(cakePriceBusd)
  const numFarmsToCollect = farmsWithStakedBalance.length


  const { targetRef, tooltip, tooltipVisible } = useTooltip(t('You can view just Active VizslaSwap Tokens in your wallet.'), {
    placement: 'bottom',
  })
 
  const earningsText = t('%earningsBusd% Collect from %count% %farms%', {
    earningsBusd: earningsBusd.toString(),
    count: numFarmsToCollect > 0 ? numFarmsToCollect : '',
    farms: numFarmsToCollect === 0 || numFarmsToCollect > 1 ? 'farms' : 'farm',
  })
  const [preText, toCollectText] = earningsText.split(earningsBusd.toString())
  const earningsBusd2 = earningsBusd.div(cakePriceBusd)
  


  const handleLogout = () => {
    onDismiss()
    logout()
  }


  return (
    <>
      <Text color="secondary" fontSize="12px" textTransform="uppercase" fontWeight="bold" mb="8px">
        {t('Your Address')}
      </Text>
      <CopyAddress account={account} mb="24px" />
      {hasLowBnbBalance && (
           <Message variant="danger" mb="24px">
           <Box>
             <Text fontWeight="bold">{t('BNB Balance Low')}</Text>
             <Text as="p">{t('You need BNB for transaction fees.')}</Text>
           </Box>
         </Message>
      )}
      <Button variant="secondary" width="100%" onClick={handleLogout}>
        {t('Disconnect Wallet')}
      </Button>
      {tooltipVisible && tooltip}
    </>
  )
}

export default WalletInfo

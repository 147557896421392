const MINUTE_IN_SECONDS = 60
const HOUR_IN_SECONDS = 3600
const DAY_IN_SECONDS = 86400

/**
 * Format number of seconds into year, month, day, hour, minute, seconds
 *
 * @param seconds
 */
const getTimePeriods2 = (seconds: number) => {
  let delta = Math.abs(seconds)
  const timeLeft = {
    years: 0,
    months2: 0,
    days2: 0,
    hours2: 0,
    minutes2: 0,
    seconds2: 0,
  }


  if (delta >= DAY_IN_SECONDS) {
    timeLeft.days2 = Math.floor(delta / DAY_IN_SECONDS)
    delta -= timeLeft.days2 * DAY_IN_SECONDS
  }

  if (delta >= HOUR_IN_SECONDS) {
    timeLeft.hours2 = Math.floor(delta / HOUR_IN_SECONDS)
    delta -= timeLeft.hours2 * HOUR_IN_SECONDS
  }

  if (delta >= MINUTE_IN_SECONDS) {
    timeLeft.minutes2 = Math.floor(delta / MINUTE_IN_SECONDS)
    delta -= timeLeft.minutes2 * MINUTE_IN_SECONDS
  }

  timeLeft.seconds2 = delta

  return timeLeft
}

export default getTimePeriods2

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { createClient } from '@supabase/supabase-js';
import { Button, Input } from '@pancakeswap/uikit';
import { ethers } from 'ethers';
import contractABI from './contractABI.json';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

const contractAddress = '0x674e3eDc898Aa4b486Bd2E5aa2D1C8653677F471';

// Container with quiz-like dark theme background and subtle shadow
const ToplistContainer = styled.div`
  padding: 30px;
  max-width: 600px;
  margin: 0 auto;
  background-color: #1e1e2e;
  border-radius: 15px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5);
  text-align: center;
  color: #eaeaea;
`;

// Title with light text-shadow for a subtle glowing effect
const TopListTitle = styled.h2`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 30px;
  color: #fff;
  text-shadow: 0 0 2.5px gold, 0 0 5px gold, 0 0 7.5px gold, 0 0 10px gold;
`;

// Countdown container with a similar dark background
const CountdownContainer = styled.div`
  font-size: 22px;
  color: gold;
  margin: 20px 0;
  padding: 15px;
  background-color: #1e1e2e;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
`;

// Rewards container with consistent styling
const RewardContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

const RewardItem = styled.div`
  font-size: 18px;
  margin: 0 15px;
  padding: 10px;
  border-radius: 8px;
  color: #fff;
  background-color: #16161f;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
`;

// Styled Input with dark background and light placeholder text
const StyledInput = styled(Input)`
  margin-bottom: 20px;
  background-color: #1f2833;
  color: #fff;
  border: 1px solid gold;

  &::placeholder {
    color: gold;
  }
`;

// User item with consistent dark background, border radius, and text-shadow
const UserItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 10px;
  background-color: #16161f;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
`;

// User name with rank color for top positions
const UserName = styled.span.withConfig({
  shouldForwardProp: (prop) => prop !== 'rank',
})`
  font-size: 18px;
  font-weight: bold;
  color: ${({ rank }) =>
    rank === 1 ? 'gold' :
    rank === 2 ? 'silver' :
    rank === 3 ? '#cd7f32' : '#eaeaea'};
`;

// User score with default light color for consistency
const UserScore = styled.span`
  font-size: 18px;
  color: #eaeaea;
`;

const BackButton = styled(Button)`
  margin-bottom: 20px;
  background-color: #e6ac00;
  color: #fff;
  &:hover {
    background-color: #d4a017;
  }
`;

// Contract function to get user name from contract
const getContract = () => {
  const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org');
  return new ethers.Contract(contractAddress, contractABI, provider);
};

const fetchUserNameFromContract = async (account) => {
  try {
    const contract = getContract();
    const name = await contract.getName(account);
    return name || null;
  } catch (error) {
    console.error('Error fetching name:', error);
    return null;
  }
};

const Toplist = () => {
  const [topList, setTopList] = useState([]);
  const [names, setNames] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    const fetchTopList = async () => {
      const { data, error } = await supabase
        .from('quiz_scores')
        .select('account, best_score')
        .order('best_score', { ascending: false });

      if (!error) {
        setTopList(data);
      }
    };

    fetchTopList();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      const newNames = {};

      const validUsers = await Promise.all(
        topList.map(async (user) => {
          const userAccount = user.account;
          const userNameFromContract = await fetchUserNameFromContract(userAccount);

          if (userNameFromContract) {
            newNames[userAccount] = userNameFromContract;
            return user;
          }
          return null;
        })
      );

      setTopList(validUsers.filter(Boolean));
      setNames(newNames);
    };

    if (topList.length > 0) {
      fetchUserData();
    }
  }, [topList]);

  useEffect(() => {
    const targetDate = new Date('2024-11-19T00:00:00');
    const countdown = setInterval(() => {
      const now = new Date();
      const diff = targetDate - now;

      if (diff <= 0) {
        clearInterval(countdown);
        setTimeLeft('The competition has ended.');
      } else {
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / 1000 / 60) % 60);
        const seconds = Math.floor((diff / 1000) % 60);

        setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  const filteredTopList = topList.filter((user) => {
    const userName = names[user.account]?.toLowerCase() || '';
    return userName.includes(searchQuery.toLowerCase()) || user.account.toLowerCase().includes(searchQuery.toLowerCase());
  });

  return (
    <ToplistContainer>
      <Link to="/quiz">
        <BackButton>&lt; Back to Quiz</BackButton>
      </Link>

      <CountdownContainer>
        <div>Time left until the competition ends:</div>
        <div>{timeLeft}</div>
      </CountdownContainer>

      <RewardContainer>
        <RewardItem>
          <strong style={{ color: 'gold' }}>🏆 1st Place:</strong> 3333 VIZSLASWAP tokens
        </RewardItem>
        <RewardItem>
          <strong style={{ color: 'silver' }}>🥈 2nd Place:</strong> 2222 VIZSLASWAP tokens
        </RewardItem>
        <RewardItem>
          <strong style={{ color: '#cd7f32' }}>🥉 3rd Place:</strong> 1111 VIZSLASWAP tokens
        </RewardItem>
      </RewardContainer>

      <TopListTitle>Top List</TopListTitle>
      <StyledInput
        placeholder="Search by name or address"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      <ul>
        {filteredTopList.map((user, index) => (
          <UserItem key={user.account}>
            <UserName rank={index + 1}>
              {names[user.account] || `${user.account.substring(0, 5)}...${user.account.substring(user.account.length - 5)}`}
            </UserName>
            <UserScore>{user.best_score} points</UserScore>
          </UserItem>
        ))}
      </ul>
    </ToplistContainer>
  );
};

export default Toplist;

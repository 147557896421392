import React from 'react'
import { Button, useWalletModal, WalletFilledIcon, WalletIcon } from '@pancakeswap/uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'
import styled, { keyframes } from 'styled-components'

const hoverEffect = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.06);
  }
`

const activeEffect = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const StyledButton = styled(Button)`
  background-color: #53b28c;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #5a9f98;
    animation: ${hoverEffect} 0.3s forwards;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }

  &:active {
    animation: none;

    svg {
      animation: ${activeEffect} 0.6s;
    }
  }

  svg {
    transition: transform 0.6s;
  }
`

const ConnectWalletButton = (props) => {
  const { t } = useTranslation() 
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout)
  
  return (
    <StyledButton onClick={onPresentConnectModal} {...props}>
      <WalletFilledIcon width="24px" />
      {t('Connect')}
    </StyledButton>
  )
}

export default ConnectWalletButton

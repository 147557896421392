import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import './Ajj.css';

const hoverEffect = keyframes`
  0% {
    transform: translateX(0);
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
  }
  100% {
    transform: translateX(0);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.7);
  }
`;

const activeEffect = keyframes`
  0% {
    transform: translateX(0);
    box-shadow: 0 0 5px rgba(83, 178, 140, 0.3);
  }
  100% {
    transform: translateX(0);
    box-shadow: 0 0 15px rgba(83, 178, 140, 0.7);
  }
`;

const StyledLink = styled(Link)`
  display: block;
  margin: 5px 0;
  text-decoration: none;
  color: inherit;

  &:hover {
    animation: ${hoverEffect} 0.5s forwards;
  }

  &:active {
    animation: ${activeEffect} 0.5s forwards;
  }
`;

function App() {
  const [data, setData] = useState([
    "VIZSLASWAP-0xadaae082237cb1772c9e079db95c117e6dd0c5af",
    "LAMEA-0xaa44051bdd76E251aab66dbbE82A97343B4D7DA3",
    "LGC-0x3496212eC43Cc49F5151ec4405efD4975e036f89",
    "BVC-0x2dEA12c4f08074032a9997F0a199b04aF0fC0392",
    "HVI-0xde619a9e0eeeaa9f8cd39522ed788234837f3b26",
    "BNB-0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    "BTCB-0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    "ETH-0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    "LTC-0x4338665CBB7B2485A8855A139b75D5e34AB0DB94",
    "SHIB-0x2859e4544C4bB03966803b044A93563Bd2D0DD4D",
    "BABYVIZSLA-0xfB19F247F1f22Cef1F0384535C779c664632cB6F",
    "EXZO-0xF8fC63200e181439823251020d691312FDcF5090",
    "BUSD-0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    "ACE-0xFAaA87943bFca6D97434bE3d26C589647FEA4375",
    "FPS-0x213503534d927424A5cdf6d580E9Fd408be9337a",
    "ATALIRA-0x8604d31F3683A22b328CF6F974A34233c3494ccf",
    "BTNTY-0xF15c8d610E02602caAA9959BF5ecfEb8B3D229bE",
    "BUILD-0x83b27De2Fca046FA63a11C7Ce7743DE33Ec58822",
    "CUBY-0x488907d99cE2ac919A47AcFac896e825Fd23598f",
    "ETC-0x3d6545b08693daE087E957cb1180ee38B9e3c25E",
    "FartCoin-0x88B985007d714d1578BCcdEc2303212c14946cdC",
    "LIRA-0xA80a006A48dc7203EB3AA7E0b3816918d242CFC4",
    "SKELETON-0xb0688E82D162Df5288A0D986DfFd4CF80AFb7897",
    "TRIP-0x15a0Ff684918B20487f2a68d6816e694BB05E471",
    "USDT-0x55d398326f99059fF775485246999027B3197955",
    "BABY-0xA4E26Bd6DCBa9021DCd3A1159BA52e97CD770b8a"
  ]);
  const [input, setInput] = useState('');

  const handleChange = (event) => {
    setInput(event.target.value);
  };

  const result = data.filter(item => item.toLowerCase().includes(input.toLowerCase()));

  return (
    <div className="App">
      <input
        type='text'
        value={input}
        placeholder='Enter Address/Symbol'
        className='searchinput'
        onChange={handleChange}
      />
      <div>
        {input ? (
          <div>
            {result.map(e => (
              <StyledLink to={e} key={e}>
                <h5 className='result-item'>{e}</h5>
              </StyledLink>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default App;

import React from 'react'
import { Redirect, useParams } from 'react-router-dom'
import AddLiquidity2 from './index'

export function RedirectToAddLiquidity2() {
  return <Redirect to="/addapeliquidity/" />
}

const OLD_PATH_STRUCTURE = /^(0x[a-fA-F0-9]{40}|BNB)-(0x[a-fA-F0-9]{40}|BNB)$/
export function RedirectOldAddLiquidityPathStructure2() {
  const { currencyIdA } = useParams<{ currencyIdA: string }>()
  const match = currencyIdA.match(OLD_PATH_STRUCTURE)
  if (match?.length) {
    return <Redirect to={`/addapeliquidity/${match[1]}/${match[2]}`} />
  }

  return <AddLiquidity2 />
}

export function RedirectDuplicateTokenIds2() {
  const { currencyIdA, currencyIdB } = useParams<{ currencyIdA: string; currencyIdB: string }>()
  if (currencyIdA.toLowerCase() === currencyIdB.toLowerCase()) {
    return <Redirect to={`/addapeliquidity/${currencyIdA}`} />
  }
  return <AddLiquidity2 />
}

export default {
  masterChef: {
    97: '',
    56: '0x3FF00B58e432B0895e3fd69634Ec50e3931110c1',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  lotteryV2: {
    97: '0x5790c3534F30437641541a0FA04C992799602998',
    56: '0x6F4b625814fAD4e0cedC6044ce042A2E4D2046e6',
  },
  multiCall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  },
  pancakeProfile: {
    56: '0xd88fdB83a1CFbc34a9eEa0106D1834A25b2BCE4B',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    56: '0x74C1D85d2f45eDf73D43c8eb70DaaFd7F6207A80',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0xf413bd4C866A336E26f56516774Ed56a0546b91A',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    56: '0x7dD1A967F2FB6C439BAecA357fcD8A9CCd3CB6D3',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetition: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  easterNft: {
    56: '0x70f317168B0B6638528b6FDFdBcCC8E7BFa3735c',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  cakeVault11: {
    56: '0x8FdfDd2871944f5155c1801B4B31D1698cCdBd32',
    97: '',
  },
  cakeVault12: {
    56: '0xA745b8072d4012A6a4380b8aA614FE96e15de2b7',
    97: '',
  },
  cakeVault13: {
    56: '0xE06c18446Ee2A08bCd47d84Ec034636cAc5B97C0',
    97: '',
  },
  cakeVault14: {
    56: '0xA745b8072d4012A6a4380b8aA614FE96e15de2b7',
    97: '',
  },
  cakeVault15: {
    56: '0x36bE3b03f8FFC367871b08fb24769D7F78e622b6',
    97: '',
  },
  cakeVaultAirSST: {
    56: '0x8C01B0f92c47AC610BCeeFccf0ABB877aB7991e0',
    97: '',
  },
  cakeVault16: {
    56: '0x5F2Cf96d11ed4D8381f409FAf73F51C1b4f516Fc',
    97: '', 
  }, 
  cakeVault17: {
    56: '0x035534A8d418eeDefFd0a981D2cF5155afDAc82c',
    97: '',
  },
  cakeVault18: {
    56: '0x215BeF6E6dDF19ACE123610EC8b4B49E9d8A68BA',
    97: '',
  },
  locker: {
    56: '0xD60279CaCE5f6c0668A43465F87C10617A73a555',
    97: '',
  },
  cakeVault: {
    56: '0x1Cb956fb2A963084BBa550a59De6B4a897973534',
    97: '',
  },
  cakeVault2: {
    56: '0x7F4ddf4F41bf7306D76bC2DDf46f286DBfBC5c4C',
    97: '',
  },
  predictions: {
    56: '0x516ffd7D1e0Ca40b1879935B2De87cb20Fc1124b',
    97: '0x4f3140C74789F1D809420343ea83BcE52B7bbAA5',
  },
  chainlinkOracle: {
    56: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    97: '',
  },
  bunnySpecialCakeVault: {
    56: '0x38426AaCeE71Be2c135c6D69dC8c318b51c54CAB',
    97: '',
  },
  bunnySpecialPrediction: {
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '',
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
}

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core/core/core';
import styled from 'styled-components';
import NumberPurchaseABI from './NumberPurchaseABI.json';
import TokenABI from './TokenABI.json';
import {
  Container,
  HeaderContainer,
  Title,
  ArrowIcon,
  Content,
  ImageGrid,
  ImageItem,
  StyledButton,
  InfoText
} from './StyledComponents';
import './Kep.css';

const contractAddress = "0xBE8C7E554C89e064ED7229952036c3678cd10180"; 
const tokenAddress = "0xadaae082237cb1772c9e079db95c117e6dd0c5af"; 

const PriceInfoText = styled.p`
  text-align: center;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
`;

const MissingImageText = styled.p`
  text-align: center;
  color: #518f85;
  margin-bottom: 20px;
  font-size: 13px;
  font-weight: bold;
`;


const Kep = () => {
  const [selectedNumber, setSelectedNumber] = useState<number | null>(null);
  const [isApproved, setIsApproved] = useState(false);
  const [numberStatistics, setNumberStatistics] = useState<number[]>([]);
  const [purchaseHistory, setPurchaseHistory] = useState<any[]>([]);
  const [isBoxOpen, setIsBoxOpen] = useState(false);
  const [userNumber, setUserNumber] = useState<number | null>(null);
   
  const { account } = useWeb3React();
  const [provider, setProvider] = useState<ethers.providers.Web3Provider | null>(null);
  const [contract, setContract] = useState<ethers.Contract | null>(null);

 

  useEffect(() => {
    const initializeProviderAndContract = async () => {
      if (account) {
        try {
          const providerInstance = new ethers.providers.Web3Provider(window.ethereum);
          setProvider(providerInstance);
          const contractInstance = new ethers.Contract(contractAddress, NumberPurchaseABI, providerInstance);
          setContract(contractInstance);
        } catch (error) {
          console.error('Failed to initialize provider or contract:', error);
        }
      }
    };

    initializeProviderAndContract();
  }, [account]);

  const checkApprovalStatus = useCallback(async () => {
    if (!account || !provider || !contract) return;

    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(tokenAddress, TokenABI, signer);

    const allowance = await tokenContract.allowance(account, contractAddress);
    const amountToApprove = ethers.utils.parseUnits("2000", 18);

    if (allowance.gte(amountToApprove)) {
      setIsApproved(true);
    } else {
      setIsApproved(false);
    }

    try {
      const userPurchase = await contract.userPurchases(account);
      if (userPurchase.number && userPurchase.number.toNumber() !== 0) {
        setUserNumber(userPurchase.number.toNumber());
      } else {
        setUserNumber(null);
      }
    } catch (error) {
      console.error('Failed to fetch user purchase:', error);
    }
  }, [account, provider, contract]);

  const loadStatistics = useCallback(async () => {
    if (!contract) return;

    const promises = []; 
    for (let i = 1; i <= 17; i++) {
      promises.push(contract.getNumberStatistics(i));
    }
    
    const stats = await Promise.all(promises);
    setNumberStatistics(stats.map(stat => stat.toNumber()));
  }, [contract]);

  const loadPurchaseHistory = useCallback(async () => {
    if (!contract) return;
  
    try {
      const [users, numbers, timestamps] = await contract.getPurchaseHistory();
      
      // A vásárlási adatok rendezése időrendben (legújabb elől)
      const purchaseData = users.map((user, index) => ({
        user,
        number: numbers[index].toNumber(),
        timestamp: new Date(timestamps[index].toNumber() * 1000).toLocaleString(),
      })).sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
      
      setPurchaseHistory(purchaseData);
    } catch (error) {
      console.error('Failed to fetch purchase history:', error);
    }
  }, [contract]);
  

  useEffect(() => {
    if (account && contract) {
      checkApprovalStatus();
      loadStatistics();
      loadPurchaseHistory();
    }
  }, [account, contract, checkApprovalStatus, loadStatistics, loadPurchaseHistory]);

  const approveTokens = async () => {
    if (!account || !provider) return;

    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(tokenAddress, TokenABI, signer);
    
    const amountToApprove = ethers.utils.parseUnits("2000", 18);
  
    try {
      const tx = await tokenContract.approve(contractAddress, amountToApprove);
      await tx.wait();
      setIsApproved(true);
      checkApprovalStatus();
    } catch (error) {
      console.error("Approve failed", error);
    }
  };

  const buyNumber = async (number: number) => {
    if (!isApproved || !account || !contract || !provider) return;
  
    const signer = provider.getSigner();
    const contractWithSigner = contract.connect(signer);
  
    try {
      const tx = await contractWithSigner.buyNumber(number, {
        gasLimit: ethers.utils.hexlify(400000), 
      });
  
      await tx.wait();
  
      loadStatistics();
      loadPurchaseHistory();
      checkApprovalStatus();
    } catch (error) {
      console.error("Transaction failed:", error);
    }
  };
  
  const changeNumber = async (newNumber: number) => {
    if (!userNumber || !account || !contract || !provider) return;

    const signer = provider.getSigner();
    const contractWithSigner = contract.connect(signer);

    try {
      const tx = await contractWithSigner.changeNumber(newNumber);
      await tx.wait();

      loadStatistics();
      loadPurchaseHistory();
      checkApprovalStatus();
    } catch (error) {
      console.error("Change number failed:", error);
    }
  };

  const toggleBox = () => {
    setIsBoxOpen(!isBoxOpen);
  };


  return (
    <Container>
      <HeaderContainer onClick={toggleBox}>
        <Title>Get Profile Image</Title>
        <ArrowIcon>{isBoxOpen ? '▲' : '▼'}</ArrowIcon>
      </HeaderContainer>
      {isBoxOpen && (
        <Content>
          {!account && <InfoText>Please connect your wallet to proceed.</InfoText>}
          <PriceInfoText>
            Each image costs 2000 VIZSLASWAP, and profile picture changes are free.
          </PriceInfoText>
          <MissingImageText>
            Missing your dog from the selection? Contact the Admin in the private chat.
          </MissingImageText>
          <ImageGrid>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17].map((number) => (
              <ImageItem key={`number-${number}`}>
                <img src={`images/pixel/0${number}.jpg`} alt={`Number ${number}`} />
                {!isApproved ? (
                  <StyledButton onClick={approveTokens}>Approve</StyledButton>
                ) : userNumber === null ? (
                  <StyledButton onClick={() => buyNumber(number)}>BUY</StyledButton>
                ) : (
                  <StyledButton onClick={() => changeNumber(number)}>CHANGE</StyledButton>
                )}
                <p>{numberStatistics[number - 1]} users have bought this dog</p>
              </ImageItem>
            ))}
          </ImageGrid>
         
         
        </Content>
      )}
    </Container>
  );
};

export default Kep;
import React, { useState, useEffect, useCallback } from 'react';
import { ethers } from 'ethers';
import { Link } from 'react-router-dom'; // Import Link for navigation
import styled, { keyframes } from 'styled-components';
import { useWeb3React } from '@web3-react/core/core/core';
import { Input, Button, Box, Heading, Text,ButtonMenu, ButtonMenuItem,Flex  } from '@pancakeswap/uikit';
import contractAbi from './Privategift.json';
import TokenABI from './TokenABI.json';


const contractAddress = "0x2E9Adf161a15D84363d322BF7C5eAE9F789a9E80";
const tokenAddressHVI = "0xDE619A9E0eEeAA9F8CD39522Ed788234837F3B26";
const tokenAddressVIZSLASWAP = "0xaDAaE082237cB1772c9e079dB95c117E6Dd0C5aF";

const ProfilePictureNote = styled(Text)`
  color: gold; // Red color for emphasis
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
`;
const hoverEffect = keyframes`
  0% {
    transform: translateX(0);
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
  }
  100% {
    transform: translateX(0);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.7);
  }
`;

const activeEffect = keyframes`
  0% {
    transform: translateX(0);
    box-shadow: 0 0 5px rgba(83, 178, 140, 0.3);
  }
  100% {
    transform: translateX(0);
    box-shadow: 0 0 15px rgba(83, 178, 140, 0.7);
  }
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 12px;
  min-height: 40px;
  margin-bottom: 16px;
  background-color: #000; /* Black background */
  color: #fff; /* White text */
  border: 1px solid #1fc7d4; /* Light border */
  border-radius: 8px;
  outline: none;
  
  &:hover {
    animation: ${hoverEffect} 0.5s forwards;
  }

  &:focus {
    animation: ${activeEffect} 0.5s forwards;
  }

  option {
    background-color: #000;
    color: #fff;
  }
`;

const PageContainer = styled(Box)`
  max-width: 800px;
  margin: 30px auto; /* Centers the container horizontally */
  padding: 24px;
  background-color: #000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center the content vertically */
  align-items: center;
`;


const FormContainer = styled(Box)`
  margin-bottom: 24px;
`;

const StyledInput = styled(Input)`
  width: 100%;
  margin-bottom: 12px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 12px;
  background-color: #53b28c;
  &:hover {
    background-color: #5a9f98;
  }
`;
export const BackButton = styled.button`
  background-color: #ffcc00;
  color: black;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #ff9900;
  }
`;

const GiftBox = styled(Box)`
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
`;

const SwapPage: React.FC = () => {
  const { account } = useWeb3React();
  const [provider, setProvider] = useState<ethers.providers.Web3Provider | null>(null);
  const [contract, setContract] = useState<ethers.Contract | null>(null);
  const [tokenType, setTokenType] = useState<'HVI' | 'VIZSLASWAP'>('HVI');
  const [amount, setAmount] = useState<string>('');
  const [receiver, setReceiver] = useState<string>('');
  const [isApproved, setIsApproved] = useState(false);
  const [gifts, setGifts] = useState<any[]>([]);
  const [activeSection, setActiveSection] = useState<'send' | 'collect'>('send'); // New state to toggle sections

  useEffect(() => {
    const initializeProviderAndContract = async () => {
      if (account) {
        const providerInstance = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(providerInstance);
        const contractInstance = new ethers.Contract(contractAddress, contractAbi, providerInstance.getSigner());
        setContract(contractInstance);
      }
    };
    initializeProviderAndContract();
  }, [account]);

  const checkApprovalStatus = useCallback(async () => {
    if (!account || !provider || !contract) return;

    const signer = provider.getSigner();
    const tokenAddress = tokenType === 'HVI' ? tokenAddressHVI : tokenAddressVIZSLASWAP;
    const tokenContract = new ethers.Contract(tokenAddress, TokenABI, signer);

    const allowance = await tokenContract.allowance(account, contractAddress);
    const amountToApprove = ethers.utils.parseUnits("2000", 18); 

    if (allowance.gte(amountToApprove)) {
      setIsApproved(true);
    } else {
      setIsApproved(false);
    }
  }, [account, provider, contract, tokenType]);

  const approveTokens = async () => {
    if (!account || !provider) return;
  
    const signer = provider.getSigner();
    const tokenAddress = tokenType === 'HVI' ? tokenAddressHVI : tokenAddressVIZSLASWAP;
    const tokenContract = new ethers.Contract(tokenAddress, TokenABI, signer);
  
    const amountToApprove = ethers.utils.parseUnits("2000", 18); 
  
    try {
      const tx = await tokenContract.approve(contractAddress, amountToApprove);
      await tx.wait();
      setIsApproved(true);
      console.log("Tokent engedélyezve");
    } catch (error) {
      console.error("Approve failed", error);
    }
  };
  
  const sendGift = async (receiver: string, amount: string, tokenType: 'HVI' | 'VIZSLASWAP') => {
    if (!account) return;
  
    const signer = provider.getSigner();
    const contractWithSigner = contract.connect(signer);
    const amountInWei = ethers.utils.parseUnits(amount, 0); 
  
    console.log("Gift küldése a következő paraméterekkel:", {
      receiver,
      amountInWei: amountInWei.toString(),
      tokenType,
    });
  
    try {
      const tx = await contractWithSigner.sendGift(receiver, amountInWei, tokenType, {
        gasLimit: 500000,
      });
  
      await tx.wait();
      console.log("Gift sikeresen elküldve");
      loadGifts();
    } catch (error) {
      console.error("Send gift transaction failed:", error);
    }
  };

  const collectAllGiftsConcurrently = async () => {
    if (!account || !contract) return; // Ellenőrizzük, hogy van-e account és contract.
  
    const signer = provider.getSigner();
    const contractWithSigner = contract.connect(signer);
  
    try {
      // Az utolsó 10 ajándék lekérése, hogy megtudjuk, milyen tokeneket kaptál
      const lastGifts = await contract.getLastTenGifts(account);
  
      // Ellenőrizzük, van-e HVI token az ajándékok között
      const hasHVI = lastGifts.some(gift => gift.tokenType === 'HVI');
      // Ellenőrizzük, van-e VIZSLASWAP token az ajándékok között
      const hasVIZSLASWAP = lastGifts.some(gift => gift.tokenType === 'VIZSLASWAP');
  
      // Ha kaptál HVI tokent, csak akkor gyűjtsük be azt
      if (hasHVI) {
        try {
          const tx = await contractWithSigner.collectGift('HVI', { gasLimit: 500000 });
          await tx.wait(); // Megvárjuk, míg a tranzakció lefut
          console.log("Collected HVI tokens");
        } catch (error) {
          console.error("Failed to collect HVI tokens:", error);
        }
      }
  
      // Ha kaptál VIZSLASWAP tokent, csak akkor gyűjtsük be azt
      if (hasVIZSLASWAP) {
        try {
          const tx = await contractWithSigner.collectGift('VIZSLASWAP', { gasLimit: 500000 });
          await tx.wait(); // Megvárjuk, míg a tranzakció lefut
          console.log("Collected VIZSLASWAP tokens");
        } catch (error) {
          console.error("Failed to collect VIZSLASWAP tokens:", error);
        }
      }
  
      // Végül újra lekérdezzük az ajándékokat a begyűjtés után, hogy frissüljön az állapot
      loadGifts();
    } catch (error) {
      console.error("Failed to collect gifts:", error);
    }
  };
  

  const loadGifts = useCallback(async () => {
    if (!contract || !account) return;

    try {
      const lastGifts = await contract.getLastTenGifts(account);
      setGifts(lastGifts);
    } catch (error) {
      console.error("Failed to fetch last 10 gifts:", error);
    }
  }, [contract, account]);

  useEffect(() => {
    if (account && contract) {
      checkApprovalStatus();
      loadGifts();
    }
  }, [account, contract, checkApprovalStatus, loadGifts]);

  return (
    <PageContainer>
          <Link to="/privatechat">
        <BackButton onClick={() => {/* handle navigation logic here */}}>
          &lt; Back to PrivateChat
        </BackButton>
        </Link>
      <Flex display="flex" justifyContent="center" mb="24px">
  <ButtonMenu activeIndex={activeSection === 'send' ? 0 : 1} onItemClick={(index) => setActiveSection(index === 0 ? 'send' : 'collect')}>
    <ButtonMenuItem>Send Gift</ButtonMenuItem>
    <ButtonMenuItem>Collect Gift</ButtonMenuItem>
  </ButtonMenu>
</Flex>

      {activeSection === 'send' && (
        <>
          <Text as="p" size="sm" textAlign="center" color="text" mb="24px">
            Send a gift to anyone without fees 🎁
          </Text>
          <FormContainer>
            <StyledInput
              value={receiver}
              onChange={(e) => setReceiver(e.target.value)}
              placeholder="Enter recipient address"
            />
            <StyledInput
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter amount"
            />
            <StyledSelect onChange={(e) => setTokenType(e.target.value as 'HVI' | 'VIZSLASWAP')}>
              <option value="HVI">HVI</option>
              <option value="VIZSLASWAP">VIZSLASWAP</option>
            </StyledSelect>

            <StyledButton onClick={approveTokens} disabled={isApproved}>
              {isApproved ? 'Approved' : 'Approve Tokens'}
            </StyledButton>
            <StyledButton onClick={() => sendGift(receiver, amount, tokenType)}>
              Send Gift
            </StyledButton>
            <ProfilePictureNote>
              Only for users with a profile picture
            </ProfilePictureNote>
          </FormContainer>
        </>
      )}

      {activeSection === 'collect' && (
        <>
          <Heading as="h3" size="lg" mt="32px" mb="16px">Collect All Gifts</Heading>
          <StyledButton onClick={collectAllGiftsConcurrently}>Collect All Gifts</StyledButton>

          <Heading as="h3" size="lg" mt="32px" mb="16px">Recent Claimable Gifts</Heading>
          {gifts.length > 0 ? gifts.map((gift) => (
            <GiftBox key={`${gift.sender}-${gift.timestamp}`}>
              <Text>From: {gift.sender}</Text>
              <Text>Amount: {ethers.utils.formatUnits(gift.amount, gift.tokenType === 'HVI' ? 9 : 18)}</Text>
              <Text>Token: {gift.tokenType}</Text>
            </GiftBox>
          )) : <Text>No gifts found</Text>}
        </>
      )}
    </PageContainer>
  );
};

export default SwapPage;

import React from 'react'
import { CardHeader, Heading, Text, Flex, Image } from '@pancakeswap/uikit'
import styled, { keyframes } from 'styled-components'
import { useTranslation } from 'contexts/Localization'


const Wrapper = styled(CardHeader)<{ isFinished?: boolean; background?: string }>`
  background: ${({ isFinished, background, theme }) =>
    isFinished ? theme.colors.backgroundDisabled : theme.colors.gradients[background]};
  border-radius: ${({ theme }) => `${theme.radii.card} ${theme.radii.card} 0 0`};
    background: #ffffff00;
  `
  const runningLightAnimation = keyframes`
  0% {
    text-shadow: 0 0 1px #62d5a3, 0 0 2px #62d5a3, 0 0 3px #62d5a3, 0 0 4px #62d5a3;
  }
  50% {
    text-shadow: 0 0 1px #62d5a3, 0 0 2px #62d5a3, 0 0 3px #62d5a3, 0 0 4px #62d5a3;
  }
  100% {
    text-shadow: 0 0 1px #62d5a3, 0 0 2px #62d5a3, 0 0 3px #62d5a3, 0 0 4px #62d5a3;
  } 
`;

const Title = styled(Heading)`
    animation: ${runningLightAnimation} 2s infinite; /* Alkalmazzuk az animációt */
    color: #fff;
`;
const Title2 = styled(Text)`
    animation: ${runningLightAnimation} 2s infinite; /* Alkalmazzuk az animációt */
    color: #fff;
`;
const StyledCardHeader: React.FC<{
  earningTokenSymbol: string
  stakingTokenSymbol: string
  isAutoVault?: boolean
  isFinished?: boolean
  isStaking?: boolean
  number: string
}> = ({ earningTokenSymbol, stakingTokenSymbol, number, isFinished = false, isAutoVault = false, isStaking = false }) => {
  const { t } = useTranslation()
  const poolImageSrc = isAutoVault
    ? `00.png`
    : `${number}.png`.toLocaleLowerCase()
  const isCakePool = earningTokenSymbol === 'CAKE' && stakingTokenSymbol === 'CAKE'
  const background = isStaking ? 'bubblegum' : 'cardHeader'

  const getHeadingPrefix = () => {
    if (isAutoVault) {
      // vault
      return t('Auto')
    }
    if (isCakePool) {
      // manual cake
      return t('Manual')
    }
    // all other pools
    return t('Earn')
  }

  const getSubHeading = () => {
    if (isAutoVault) {
      return t('Automatic restaking')
    }
    if (isCakePool) {
      return t('Earn CAKE, stake CAKE')
    }
    return t('Stake %symbol%', { symbol: stakingTokenSymbol })
  }

  return (
    <Wrapper isFinished={isFinished} background={background}>
    <Flex alignItems="center" justifyContent="space-between">
      <Flex flexDirection="column">
        <Title color={isFinished ? 'textDisabled' : 'body'} scale="lg">
          {`${getHeadingPrefix()} ${earningTokenSymbol}`}
        </Title>
        <Title2 color={isFinished ? 'textDisabled' : 'textSubtle'}>{getSubHeading()}</Title2>
      </Flex>
      <Image src={`/images/pools/${poolImageSrc}`} alt={number} width={85} height={85} />
    </Flex>
  </Wrapper>
  )
}

export default StyledCardHeader

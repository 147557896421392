import BigNumber from 'bignumber.js/bignumber'
import React from 'react'
import { CardBody, Flex, Text, CardRibbon, useTooltip, HelpIcon , Input} from '@pancakeswap/uikit'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useTranslation } from 'contexts/Localization'
import { BIG_ZERO } from 'utils/bigNumber'
import { Pool } from 'state/types'
import { useLocked, useLocked3, usePool4 } from 'hooks/useTokenBalance'
import { getCakeAddress, getHVIAddress } from 'utils/addressHelpers'
import { getBalanceNumber } from 'utils/formatBalance'
import { usePriceCakeBusd, usePriceHVIBusd } from 'state/farms/hooks'
import styled from 'styled-components'
import { Auction, AuctionStatus } from 'config/constants/types'
import useWithdrawalFeeTimer from 'views/Pools/hooks/useWithdrawalFeeTimer'
import { useCakeVault } from 'state/poolsTimerV2Locked/hooks'
import { useBlock } from 'state/block/hooks'
import getTimePeriods from 'utils/getTimePeriods'
import getTimePeriods2 from 'utils/getTimePeriods2'
import getTimePeriods3 from 'utils/getTimePeriods3' 
import { StyledCard } from './StyledCard'
import CardFooter from './CardFooter'
import StyledCardHeader from './StyledCardHeader'
import CardActions from './CardActions'

interface ScheduleProps {
  auction: Auction
  showForClosedAuction?: boolean
}

const ImageWrapper = styled.div`
flex: none;

${({ theme }) => theme.mediaQueries.md} {
  flex: none;

}
`
const HelpIconWrapper = styled.div`
  align-self: center;
`
const PoolCard: React.FC<{ pool: Pool; account: string }> = ({ pool, account }) => {
  const { number, sousId, stakingToken, earningToken, isFinished, userData,withdrawtime, withdrawtime2, withdrawtime3, withdrawtime4} = pool
  const { t } = useTranslation()
  const stakedBalance = userData?.stakedBalance ? new BigNumber(userData.stakedBalance) : BIG_ZERO
  const accountHasStakedBalance = stakedBalance.gt(0)  
  const {
    userData: { lastDepositedTime, userShares, lastUserActionTime },
    fees: { withdrawalFee, withdrawalFeePeriod },
  } = useCakeVault()

  const isStaked = stakedBalance.gt(0)
  const { secondsRemaining, hasUnstakingFee } = useWithdrawalFeeTimer(
    parseInt(lastDepositedTime, 10),
    userShares,
    withdrawalFeePeriod,
  )
  const manualTooltipText = t('You must harvest and compound your earnings from this pool manually.')

  const {
    targetRef: tagTargetRef,
    tooltip: tagTooltip,
    tooltipVisible: tagTooltipVisible,
  } = useTooltip( manualTooltipText, {
    placement: 'bottom-start',
  })


  const currentTimestamp = () => new Date().getTime()
  const withdrawtime0 = parseInt(withdrawtime)
  const shouldShowTimer = account && lastUserActionTime
  const { currentBlock } = useBlock()
  const anyad  = currentTimestamp()
  const goo = shouldShowTimer 
  const goo0 = parseInt(goo)
  const anyad2 = anyad / 1000
  const goo2 = goo0 - anyad2
  const { months,days, hours, minutes } = getTimePeriods(goo2)

const hvitime  = currentTimestamp()
const hvitime2 = hvitime / 1000
const goohvi = 1730415600 - hvitime2
const { months2,days2, hours2, minutes2 } = getTimePeriods2(goohvi)

const hvitimetwo = currentTimestamp()
const hvitimetwo2 = hvitime / 1000
const goohvi2 = 1701471600 - hvitime2
const { months3,days3, hours3, minutes3 } = getTimePeriods3(goohvi2)

const getRowText = () => {
  if ( goo2 > 0) {
        return   <Text bold fontSize="14px">{t('%day%d : %hour%h : %minute%m', { day: days, hour: hours, minute: minutes })}</Text>
  }
  if ( goo2 < 0) {
    return   <Text textAlign="left" fontSize="14px" bold color='contrast'>   
    {t('0d : 0h : 0m')}
     </Text>
  }
  return <Text bold fontSize="14px">{t('%day%d : %hour%h : %minute%m', { day: days, hour: hours, minute: minutes })}</Text>
}

const getRowText0 = () => {
  if ( withdrawtime0 < 2) {
        return   <Text bold fontSize="14px">{getRowText()}</Text>
  }
  if ( withdrawtime0 < 4) {
    return   <Text textAlign="left" fontSize="14px" bold color='contrast'>   
    {getRowText3()}
     </Text>
  }
  return <Text bold fontSize="14px">{t('%day%d : %hour%h : %minute%m', { day: days, hour: hours, minute: minutes })}</Text>
}

const getRowText3 = () => {
  if ( goohvi > 0) {
        return   <Text bold fontSize="14px" >{t('%day%d : %hour%h : %minute%m', { day: days2, hour: hours2, minute: minutes2 })}</Text>
  }
  if ( goohvi < 0) {
    return   <Text textAlign="left" fontSize="14px" bold color='contrast' >   
    {t('END')}
     </Text>
  }
  return <Text bold fontSize="14px">{t('%day%d : %hour%h : %minute%m', { day: days2, hour: hours2, minute: minutes2 })}</Text>
  }

  const getRowText7 = () => {
    if ( goohvi2 > 0) {
          return   <Text bold color="gold"  fontSize="14px" >{t('%day%d : %hour%h : %minute%m', { day: days3, hour: hours3, minute: minutes3 })}</Text>
    }
    if ( goohvi2 < 0) {
      return   <Text textAlign="left" color="gold"  fontSize="14px" bold >   
      {t('FALSE')}
       </Text>
    } 
    return <Text bold fontSize="14px">{t('%day%d : %hour%h : %minute%m', { day: days3, hour: hours3, minute: minutes3 })}</Text>
    }
   

const getRowText2 = () => {
  if ( withdrawtime0 < 2) {
        return   <Text bold fontSize="14px">{t('180 Days')}</Text>
  } 
  if ( withdrawtime0 < 4) {
    return   <Text  bold fontSize="14px">{getRowText3()}</Text>
  }
  return<Text bold fontSize="14px">{t('test3')}</Text>
}
const getRowText6 = () => {
  if ( withdrawtime0 < 2) {
        return   <Text bold fontSize="0px">{t('')}</Text>
  } 
  if ( withdrawtime0 < 4) {
    return   <Text color="gold" bold fontSize="14px">{t('Deposit open:')}</Text>
  }
  return<Text bold fontSize="14px">{t('test3')}</Text>
} 

const getRowText5 = () => {
  if ( withdrawtime0 < 2) {
        return   <Text bold fontSize="0px">{t('')}</Text>
  } 
  if ( withdrawtime0 < 4) {
    return   <Text bold color="gold"  fontSize="14px">{getRowText7()}</Text>
  }
  return<Text bold fontSize="14px">{t('test3')}</Text>
}

 
  const price = usePriceCakeBusd()
  const POOLLOCKED = getBalanceNumber(useLocked3(getCakeAddress()))
  const POOLLOCKED2 = POOLLOCKED.toFixed(0)
  const hviprice = usePriceHVIBusd()
  const POOLLOCKED3 = getBalanceNumber(useLocked3(getCakeAddress()))
  const POOLLOCKED4 = POOLLOCKED3 * 1000000000
  const POOLLOCKED5 = POOLLOCKED4.toFixed(0)
  
  const POOLAPR = getBalanceNumber(useLocked3(getCakeAddress()))
  const POOLAPRe = POOLAPR * 30 / 1000000
  const POOLAPRee = POOLAPRe.toFixed(2)

  const getRowText4 = () => {
    if ( withdrawtime0 < 2) {
          return   <Text bold fontSize="14px">{POOLLOCKED2} / {withdrawtime4} VIZSLASWAP</Text>
    } 
    if ( withdrawtime0 < 4) {
      return   <Text bold fontSize="14px">{POOLLOCKED5} HVI</Text>
    }
    return<Text bold fontSize="14px">{t('')}</Text>
  }

  const Row = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin-bottom: 0px;
`

  return (
    <StyledCard
      isFinished={isFinished && sousId !== 0}
      isActive
      ribbon={isFinished && <CardRibbon variantColor="textDisabled" text={t('Finished')} />}
    >
      <StyledCardHeader
        number= {number}
        isStaking={accountHasStakedBalance}
        earningTokenSymbol={earningToken.symbol}
        stakingTokenSymbol={stakingToken.symbol}
        isFinished={isFinished && sousId !== 0}
      />
      <CardBody>
      <Row>   
          <Text textAlign="left" bold fontSize="14px" color='primary'>   
          {t('APR (driven by the community):')}
          </Text>
          <Text fontSize="14px" textAlign="left" bold color='gold'>   
          {POOLAPRee}% 
           </Text> 
           </Row> 
           <Row>   
          <Text textAlign="left" fontSize="14px"  color='contrast'>   
          {getRowText6()}
          </Text>
          <Text textAlign="left"  fontSize="14px"bold color='contrast'>   
          {getRowText5()}
           </Text>
           </Row>  
        <Row>   
          <Text textAlign="left" fontSize="14px"  color='contrast'>   
          {t('Duration:')}
          </Text>
          <Text textAlign="left"  fontSize="14px"bold color='contrast'>   
          {getRowText2()}
           </Text>
           </Row> 
           <Row>   
          <Text textAlign="left" fontSize="14px" color='contrast'>   
          {t('Exit Penalty:')}
          </Text>
          <Text textAlign="left" fontSize="14px" bold color='contrast'>   
          {t('25%')}
           </Text>
           </Row> 
           <Row>   
          <Text textAlign="left" fontSize="14px" color='contrast'>   
          {t('Locked:')}
          </Text>
          <Text textAlign="left" fontSize="14px" bold color='contrast'>   
           {getRowText4()}
           </Text>
           </Row>  

           {accountHasStakedBalance && (
              <>
           <Row >   
           <Flex>
           <Text textAlign="left" fontSize="14px" color='contrast'>   
          {t('Unlock time')}
           </Text>
          </Flex>
          <Text textAlign="left"  fontSize="14px" bold color='contrast'>   
          {getRowText0()}
           </Text>
           </Row> 
           </>
           )}

        <Flex mt="24px" flexDirection="column">
          {account ? (
            <CardActions pool={pool} stakedBalance={stakedBalance} />
          ) : (
            <>
   
              <ConnectWalletButton />
            </>
          )}
        </Flex>
      </CardBody>
      <CardFooter pool={pool} account={account} />
    </StyledCard>
  )
}

export default PoolCard

import React from 'react'
import styled, { keyframes } from 'styled-components'
import {Flex} from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import Page from 'components/Layout/Page'
import PageHeader from 'components/PageHeader'
import BountyCard from './components/BountyCard'

const runningLightAnimation = keyframes`
  0% {
    text-shadow: 0 0 5px #62d5a3, 0 0 10px #62d5a3, 0 0 15px #62d5a3, 0 0 20px #62d5a3;
  }
  50% {
    text-shadow: 0 0 10px #62d5a3, 0 0 15px #62d5a3, 0 0 20px #62d5a3, 0 0 25px #62d5a3;
  }
  100% {
    text-shadow: 0 0 5px #62d5a3, 0 0 10px #62d5a3, 0 0 15px #62d5a3, 0 0 20px #62d5a3;
  }
`;
const AnimatedText = styled.h1`
  font-size: 55px; // Adjust as needed
  color: white;
  text-align: center;
  width: 100%;
  animation: ${runningLightAnimation} 3s linear infinite;
 
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(98, 213, 163, 0.3) 0%, rgba(98, 213, 163, 0) 70%);
    z-index: -1;
  }
`;


const Faucet3: React.FC = () => {
  const { t } = useTranslation()



  return (
    <>
      <PageHeader>
        <Flex justifyContent="space-between" flexDirection={['column', null, null, 'row']}>
          <Flex flex="1" flexDirection="column" mr={['8px', 0]}>
          <Flex alignItems="center" justifyContent="space-between">
          <AnimatedText>VizslaSwap Bonus System</AnimatedText>

      </Flex>
      
          </Flex>
        </Flex>
      </PageHeader>
 
      <Page>
      <Flex alignItems="center" justifyContent="center" mt="-20px" > 
      <BountyCard />
      </Flex>

      </Page>
    </>
  )
}


export default Faucet3

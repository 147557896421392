import React, { useState } from 'react'
import {
  CloseIcon,
  Heading,
  IconButton,
  InjectedModalProps,
  ModalBody,
  ModalContainer,
  ModalHeader as UIKitModalHeader,
  ModalTitle,
} from '@pancakeswap/uikit'
import BigNumber from 'bignumber.js/bignumber'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import { useFarmUser } from 'state/farms/hooks'
import useTokenBalance, { FetchStatus, useGetBnbBalance } from 'hooks/useTokenBalance'
import {  getCakeAddress} from 'utils/addressHelpers'
import WalletHarvest from './WalletHarvest'


export enum WalletView {
  HARVEST,
}

interface WalletModalProps extends InjectedModalProps {
  initialView?: WalletView
}

export const LOW_BNB_BALANCE = new BigNumber('2000000000') // 2 Gwei
export const LOW_GOD_BALANCE = new BigNumber('2000000000') // 2 Gwei

const ModalHeader = styled(UIKitModalHeader)`
`

const Tabs = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
  padding: 16px 24px;
`

const WalletModal: React.FC<WalletModalProps> = ({ initialView = WalletView.HARVEST, onDismiss }) => {
  const [view, setView] = useState(initialView)
  const { t } = useTranslation()
  const { balance, fetchStatus } = useGetBnbBalance()
  const { balance: cakeBalance } = useTokenBalance(getCakeAddress())


  const hasLowBnbBalance = fetchStatus === FetchStatus.SUCCESS && balance.lte(LOW_BNB_BALANCE)

  const handleClick = (newIndex: number) => {
    setView(newIndex)
  }

  return (
    <ModalContainer title={t('Welcome!')} minWidth="320px">
      <ModalHeader>
        <ModalTitle>
          <Heading>{t('Your Wallet')}</Heading>
        </ModalTitle>
        <IconButton variant="text" onClick={onDismiss}>
          <CloseIcon width="24px" color="text" />
        </IconButton>
      </ModalHeader>
      <ModalBody p="24px" maxWidth="400px" width="100%">
        {view === WalletView.HARVEST && <WalletHarvest hasLowBnbBalance={hasLowBnbBalance}  onDismiss={onDismiss}  />}
      </ModalBody>
    </ModalContainer>
  )
}

export default WalletModal

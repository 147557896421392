import { ConnectorNames } from '@ape.swap/uikit';
import { connectionByType } from 'utils/connection';
import { useWeb3React } from '@web3-react/core/core/core';
import { profileClear } from 'state/profile';
import { WalletConnect } from '@web3-react/walletconnect-v2';
import { useDispatch } from 'react-redux';

// Törli a WalletConnect V2 session adatait a localStorage-ból
function deleteWc2LocalStorageItems(): void {
  const prefix = 'wc@2';
  for (let i = localStorage.length - 1; i >= 0; i--) {
    const key = localStorage.key(i);
    if (key && key.startsWith(prefix)) {
      localStorage.removeItem(key);
    }
  }
}

const useAuth = () => {
  const { connector } = useWeb3React();
  const dispatch = useDispatch();

  const login = async (connectorType?: ConnectorNames) => {
    if (connectorType) {
      const { connector } = connectionByType[connectorType];

      try {
        // WalletConnect session bontása, ha van aktív session
        if (connector instanceof WalletConnect) {
          await connector.deactivate();
          deleteWc2LocalStorageItems();
        }

        await connector.activate();
      } catch (error) {
        console.debug(error);
        console.error(`connection error: ${error}`);
      }
    }
  };

  const logout = () => {
    if (connector && connector.deactivate) {
      try {
        // Próbáljuk meg deaktiválni a tárcát
        connector.deactivate();
      } catch (error) {
        console.error('Hiba történt a tárca deaktiválásakor:', error);
      }
    }

    try {
      // Állapot visszaállítása
      connector.resetState();
    } catch (error) {
      console.error('Hiba történt az állapot visszaállításakor:', error);
    }

    if (connector instanceof WalletConnect) {
      // WalletConnect V2 session bontása
      deleteWc2LocalStorageItems();
    }

    // Redux állapot frissítése
    dispatch(profileClear());
  };

  return { login, logout };
};

export default useAuth;

import React from 'react'
import { Currency } from '@pancakeswap2/sdk/dist/dist/dist5/dist6/distOrder'
import CurrencyLogo from 'components/Logo/CurrencyLogoOrder'
import TextIcon from './TextIcon'

interface CurrencyFormatProps {
  currency: Currency
  bold?: boolean
}

const CurrencyFormat: React.FC<CurrencyFormatProps> = ({ currency, bold }) => {
  return <TextIcon bold={bold} text={currency?.symbol} icon={<CurrencyLogo currency={currency} />} />
}

export default CurrencyFormat

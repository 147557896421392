import React from 'react'
import { Redirect, useParams } from 'react-router-dom'
import AddLiquidity4 from './index'

export function RedirectToAddLiquidity3() {
  return <Redirect to="/addradioliquidity/" />
}

const OLD_PATH_STRUCTURE = /^(0x[a-fA-F0-9]{40}|BNB)-(0x[a-fA-F0-9]{40}|BNB)$/
export function RedirectOldAddLiquidityPathStructure3() {
  const { currencyIdA } = useParams<{ currencyIdA: string }>()
  const match = currencyIdA.match(OLD_PATH_STRUCTURE)
  if (match?.length) {
    return <Redirect to={`/addradioliquidity/${match[1]}/${match[2]}`} />
  }

  return <AddLiquidity4 />
}

export function RedirectDuplicateTokenIds3() {
  const { currencyIdA, currencyIdB } = useParams<{ currencyIdA: string; currencyIdB: string }>()
  if (currencyIdA.toLowerCase() === currencyIdB.toLowerCase()) {
    return <Redirect to={`/addradioliquidity/${currencyIdA}`} />
  }
  return <AddLiquidity4 />
}

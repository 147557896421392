const MINUTE_IN_SECONDS = 60
const HOUR_IN_SECONDS = 3600
const DAY_IN_SECONDS = 86400

/**
 * Format number of seconds into year, month, day, hour, minute, seconds
 *
 * @param seconds
 */
const getTimePeriods3 = (seconds: number) => {
  let delta = Math.abs(seconds)
  const timeLeft = {
    years: 0,
    months3: 0,
    days3: 0,
    hours3: 0,
    minutes3: 0,
    seconds3: 0,
  }


  if (delta >= DAY_IN_SECONDS) {
    timeLeft.days3 = Math.floor(delta / DAY_IN_SECONDS)
    delta -= timeLeft.days3 * DAY_IN_SECONDS
  }

  if (delta >= HOUR_IN_SECONDS) {
    timeLeft.hours3 = Math.floor(delta / HOUR_IN_SECONDS)
    delta -= timeLeft.hours3 * HOUR_IN_SECONDS
  }

  if (delta >= MINUTE_IN_SECONDS) {
    timeLeft.minutes3 = Math.floor(delta / MINUTE_IN_SECONDS)
    delta -= timeLeft.minutes3 * MINUTE_IN_SECONDS
  }

  timeLeft.seconds3 = delta

  return timeLeft
}

export default getTimePeriods3

import React, { useState, useEffect, useCallback } from 'react';
import styled, { css,keyframes } from 'styled-components'
import { useHistory } from 'react-router-dom'; // useHistory a react-router-dom v5-höz
import { createClient } from '@supabase/supabase-js';
import { Button, Text } from '@pancakeswap/uikit';
import { useWeb3React } from '@web3-react/core/core/core';
import { ethers } from 'ethers'; 
import contractABI from './contractABI.json'; // Az ABI fájl importálása
import {
  QuizContainer,
  ScoreText,
  QuestionText,
  QuestionText2,
  Title,
  OptionContainer, 
  OptionButton,
  TimerText,
  SkipButtonWrapper,
  SkipButton,
  PlayAgainButton,
  GameOverContainer,
  QuestionBox,
  TitleContainer,
} from './QuizGameStyles';

// Supabase initialization
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL!;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY!;
const supabase = createClient(supabaseUrl, supabaseKey);

const Circle = styled.div<{ timeLeft: number }>`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 6px solid ${({ timeLeft }) => (timeLeft < 5 ? 'red' : 'green')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: white;
`;
const QuestionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: #16161f;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Adjust spacing between buttons */
  margin-top: 20px; /* Optional: spacing from other elements */
`;

interface Question {
  id: number;
  question: string;
  options: string[];
  correct_answer: string; // eslint-disable-line camelcase
}

const QuizGame = () => {
  const { account } = useWeb3React();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [bestScore, setBestScore] = useState(0); 
  const history = useHistory(); // useHistory használata a v5-ben
  const [bestScoreLoaded, setBestScoreLoaded] = useState(false); 
  const [roundScore, setRoundScore] = useState(0); 
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [timeLeft, setTimeLeft] = useState<number>(10);
  const [quizFinished, setQuizFinished] = useState(false);
  const [loading, setLoading] = useState(true);
  const [optionColors, setOptionColors] = useState<{ [key: string]: string }>({});
  const [skipsRemaining, setSkipsRemaining] = useState(3);
  const [isRevealingAnswer, setIsRevealingAnswer] = useState(false);
  const [revealAnswerRemaining, setRevealAnswerRemaining] = useState(3); 
  const [timer, setTimer] = useState<number>(12);  // Hozzáadott időzítő állapot
  const [canPlay, setCanPlay] = useState<boolean>(true);
  const [language, setLanguage] = useState<string>('en'); 
  const [languageSelected, setLanguageSelected] = useState<boolean>(false); 
  const [gameStarted, setGameStarted] = useState(false);
  const optionLabels = ["A", "B", "C", "D"];
  const CircularTimer = ({ timeLeft }) => {
    return <Circle timeLeft={timeLeft}>{timeLeft}</Circle>;
  };
  const goToToplist = () => {
    history.push('/quiztoplist'); // Átirányítás a toplistára
  };
  const [userName, setUserName] = useState<string | null>(null); // Added state for user name
  const handleRegisterNameClick = () => {
    history.push('/settings'); // Navigate to the settings page to register name
  };

  const contractAddress = '0x674e3eDc898Aa4b486Bd2E5aa2D1C8653677F471';  // Add your contract address

  // Get contract
  const getContract = useCallback(() => {
    const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org');
    return new ethers.Contract(contractAddress, contractABI, provider);
  }, []);

  // Function to fetch the user's name
  const fetchUserName = useCallback(async () => {
    if (!account) return;

    try {
      const contract = getContract();
      const name = await contract.getName(account); // Call contract to get the user's name
      if (name) {
        setUserName(name);
      } else {
        setUserName(null); // No name found
      }
    } catch (error) {
      console.error('Error fetching user name:', error);
      setUserName(null);
    }
  }, [account, getContract]);

  useEffect(() => {
    fetchUserName();
  }, [fetchUserName]);

  const startGame = async () => {
    // Immediate UI update for game start
    setGameStarted(true);
    setQuizFinished(false);
    setLoading(true);
  
    // Update the database to reflect the game has started
    if (account) {
      await supabase
        .from('quiz_scores')
        .update({ is_game_ended: false, last_question_timestamp: new Date().toISOString() })
        .eq('account', account);
    }
  
    // Fetch scores and questions asynchronously
    await fetchScores();
    await fetchQuestions();
  
    setLoading(false); // Set loading to false after data is fetched
    setTimeLeft(20); // Start with 20 seconds for questions 1–20
  };
  
  useEffect(() => {
    if (gameStarted) {
      setCurrentQuestionIndex(0);
      setQuizFinished(false);
      setScore(0);
      setRoundScore(0);
      setSkipsRemaining(3);
      setRevealAnswerRemaining(3);
      setSelectedOption(null);
      setOptionColors({});
      setTimeLeft(20); // Start with 20 seconds for the initial question
    }
  }, [gameStarted]);
  
  const handleRevealAnswer = () => {
    if (revealAnswerRemaining === 0 || selectedOption) return;
  
    setIsRevealingAnswer(true); // Pause the timer
    setSelectedOption(questions[currentQuestionIndex].correct_answer);
    setOptionColors({ [questions[currentQuestionIndex].correct_answer]: '#62d5a3' });
    setRevealAnswerRemaining((prev) => prev - 1);
    const newRoundScore = roundScore + 1;
    setRoundScore(newRoundScore);
    setScore(newRoundScore);
    saveScores(newRoundScore, true, skipsRemaining, revealAnswerRemaining - 1);
  
    setTimeout(() => {
      setIsRevealingAnswer(false); // Resume the timer
      if (currentQuestionIndex + 1 < questions.length) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setSelectedOption(null);
        setOptionColors({});
        setTimeLeft(currentQuestionIndex < 20 ? 20 : currentQuestionIndex < 50 ? 15 : 10);
      } else {
        setQuizFinished(true);
        saveScores(newRoundScore);
      }
    }, 5000); // Reveal answer for 2 seconds
  };

  const shuffleArray = (originalArray) => {
    const array = [...originalArray]; // Készítünk egy másolatot az eredeti tömbről
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // A másolat tömböt módosítjuk
    }
    return array;
  };
  
  
  const fetchQuestions = useCallback(async () => {
    setLoading(true);
    const { data, error } = await supabase
      .from('quiz_questions')
      .select('id, question, options, correct_answer')
      .eq('language', language)
      .limit(1550);
  
    if (error) {
      console.error('Error fetching questions:', error.message);
    } else if (data) {
      // Első keverés
      let randomQuestions = shuffleArray(data);
  
      // Második keverés
      randomQuestions = shuffleArray(randomQuestions.map((question) => ({
        ...question,
        options: shuffleArray(question.options),
      })));
  
      // További keverés kisebb csoportokban
      const chunkSize = Math.floor(randomQuestions.length / 3);
      const shuffledChunks = [];
      for (let i = 0; i < randomQuestions.length; i += chunkSize) {
        const chunk = randomQuestions.slice(i, i + chunkSize);
        shuffledChunks.push(...shuffleArray(chunk));
      }
  
      // Csak 700 kérdést tart meg a végső listából, ha több van
      setQuestions(shuffledChunks.slice(0, 1550));
    }
    setLoading(false);
  }, [language]);
  

  const fetchScores = useCallback(async () => {
    if (account) {
      const { data, error } = await supabase
        .from('quiz_scores')
        .select('current_score, best_score, last_question_timestamp, skips_remaining, reveal_answer_remaining, is_game_ended')
        .eq('account', account)
        .single();
  
      if (error) {
        console.error('Error fetching scores:', error.message);
      } else if (data) {
        setScore(data.current_score || 0);
        setBestScore(data.best_score || 0);
        setBestScoreLoaded(true);
        setSkipsRemaining(data.skips_remaining || 3);
        setRevealAnswerRemaining(data.reveal_answer_remaining || 3);
  
        const lastTimestamp = new Date(data.last_question_timestamp).getTime();
        const currentTime = new Date().getTime();
        const timeElapsed = (currentTime - lastTimestamp) / 1000;
  
        if (data.is_game_ended || timeElapsed > 10) {
          // Ha a játék már befejezett vagy az időkorlát túllépésre került, vége a játéknak
          setQuizFinished(true);
          setGameStarted(false);
          await supabase
            .from('quiz_scores')
            .update({ is_game_ended: true })
            .eq('account', account);
        } else {
          // Ha a játék folytatható, beállítjuk a fennmaradó időt
          setTimeLeft(10 - Math.floor(timeElapsed));
        }
      }
    }
  }, [account]);
  
  const saveScores = useCallback(async (finalScore: number, isTimeUpdateOnly = false, skipsRemaining?: number, revealAnswerRemaining?: number) => {
    if (!account) return;
  
    const lastQuestionTimestamp = new Date().toISOString();
    const updateData: any = {
      account,
      last_question_timestamp: lastQuestionTimestamp,
      is_game_ended: quizFinished, // Set to true if the game is finished
    };
  
    if (!isTimeUpdateOnly) {
      updateData.current_score = finalScore;
      if (finalScore > bestScore) {
        updateData.best_score = finalScore;
        setBestScore(finalScore);
      }
    }
  
    if (skipsRemaining !== undefined) {
      updateData.skips_remaining = skipsRemaining;
    }
    if (revealAnswerRemaining !== undefined) {
      updateData.reveal_answer_remaining = revealAnswerRemaining;
    }
  
    await supabase.from('quiz_scores').upsert(updateData, { onConflict: ['account'] });
  }, [account, bestScore, quizFinished]);

  useEffect(() => {
    fetchScores(); // Csak a pontszámok beolvasása a játék indulásakor
  }, [fetchScores]);
  
  useEffect(() => {
    fetchQuestions(); // Kérdések beolvasása külön, ha szükséges
  }, [fetchQuestions]);

  const handleOptionSelect = async (option: string) => {
  const isCorrect = questions[currentQuestionIndex].correct_answer === option;
  setSelectedOption(option);

  setOptionColors({
    [option]: isCorrect ? '#62d5a3' : 'red', // Helytelen válasz piros, helyes zöld
    [questions[currentQuestionIndex].correct_answer]: '#62d5a3',
  });

  if (isCorrect) {
    // Helyes válasz kezelése
    const newRoundScore = roundScore + 1;
    setRoundScore(newRoundScore);
    setScore(newRoundScore);

    setIsRevealingAnswer(true); // Időzítő szüneteltetése
    setTimeout(async () => {
      setIsRevealingAnswer(false); // Időzítő folytatása
      if (currentQuestionIndex + 1 < questions.length) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setSelectedOption(null);
        setOptionColors({});
        setTimeLeft(currentQuestionIndex + 1 < 20 ? 20 : currentQuestionIndex + 1 < 50 ? 15 : 10);
      } else {
        setQuizFinished(true);
        await saveScores(newRoundScore); // Játék befejezése a végső pontszámmal
      }
    }, 5000);
  } else {
    // Rossz válasz esetén piros jelzés mutatása 5 másodpercig
    setCanPlay(false);

    // Azonnal frissítjük az adatbázist
    await supabase
      .from('quiz_scores')
      .update({ is_game_ended: true, current_score: roundScore, last_question_timestamp: new Date().toISOString() })
      .eq('account', account);

    // 5 másodperces késleltetés, hogy a játékos lássa a helytelen választ
    setTimeout(async () => {
      setQuizFinished(true); // Játék vége állapot beállítása
      await saveScores(roundScore); // Végső pontszám mentése a játék végén
    }, 5000); // 5 másodperc várakozás, mielőtt a játék ténylegesen véget ér
  }
};

  const handleSkip = () => {
    if (skipsRemaining > 0) {
      setSkipsRemaining(skipsRemaining - 1);
      setCurrentQuestionIndex((prevIndex) => (prevIndex + 1) % questions.length);
      setSelectedOption(null);
      setOptionColors({});
      setTimeLeft(currentQuestionIndex + 1 < 20 ? 20 : currentQuestionIndex + 1 < 50 ? 15 : 10);
      saveScores(roundScore, true, skipsRemaining - 1);
    }
  };

  useEffect(() => {
    if (!quizFinished && timeLeft > 0 && !loading && !isRevealingAnswer) {
      const timer = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
      return () => clearInterval(timer);
    }
  
    if (timeLeft === 0 && !loading) {
      setOptionColors({ [questions[currentQuestionIndex].correct_answer]: '#62d5a3' });
      setTimeout(async () => {
        setQuizFinished(true);
  
        // Az adatbázis frissítése, hogy a játék időkorlát miatt véget ért
        await supabase
          .from('quiz_scores')
          .update({ is_game_ended: true, current_score: roundScore, last_question_timestamp: new Date().toISOString() })
          .eq('account', account);
        
        await saveScores(roundScore);
      }, 5000);
    }
  
    return undefined;
  }, [timeLeft, quizFinished, loading, isRevealingAnswer, saveScores, roundScore, questions, currentQuestionIndex, account]);
  
  
 const resetGame = async () => {
  // Immediate UI update for game reset
  setGameStarted(false);
  setQuizFinished(false);
  setRoundScore(0);
  setScore(0);
  setCurrentQuestionIndex(0);
  setSelectedOption(null);
  setOptionColors({});
  setTimeLeft(20); // Set initial 20 seconds for the first 20 questions
  setSkipsRemaining(3); 
  setRevealAnswerRemaining(3);

  if (account) {
    await supabase
      .from('quiz_scores')
      .update({ is_game_ended: false })
      .eq('account', account);
  }

  saveScores(0, false, 3, 3); // Initial score save
  await fetchQuestions();
};

  useEffect(() => {
    if (quizFinished) {
      const timer = setTimeout(() => {
        setCanPlay(true);
      }, 10000); // 10 másodperc múlva újra engedélyezi a Play Again gombot
  
      return () => clearTimeout(timer); // Törli az időzítőt, ha a komponens frissül vagy bezárul
    }
    
    return undefined; // Adjunk vissza egy üres értéket, ha quizFinished hamis
  }, [quizFinished]);  

  return (
    <>
   {!gameStarted && (
      <TitleContainer>
        <Title as="h1" scale="xxl">VizslaQuiz</Title>
        <img src="images/pixel/1222.png" alt="BlackJack Icon" style={{ width: '40px', height: '40px', marginBottom:'10px' }} />
      </TitleContainer>
    )}
      <QuizContainer>
        {account ? (
          <>
            {!userName ? ( // Ha nincs felhasználónév, csak a regisztrációs gomb jelenik meg
              <div>
                <Text mb="10px">Please register a name to start the game.</Text>
                <Button onClick={handleRegisterNameClick} variant="gold">
                  Register Your Name
                </Button>
              </div>
            ) : ( // Ha van felhasználónév, a többi lehetőség elérhető
              <>
                {!languageSelected ? (
                  <div>
                    <Text mb="10px">Select Language:</Text>
                    <Button mr="5px" variant="gold" onClick={() => { setLanguage('en'); setLanguageSelected(true); }}>English</Button>
                    <Button mr="5px" variant="gold" onClick={() => { setLanguage('hu'); setLanguageSelected(true); }}>Magyar</Button>
                    <Button variant="gold" onClick={goToToplist} width="50px">
      🏆
    </Button>
                  </div>
                ) : !gameStarted ? (
                  <div>
                    <Text mb="10px">Ready to start?</Text>
                    <Button mr="5px" variant="gold" onClick={startGame}>Start Game</Button>
                    <Button variant="gold" onClick={goToToplist} width="50px">
      🏆
    </Button>
                  </div>
                ) : loading ? (
                  <Text>Loading questions...</Text>
                ) : quizFinished ? (
                  <GameOverContainer>
               <ButtonWrapper>
    <PlayAgainButton disabled={!canPlay} variant="gold" onClick={resetGame}>
      Play Again
    </PlayAgainButton>
    <Button variant="gold" onClick={goToToplist} width="50px" mt="20px">
      🏆
    </Button>
  </ButtonWrapper>
                    <QuestionBox>
                      <ScoreText bold>Game Over! Your score: {roundScore}</ScoreText>
                      <ScoreText bold>Your Best Score: {bestScore}</ScoreText>
                    </QuestionBox>
                  </GameOverContainer>
                ) : (
                  <>
                    <QuestionText bold>Question {currentQuestionIndex + 1} / {questions.length}</QuestionText>
                      <QuestionRow>
                      <QuestionText2>{questions[currentQuestionIndex]?.question}</QuestionText2>
                      <TimerText>
  <CircularTimer timeLeft={timeLeft} />
</TimerText>               
                      </QuestionRow>
                    <SkipButtonWrapper>
                      <SkipButton variant="secondary" disabled={skipsRemaining === 0 || selectedOption} onClick={handleSkip}>Skip ({skipsRemaining})</SkipButton>
                      <SkipButton variant="secondary" onClick={handleRevealAnswer} disabled={revealAnswerRemaining === 0 || selectedOption}>Reveal Answer ({revealAnswerRemaining})</SkipButton>
                    </SkipButtonWrapper>
                    <OptionContainer>
                      {(questions[currentQuestionIndex]?.options).map((option, index) => (
                        <OptionButton
                          key={option}
                          variant="tertiary"
                          style={{
                            background: optionColors[option] || '#16161f',
                            textAlign: 'left',
                            justifyContent: 'flex-start',
                          }}
                          onClick={() => handleOptionSelect(option)}
                          disabled={!!selectedOption}
                        >
                          <span style={{ color: 'gold', fontWeight: 'bold', marginRight: '8px' }}>
                            {optionLabels[index]}:
                          </span>
                          {option}
                        </OptionButton>
                      ))}
                    </OptionContainer>
                    <QuestionBox>
                      <ScoreText bold>Score: {score}</ScoreText>
                      <ScoreText bold>Your Best Score: {bestScore}</ScoreText>
                    </QuestionBox>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <Text>Please connect your wallet to play the quiz.</Text>
        )}
      </QuizContainer>
    </>
  );  
};

export default QuizGame; 
import React from 'react'
import { Modal, Text, Flex} from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'

interface ModalInfoProps {
  onDismiss?: () => void
  TooltipComponent: React.ElementType
}


const ModalInfo3: React.FC<ModalInfoProps> = ({ onDismiss }) => {
  const { t } = useTranslation()



  return (
    <Modal title={t('Hungarian Vizsla Inu ECOSYSTEM!')} onDismiss={onDismiss}>

          <Flex alignItems="left" justifyContent="left">
  <Text fontSize="15px" >  {t('The first major Hungarian investment & charity token on the Binance Smart Chain. We started as a memecoin, but our goal is to become a significant global altcoin. That’s why we are building a friendly crypto community, creating and developing the HVI ecosystem – while also providing donations to the dogs in need. Incubation program: https://vizslainu.com/incubator/ Charity: https://vizslainu.com/charity/ Blog: https://vizslainu.com/blog/')} </Text>
      </Flex>
      <Text fontSize="20px" color="gold" >  {t('VizslaMarket (NFT Market: https://vizslamarket.io/)')} </Text>
      <Flex alignItems="left" justifyContent="left">
  <Text fontSize="15px" >  {t('Self-developed NFT MARKET. The bottom line is the adoption of animals in the form of NFTs, of which more than 90% are donations on average. It is unique in the market because of what do you prefer? Trading random generated #jpeg or the picture of a real living animal which you support? Read more: https://vizslainu.com/nft-vizsla-market/')} </Text>
      </Flex>
      <Text fontSize="20px" color="gold" >  {t('VizslaSwap (DeFi: https://www.vizslaswap.com/)')} </Text>
      <Flex alignItems="left" justifyContent="left">
  <Text fontSize="15px" >  {t('VizslaSwap is a decentralized exchange running on BNB Smart Chain with lots of features that let you trade and earn tokens. VizslaSwap Token is a perpetual deflation token with a sufficient burn mechanism. We are not trying to replace the swap & exchange but to add value into the ecosystem and create a suitable and sustainable environment for people to liquidity mining and staking pools with high APR. Trade directly from your wallet app. VizslaSwap doesn’t hold your funds when you trade: you have 100% ownership of your own crypto. Read more: https://vizslainu.com/swap/')} </Text>
      </Flex>
    </Modal>

  )
} 

export default ModalInfo3

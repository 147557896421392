import React from 'react'
import { Flex, useModal, CalculateIcon, Skeleton, FlexProps, Button } from '@pancakeswap/uikit'
import ApyCalculatorModal from 'components/ApyCalculatorModal'
import Balance from 'components/Balance'
import { Pool } from 'state/types'
import { useTranslation } from 'contexts/Localization'
import { getBalanceNumber, formatLocalisedCompactNumber, getFullDisplayBalance } from 'utils/formatBalance'
import { usePriceCakeBusd , usePriceHVIBusd} from 'state/farms/hooks'
import { getAddress, getHVIAddress , getCakeAddress } from 'utils/addressHelpers'
import { useTotalSupply, useVizslaLPBalance, useHVILPBalance ,useTotalSupplyVIZSLAHVI} from 'hooks/useTokenBalance'
import { getAprData } from 'views/FarmPools/helpers'

interface AprProps extends FlexProps {
  pool: Pool
  showIcon: boolean
  performanceFee?: number
}

const Apr: React.FC<AprProps> = ({ pool, showIcon, performanceFee = 0, ...props }) => {
  const { stakingToken, earningToken, isFinished, earningTokenPrice, apr } = pool
  const { t } = useTranslation()
  const totalSupply = useTotalSupply()
  const vizslalpbalance = getBalanceNumber(useVizslaLPBalance(getCakeAddress()))
  const cakePriceBusd = usePriceCakeBusd()
  const cakeSupply2 = totalSupply ? getBalanceNumber(totalSupply) : 0
  const mcap = cakePriceBusd.times(cakeSupply2).times(1000000000)
  const mcap2 = mcap.toNumber()
  const vizslaprice = cakePriceBusd.toNumber()
  const hvilpbalance = getBalanceNumber(useHVILPBalance(getHVIAddress()))
  const hviPriceBusd = usePriceHVIBusd()
  const hviprice = hviPriceBusd.toNumber()
  const vizslahvisupply = useTotalSupplyVIZSLAHVI()
  const vizslahvisupply2 = vizslahvisupply ? getBalanceNumber(vizslahvisupply) : 0
  const full = vizslaprice * vizslalpbalance 
  const full2 = hviprice * hvilpbalance
 const full3 = full + full2
 const full4 = full3 / vizslahvisupply2 * 2
 
  const { apr: earningsPercentageToDisplay, roundingDecimals, compoundFrequency } = getAprData(pool, performanceFee)

  const apyModalLink = stakingToken.address ? `/swap?outputCurrency=${getAddress(stakingToken.address)}` : '/swap'

  const [onPresentApyModal] = useModal(
    <ApyCalculatorModal
      tokenPrice={earningTokenPrice}
      apr={apr}
      linkLabel={t('Get %symbol%', { symbol: stakingToken.symbol })}
      linkHref={apyModalLink}
      earningTokenSymbol={earningToken.symbol}
      roundingDecimals={roundingDecimals}
      compoundFrequency={compoundFrequency}
      performanceFee={performanceFee}
    />,
  )


  return (
    <Flex alignItems="center" justifyContent="space-between" {...props}>
      {earningsPercentageToDisplay || isFinished ? (
        <>
          <Balance
            fontSize="16px"
            isDisabled={isFinished}
            value={isFinished ? 0 : earningsPercentageToDisplay * full4}
            decimals={2}
            unit="%"
          />
        </>
      ) : (
        <Skeleton width="80px" height="16px" />
      )}
    </Flex>
  )
}

export default Apr

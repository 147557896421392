import React from 'react'
import { Modal, Text, Flex } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'

interface ModalInfoProps {
  onDismiss?: () => void
  TooltipComponent: React.ElementType
}


const ModalInfo2: React.FC<ModalInfoProps> = ({ onDismiss }) => {
  const { t } = useTranslation()
  return (
    <Modal title={t('About Baby Vizsla Inu Token')} onDismiss={onDismiss}>

          <Flex alignItems="left" justifyContent="left">
  <Text fontSize="15px" >  {t('As part of our incubation program for the Hungarian Vizsla Inu (HVI), we sell drawings of orphanage children as NFT at the HVI NFT Market (vizslamarket.io) to get a new chance for the future from the proceeds. This uniquely supports talented children. All transactions are made through the BABYVIZSLA Token on the basis of an individual contract. The money from the pictures sold will be given to the children in the BABYVIZSLA Token. In addition, we are constantly posting educational and training videos on our YouTube channel. One of our main target is to list our token on every relevant digital exchanges, because high holder number can generate more transaction value and it will create our golden fund to help orphans. We want to be spread around the world as fast as possible by the power of the good deeds that we acn share with the ones in need. BABYVIZSLA were born in the incubator program of the most popular hungarian token Hungarian Vizsla Inu, but we are also stepping on the (inter)national stage. Our purpose is clear without any black shadow, just to be a rock solid base for orphans, be the guard of their future, be their pillar of hope. We are active, we are funny and supportive with decades of experience in many fields like marketing, hospitality and in the finance sector. So let’s meet up in this project and be a valued and active member of our happy community. In long-term we want to become a popular altcoin as we will develop our own utility. That is how we want to earn our land and place in the crypto world.')} </Text>
      </Flex>

    </Modal> 
  )
}

export default ModalInfo2
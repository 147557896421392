import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { keyframes } from "styled-components";
import { createClient } from '@supabase/supabase-js';
import axios from 'axios';
import { ethers } from 'ethers'; 
import { Button, Box, Input, Card, Text } from '@pancakeswap/uikit';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import ConnectWalletButton from 'components/ConnectWalletButton'; 

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);
const textGlow = keyframes`
  0% {
    text-shadow: 0 0 3px #62d5a3, 0 0 6px #62d5a3;
  }
  50% {
    text-shadow: 0 0 3px #4abf88, 0 0 6px #4abf88;
  }
  100% {
    text-shadow: 0 0 3px #62d5a3, 0 0 6px #62d5a3;
  }
`;

// Styled Text with glowing effect
const GlowingText = styled(Text)`
  animation: ${textGlow} 2s infinite;
  color: #62d5a3;
  font-size: 24px;
  font-weight: bold;
`;

// Define the glowing border animation
const borderGlow = keyframes`
  0% {
    border-color: #62d5a3;
    box-shadow: 0 0 5px #62d5a3;
  }
  50% {
    border-color: #4abf88;
    box-shadow: 0 0 15px #4abf88;
  }
  100% {
    border-color: #62d5a3;
    box-shadow: 0 0 5px #62d5a3;
  }
`;

// Styled Box with glowing border animation
const GlowingBox = styled(Box)`
  animation: ${borderGlow} 2s infinite;
  border: 2px solid #62d5a3;
  border-radius: 8px;
  padding: 20px;
    justify-content: center;
  margin-top: 40px;
  max-width: 400px;
 `;
 const PageWrapper = styled.div`
 display: flex;
 justify-content: center;
`;
const contractABI = [
  {
    "inputs": [{ "internalType": "string", "name": "_name", "type": "string" }],
    "name": "registerName",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function",
  },
  {
    "inputs": [{ "internalType": "address", "name": "_user", "type": "address" }],
    "name": "getName",
    "outputs": [{ "internalType": "string", "name": "", "type": "string" }],
    "stateMutability": "view",
    "type": "function",
  },
];

const contractAddress = '0x674e3eDc898Aa4b486Bd2E5aa2D1C8653677F471'; 

const RedirectPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get('userId'); // A userId query paraméterből
  const [isRegistered, setIsRegistered] = useState(false);
  const [hasExistingName, setHasExistingName] = useState(false);
  const [contract, setContract] = useState<ethers.Contract | null>(null);
  const [name, setName] = useState(''); 
  const { account } = useActiveWeb3React();

  useEffect(() => {
    const initializeContract = async () => {
      if (window.ethereum && account) {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const contractInstance = new ethers.Contract(contractAddress, contractABI, signer);
          setContract(contractInstance);

          const existingName = await contractInstance.getName(account);
          if (existingName) {
            setHasExistingName(true);
          }
        } catch (error) {
          console.error('Failed to initialize contract:', error);
        }
      }
    };
    initializeContract();
  }, [account]);

  // Új kattintás beszámítása, ha a tranzakció sikeres és nincs már regisztrált neve
  const handleClickCount = useCallback(async () => {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      const ipAddress = response.data.ip;
  
      const { data: existingClick } = await supabase
        .from('clicks')
        .select('*')
        .eq('user_id', userId)
        .eq('ip_address', ipAddress)
        .single();
  
      if (existingClick) {
        console.log('Click already counted for this IP');
        return;
      }
  
      await supabase
        .from('clicks')
        .insert([{ user_id: userId, ip_address: ipAddress }]);
  
      const { data: userLink } = await supabase
        .from('user_links')
        .select('click_count')
        .eq('user_id', userId)
        .single();
  
      if (userLink) {
        const newClickCount = userLink.click_count + 1;
  
        await supabase
          .from('user_links')
          .update({ click_count: newClickCount })
          .eq('user_id', userId);
  
        console.log(`Click count updated to ${newClickCount} for user ${userId}`);
      }
    } catch (error) {
      console.error('Error updating click count:', error);
    }
  }, [userId]);

  // Csak akkor számol kattintást, ha sikeresen regisztráltak
  const registerAndVerifyName = async () => {
    try {
      if (hasExistingName) {
        alert('You already have a registered name.');
        return;
      }
      if (!name || !contract) {
        alert("Please enter a valid name.");
        return;
      }

      const tx = await contract.registerName(name);
      await tx.wait(); 

      const registeredName = await contract.getName(await contract.signer.getAddress());
      if (registeredName === name) {
        setIsRegistered(true);
        alert('Name registered successfully!');
        
        // Csak akkor számol kattintást, ha sikeresen regisztrált
        await handleClickCount();
      } else {
        alert('Name registration failed.');
      }
    } catch (error) {
      console.error('Error registering name:', error);
    }
  };

  const checkUserAndCountClick = useCallback(async () => {
    try {
      const { data: userLink } = await supabase
        .from('user_links')
        .select('generated_link')
        .eq('user_id', userId)
        .single();
  
      if (!userLink) {
        alert('Invalid referral link.');
      }
    } catch (error) {
      console.error('Error checking user ID:', error);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      checkUserAndCountClick();
    } else {
      alert('Missing userId');
    }
  }, [checkUserAndCountClick, userId]);

  return ( 
    <PageWrapper>
    <GlowingBox maxWidth="400px"   padding="20px" mt="40px" >
      <Card padding="20px" style={{ backgroundColor: 'black', color: 'white' }}>
        <Text fontSize="24px" color="gold" bold>
          Welcome to VizslaSwap! 🎉
        </Text>
        <Text fontSize="16px" marginTop="10px">
          Please register your name to become a member of VizslaSwap. At the end of the month, we&apos;ll raffle off VIZSLASWAP tokens among new members.
        </Text>
        {account ? (
          hasExistingName ? (
            <Text fontSize="16px" bold>
              You already have a registered name. No need to register again.
            </Text>
          ) : (
            <>
              <Text fontSize="24px" bold marginTop="20px" color="gold">
                Register Your Name and Win
              </Text>
              <Input
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  border: '1px solid #fff',
                  borderRadius: '8px',
                  marginTop: '10px',
                  padding: '10px',
                  width: '100%',
                }}
              />
              <Button onClick={registerAndVerifyName} mt="20px" variant="gold">
                Register Name
              </Button>
            </>
          )
        ) : (
          <ConnectWalletButton />
        )}
      </Card>
    </GlowingBox>
    </PageWrapper>
  ); 
};

export default RedirectPage;

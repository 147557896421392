interface LevelSettings {
  MAX_MOVES: number;
  NUM_TILES_TO_GO: number;
  NUMBERS: number[];
  BOARD_SIZE: number;
  targetTileIds: number[]; // Az adott szinten begyűjtendő cukorkák ID-i
  UNBREAKABLE_TILE_COUNT: number; // Törhetetlen csempék száma
}

export const levels: LevelSettings[] = [
  { MAX_MOVES: 11, NUM_TILES_TO_GO: 15, NUMBERS: [1, 2, 3, 4], BOARD_SIZE: 8, targetTileIds: [1, 2, 3, 4], UNBREAKABLE_TILE_COUNT: 2 }, // 1. szint
  { MAX_MOVES: 10, NUM_TILES_TO_GO: 20, NUMBERS: [1, 2, 3, 4], BOARD_SIZE: 9, targetTileIds: [1, 2, 3, 4], UNBREAKABLE_TILE_COUNT: 5 }, // 2. szint
  { MAX_MOVES: 9, NUM_TILES_TO_GO: 25, NUMBERS: [1, 2, 3, 4], BOARD_SIZE: 9, targetTileIds: [1, 2, 3, 4], UNBREAKABLE_TILE_COUNT: 10 }, // 3. szint
  // További szintek hozzáadhatók különböző beállításokkal
];

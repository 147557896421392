import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'VizslaSwap',
  description:
    'VizslaSwap is the decentralized exchange of the Hungarian Vizsla Inu (HVI) Ecosystem. Buy, Trade HVI or Partner tokens and stake them for best yields. All at one place. VizslaSwap – the simplest way to join the Vizsla Ecosystem.',
  image: 'https://lotery.cryptochiptoken.com/images/hero.jpg',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('Home')} | ${t('Vizslaswap')}`,
      }
    case '/competition':
      return {
        title: `${t('VizslaSwap')}`,
      }
    case '/prediction':
      return {
        title: `${t('VizslaSwap')}`,
      }
    case '/mining':
      return {
        title: `${t('VizslaSwap')}`,
      }
    case '/pools':
      return {
        title: `${t('VizslaSwap')}`,
      }
    case '/lottery':
      return {
        title: `${t('VizslaSwap')}`,
      }
    case '/collectibles':
      return {
        title: `${t('VizslaSwap')}`,
      }
    case '/ifo':
      return {
        title: `${t('VizslaSwap')}`,
      }
    case '/teams':
      return {
        title: `${t('VizslaSwap')}`,
      }
    case '/profile/tasks':
      return {
        title: `${t('VizslaSwap')}`,
      }
    case '/profile':
      return {
        title: `${t('VizslaSwap')}`,
      }
    default:
      return null
  }
}

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Flex, TooltipText, IconButton, useModal, CalculateIcon, Skeleton, useTooltip } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import Balance from 'components/Balance'
import { useWeb3React } from '@web3-react/core/core/core'
import ApyCalculatorModal from 'components/ApyCalculatorModal'
import { Pool } from 'state/types'
import { getBalanceNumber, formatLocalisedCompactNumber, getFullDisplayBalance } from 'utils/formatBalance'
import { usePriceliraBusd , usePriceCakeBusd, usePriceLGCBusd, usePriceHVIBusd, usePricebabyBusd} from 'state/farms/hooks'
import { getAddress, getHVIAddress , getCakeAddress, getLAMEAAddress, geLiraAddress, getlgcAddress, getbabyAddress } from 'utils/addressHelpers'
import { useTotalSupply, useVizslaLameaLPBalance, useLAMEALPBalance ,useTotalSupplyFarm25, useFarm25, useFarm27, useTotalSupplyFarm27} from 'hooks/useTokenBalance'
import { getAprData } from 'views/FarmPools/helpers'
import { useFetchPublicPoolsData, usePools, useFetchCakeVault, useCakeVault } from 'state/pools/hooks'

interface AprRowProps {
  pool: Pool
  performanceFee?: number
}

const AprRow: React.FC<AprRowProps> = ({ pool, performanceFee = 0 }) => {
  const { t } = useTranslation()
  const { stakingToken, earningToken, isFinished, apr, earningTokenPrice, isAutoVault  } = pool

  const tooltipContent = isAutoVault
    ? t('APY includes compounding, APR doesn’t. This pool’s VIZSLASWAP is compounded automatically, so we show APY.')
    : t('This pool’s rewards aren’t compounded automatically, so we show APR')

  const { targetRef, tooltip, tooltipVisible } = useTooltip(tooltipContent, { placement: 'bottom-start' })
  const { account } = useWeb3React()

  const { apr: earningsPercentageToDisplay, roundingDecimals, compoundFrequency } = getAprData(pool, performanceFee)
  const { pools: poolsWithoutAutoVault, userDataLoaded } = usePools(account)


  
  const apyModalLink = stakingToken.address ? `/swap?outputCurrency=${getAddress(stakingToken.address)}` : '/swap'
  const totalSupply = useTotalSupply()

  const cakeprice0 = usePriceCakeBusd()
  const cakeprice = cakeprice0.toNumber()
  const babyprice0 = usePricebabyBusd()
  const babyprice = babyprice0.toNumber()
  const cakelp = getBalanceNumber(useFarm27(getCakeAddress()))
  const babylp = getBalanceNumber(useFarm27(getbabyAddress()))
  
  const kombo43 = useTotalSupplyFarm27()
  const kombo44 = kombo43 ? getBalanceNumber(kombo43) : 0
  
  const teljes64 = cakeprice * cakelp
  const teljes65 = babyprice * babylp
  const teljes66 = teljes64 + teljes65 
  const full7 = teljes66 / kombo44 

 
  const [onPresentApyModal] = useModal(
    <ApyCalculatorModal
      tokenPrice={earningTokenPrice}
      apr={apr}
      linkLabel={t('Get %symbol%', { symbol: stakingToken.symbol })}
      linkHref={apyModalLink}
      earningTokenSymbol={earningToken.symbol}
      roundingDecimals={roundingDecimals}
      compoundFrequency={compoundFrequency}
      performanceFee={performanceFee}
    />,
  )

  return (
    <Flex alignItems="center" justifyContent="space-between">
      {tooltipVisible && tooltip}
      <TooltipText ref={targetRef}>{isAutoVault ? `${t('APY')}:` : `${t('APR')}:`}</TooltipText>
      {isFinished || !apr ? (
        <Skeleton width="82px" height="32px" />
      ) : (
        <Flex alignItems="center">
          <Balance
            fontSize="16px"
            isDisabled={isFinished}
            value={ earningsPercentageToDisplay * full7}
            decimals={2}
            unit="%"
            bold
          />
        </Flex>
      )}
    </Flex>
  )
}

export default AprRow

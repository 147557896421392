import React, { useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { useWeb3React } from '@web3-react/core/core/core'
import { Heading, Flex, Text, CopyIcon, Box, IconButton, Button, BaseLayout, useModal, Image } from '@pancakeswap/uikit';
import { useTranslation } from 'contexts/Localization';
import usePersistState from 'hooks/usePersistState';
import { useCakeVault, usePools } from 'state/pools/hooks';
import Page from 'components/Layout/Page';
import PageHeader from 'components/PageHeader';
import ModalInfo from './components/ModalInfo';
import ModalInfo1 from './components/ModalInfo1';
import ModalInfo3 from './components/ModalInfo3';
import ModalInfo4 from './components/ModalInfo4';
import ModalInfo5 from './components/ModalInfo5';
import YoutubeEmbed from './components/YoutubeEmbed';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;


const Cards = styled(BaseLayout)`
  align-items: stretch;
  justify-content: stretch;
  grid-gap: 24px;
  margin-bottom: 20px;

  & > div {
    grid-column: span 6;
    width: 100%;
    border-radius: 16px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    animation: ${slideUp} 0.5s ease-out;

    &:hover {
      transform: translateY(-5px);
    }
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    & > div {
      grid-column: span 8;
    }
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    grid-gap: 32px;

    & > div {
      grid-column: span 6;
    }
  }

  ${({ theme }) => theme.mediaQueries.md} {
    grid-gap: 32px;

    & > div {
      grid-column: span 6;
    }
  }
`;

const Tooltip = styled.div<{ isTooltipDisplayed: boolean }>`
  display: ${({ isTooltipDisplayed }) => (isTooltipDisplayed ? 'inline-block' : 'none')};
  position: absolute;
  padding: 8px;
  top: -38px;
  right: 0;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.contrast};
  color: ${({ theme }) => theme.colors.invertedContrast};
  border-radius: 8px;
  opacity: 0.9;
  width: 120px;
  animation: ${fadeIn} 0.3s ease-in-out;
`;


const runningLightAnimation = keyframes`
  0% {
    text-shadow: 0 0 2.5px #62d5a3, 0 0 5px #62d5a3, 0 0 7.5px #62d5a3, 0 0 10px #62d5a3;
  }
  50% {
    text-shadow: 0 0 2.5px #62d5a3, 0 0 5px #62d5a3, 0 0 7.5px #62d5a3, 0 0 10px #62d5a3;
  }
  100% {
    text-shadow: 0 0 2.5px #62d5a3, 0 0 5px #62d5a3, 0 0 7.5px #62d5a3, 0 0 10px #62d5a3;
  }
`;
const Title = styled(Heading)`
    animation: ${runningLightAnimation} 2s infinite; /* Alkalmazzuk az animációt */
    color: #fff;
`;
const StyledModalContainer2 = styled(Text)`
  font-size: 14px;

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 16px;
  }
`;

const StyledModalContainer = styled(Box)`
  max-width: 1200px;
  width: 100%;
  background: #000; /* Dark background */
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border: 1px solid ${({ theme }) => theme.colors.text};
  padding: 40px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.5); /* Stronger shadow */
  animation: ${fadeIn} 0.5s ease-out;
`;

const CustomButton = styled(Button)`
   color: black;
   box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
  }
`;

const Lol = styled(Box)`
  margin: 0 25px;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.mediaQueries.md} {
    margin: 0 55px;
  }
`;

const ImageWrapper = styled.div`
  img {
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }
`;

const Donation: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { account } = useWeb3React();
  const { pools: poolsWithoutAutoVault, userDataLoaded } = usePools(account);
  const [stakedOnly, setStakedOnly] = usePersistState(false, { localStorageKey: 'pancake_pool_staked' });
  const [numberOfPoolsVisible, setNumberOfPoolsVisible] = useState(12);
  const [observerIsSet, setObserverIsSet] = useState(false);
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const [viewMode, setViewMode] = usePersistState('TABLE', { localStorageKey: 'pancake_pool_view' });
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOption, setSortOption] = useState('hot');
  const chosenPoolsLength = useRef(0);
  const {
    userData: { cakeAtLastUserAction, userShares },
    fees: { performanceFee },
    pricePerFullShare,
    totalCakeInVault,
  } = useCakeVault();
  const accountHasVaultShares = userShares && userShares.gt(0);
  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false);

  const TooltipComponent = ({ fee }: { fee: number }) => (
    <>
      <Text mb="16px">{t('This bounty is given as a reward for providing a service to other users.')}</Text>
      <Text mb="16px">
        {t(
          'Whenever you successfully claim the bounty, you’re also helping out by activating the Auto VIZSLASWAP Pool’s compounding function for everyone.',
        )}
      </Text>
      <Text style={{ fontWeight: 'bold' }}>
        {t('Auto-Compound Bounty: %fee%% of all Auto VIZSLASWAP pool users pending yield', { fee: fee / 100 })}
      </Text>
    </>
  );

  const copyAddress = () => {
    if (navigator.clipboard && navigator.permissions) {
      navigator.clipboard.writeText('0xbf3B713827d5DA58E25711e746922b0A6233DE86').then(() => displayTooltip());
    } else if (document.queryCommandSupported('copy')) {
      const ele = document.createElement('textarea');
      ele.value = account;
      document.body.appendChild(ele);
      ele.select();
      document.execCommand('copy');
      document.body.removeChild(ele);
      displayTooltip();
    }
  };

  function displayTooltip() {
    setIsTooltipDisplayed(true);
    setTimeout(() => {
      setIsTooltipDisplayed(false);
    }, 1000);
  }

  const [onPresentBountyModal] = useModal(<ModalInfo TooltipComponent={TooltipComponent} />);
  const [onPresentBountyModal2] = useModal(<ModalInfo1 TooltipComponent={TooltipComponent} />);
  const [onPresentBountyModal3] = useModal(<ModalInfo3 TooltipComponent={TooltipComponent} />);
  const [onPresentBountyModal4] = useModal(<ModalInfo4 />);
  const [onPresentBountyModal5] = useModal(<ModalInfo5 />);

  return (
    <>
      <PageHeader>
        <Flex justifyContent="space-between" flexDirection={['column', null, null, 'row']}>
          <Flex flex="1" flexDirection="column" mr={['8px', 0]}>
            <Title as="h1" scale="xxl"  mb="24px" >
              {t('Charity')}
            </Title>
            <Heading scale="md" color="text">
              {t('Help us support animal shelters in Hungary.')}
            </Heading>
          </Flex>
        </Flex>
      </PageHeader>

      <Page>
        <StyledModalContainer>
          <Flex flex="1" flexDirection="column" justifyContent="center" alignItems="center" mr={['8px', 0]}>
            <Lol>
              <Flex flex="1" flexDirection="column" justifyContent="center" alignItems="center">
                <Heading scale="xl" color="secondary" mt="20px" mb="10px">
                  {t('How can you help?')}
                </Heading>
              </Flex>
              <Flex flex="1" flexDirection="column" justifyContent="center" alignItems="center">
                <Flex justifyContent="center" alignItems="center">
                  <Text color="text" fontSize="15px" mb="10px">
                    {t('Send any amount you are comfortable with to the following address:')}
                  </Text>
                </Flex>
                <Flex justifyContent="center" alignItems="center" mr={['8px', 0]} mb="10px">
                  <ImageWrapper>
                    <img src="/images/wallet/1.png" alt="1" width="35px" height="35px" />
                  </ImageWrapper>
                  <ImageWrapper>
                    <img src="/images/wallet/black.png" alt="black" width="4px" height="4px" />
                  </ImageWrapper>
                  <ImageWrapper>
                    <img src="/images/wallet/4.png" alt="4" width="35px" height="35px" />
                  </ImageWrapper>
                  <ImageWrapper>
                    <img src="/images/wallet/black.png" alt="black" width="4px" height="4px" />
                  </ImageWrapper>
                  <ImageWrapper>
                    <img src="/images/wallet/2.png" alt="2" width="35px" height="35px" />
                  </ImageWrapper>
                </Flex>
                <Flex justifyContent="center" alignItems="center">
                  <Text color="primary" fontSize="12px" mb="3px" textAlign="center">
                    {t('Copy BEP-20 address:')}
                  </Text>
                  <IconButton variant="text" onClick={copyAddress}>
                    <CopyIcon color="primary" width="24px" />
                  </IconButton>
                </Flex>
                <StyledModalContainer2 color="text">
                  {t('0xbf3B713827d5DA58E25711e746922b0A6233DE86')}
                </StyledModalContainer2>
                <Tooltip isTooltipDisplayed={isTooltipDisplayed}>{t('Copied')}</Tooltip>
              </Flex>
              <Flex justifyContent="center" alignItems="center">
                <Image src="/images/pixel/0909.png" width={290} height={168} />
              </Flex>
              <Flex flex="1" flexDirection="column" justifyContent="center" alignItems="center">
                <Heading scale="xl" color="secondary" mt="20px" mb="10px">
                  {t('Charity images')}
                </Heading>
              </Flex>
              <Flex flex="1" flexDirection="column" justifyContent="center" alignItems="center">
                <Text color="text" fontSize="15px" mb="10px">
                  {t('Pictures that you can share')}
                </Text>
              </Flex>
              <Flex flex="1" justifyContent="center" alignItems="center" mb="10px">
                <CustomButton onClick={onPresentBountyModal5} scale="sm" variant="secondary" id="clickClaimVaultBounty" mr="10px">
                  <Text color="text" fontSize="15px">
                    {t('Charity 5')}
                  </Text>
                </CustomButton>
                <CustomButton onClick={onPresentBountyModal4} scale="sm" variant="secondary" id="clickClaimVaultBounty" mr="10px">
                  <Text color="text" fontSize="15px">
                    {t('Charity 4')}
                  </Text>
                </CustomButton>
                <CustomButton onClick={onPresentBountyModal3} scale="sm" variant="secondary" id="clickClaimVaultBounty">
                  <Text color="text" fontSize="15px">
                    {t('Charity 3')}
                  </Text>
                </CustomButton>
              </Flex>
              <Flex flex="1" justifyContent="center" alignItems="center" mb="30px">
                <CustomButton onClick={onPresentBountyModal} scale="sm" variant="secondary" id="clickClaimVaultBounty" mr="10px">
                  <Text color="text" fontSize="15px">
                    {t('Charity 2')}
                  </Text>
                </CustomButton>
                <CustomButton onClick={onPresentBountyModal2} scale="sm" variant="secondary" id="clickClaimVaultBounty">
                  <Text color="text" fontSize="15px">
                    {t('Charity 1')}
                  </Text>
                </CustomButton>
              </Flex>
              <Cards>
                <YoutubeEmbed embedId="s9pzxEkZAg0" />
                <Flex flex="1" justifyContent="center" alignItems="center" mb="10px">
                  <Text color="text" fontSize="14px" mb="30px">
                    {t('…to support a good cause. And that is helping dogs in need. That’s why we primarily support dog shelters. And you can also take part of it, because with every HVI transaction* you contribute to this honorable goal.')}
                  </Text>
                </Flex>
              </Cards>
            </Lol>
          </Flex>
        </StyledModalContainer>
      </Page>
    </>
  );
};

export default Donation;



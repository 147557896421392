import React, { FC } from "react";
import { Button } from "@pancakeswap/uikit";
import * as S from "./GameOver.style";

interface GameOverProps { 
  isWin: boolean;
  newGame: () => void; 
  nextLevel: () => void; // Új prop hozzáadása
}

const GameOver: FC<GameOverProps> = ({ isWin, newGame, nextLevel }) => {
  return (
    <S.Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 1, duration: 0.5 }}
    >
      <div>
        <h2>{isWin ? "Congratulations!" : "Game Over"}</h2>
        {isWin ? (
          <>
            <Button onClick={nextLevel}>Next Level</Button> {/* Következő szint gomb */}
            <Button onClick={newGame}>Play Again</Button>
          </>
        ) : (
          <Button onClick={newGame}>Try Again</Button>
        )}
      </div>
    </S.Wrapper>
  );
};

export default GameOver;

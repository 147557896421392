import React, { useState } from 'react';
import { Button } from '@pancakeswap/uikit';

const EmojiPicker = ({ setNewMessage, handleSetImage, newMessage }) => {
  const [isEmojiMode, setIsEmojiMode] = useState(true);  // Toggle between Emoji and Sticker

  const emojis = ['😊', '😂', '😢', '😍', '😎', '🎉', '🎁', '😜', '🤔', '😇', '🙃', '😅', '🤣', '🤩', '😡', '🥳', '🤯', '😱', '🤗', '😏', '😴', '🤤', '😷', '🤒', '🤕', '🤮', '🤑', '😈', '👻', '💩', '😺', '👀', '💪', '🙏', '👏', '🙌', '👐', '✌️', '👍', '👎', '👊', '🤟'];
  const stickers = [
    'images/pixel/L/10.png',
    'images/pixel/L/28.png',
    'images/pixel/L/0.png',
    'images/pixel/L/1.png',
    'images/pixel/L/18.png',
    'images/pixel/L/15.png',
    'images/pixel/L/19.png',
    'images/pixel/L/21.png',
    'images/pixel/L/7.png',
    'images/pixel/L/11.png',
    'images/pixel/L/8.png',
    'images/pixel/L/29.png',
    'images/pixel/L/30.png',
    'images/pixel/L/31.png',
    'images/pixel/L/20.png',
    'images/pixel/L/6.png',
    'images/pixel/L/9.png',
    'images/pixel/L/32.png',
    'images/pixel/L/33.png',
    'images/pixel/L/34.png',
    'images/pixel/L/27.png',
    'images/pixel/L/2.png',
    'images/pixel/L/3.png',
    'images/pixel/L/4.png',
    'images/pixel/L/5.png',
    'images/pixel/L/16.png',
    'images/pixel/L/24.png',
    'images/pixel/L/25.png',
    'images/pixel/L/22.png',
    'images/pixel/L/14.png',
    'images/pixel/L/23.png',
    'images/pixel/L/26.png',
    'images/pixel/L/12.png',
    'images/pixel/L/13.png',
    'images/pixel/L/17.png',
  ];

  const handleToggleMode = () => {
    setIsEmojiMode(!isEmojiMode);  // Switch between Emoji and Sticker mode
  };

  return (
    <div style={styles.emojiPicker}>
      {/* Toggle between Emoji and Sticker */}
      <Button onClick={handleToggleMode} style={styles.toggleButton}>
        {isEmojiMode ? 'Switch to Stickers' : 'Switch to Emojis'}
      </Button>

      <div style={styles.pickerContainer}>
        {isEmojiMode ? (
          emojis.map((emoji) => (
            <Button
              key={emoji}
              onClick={() => setNewMessage(`${newMessage}${emoji}`)}
              style={styles.emojiButton}
            >
              {emoji}
            </Button>
          ))
        ) : (
          stickers.map((sticker) => (
            <Button
            key={sticker}
            onClick={() => handleSetImage(sticker)}  // Csak beállítjuk a kiválasztott képet
            style={styles.imageButton}
          >
            <img src={sticker} alt="sticker" style={{ width: '50px', height: '50px' }} />
          </Button>
          ))
        )}
      </div>
    </div>
  );
};

const styles = {
  emojiPicker: {
    marginBottom: '30px',
    marginTop: '80px',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    border: '2px solid #5a9f98',
    borderRadius: '8px',
    backgroundColor: '#000',
    color: '#fff',
  },
  toggleButton: {
    fontSize: '14px',
    margin: '10px 0',
    padding: '5px',
    cursor: 'pointer',
    backgroundColor: '#5a9f98',
    border: 'none',
    borderRadius: '4px',
    color: '#fff',
  },
  pickerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '5px',  // Space between emojis and stickers
  },
  emojiButton: {
    fontSize: '24px',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    padding: '10px',
    display: 'inline-block',
    width: '45px',  // Uniform width
    height: '45px',
    textAlign: 'center',
  },
  imageButton: {
    background: 'none',
    border: 'none',
    padding: '0',
    display: 'inline-block',
    width: '55px',
    height: '55px',
    cursor: 'pointer',
  },
};

export default EmojiPicker;

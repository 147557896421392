import React,{ FC } from "react";
import * as S from "./Tile.style";
import { candies } from "../../assets";
import gold from "../../assets/candes/gold.png"; // Törhetetlen csempe képének importálása
import { UNBREAKABLE_TILE_ID } from "../Board/UnbreakableTile.utils"; // Törhetetlen csempe azonosító

interface TileProps {
  dragStart: (e: React.DragEvent<HTMLDivElement>) => void;
  dragOver: (e: React.DragEvent<HTMLDivElement>) => void;
  dragEnd: (e: React.DragEvent<HTMLDivElement>) => void;
  tile: number;
  id: string;
  isPlayable: boolean;
}
 
const Tile: FC<TileProps> = ({
  dragStart,
  dragOver,
  dragEnd,
  tile,
  id,
  isPlayable,
}) => {
  return (
    <S.Cell
      draggable={isPlayable}
      onDragStart={dragStart}
      onDragOver={dragOver}
      onDragEnd={dragEnd}
      id={id}
      playable={isPlayable}
    >
      {tile === UNBREAKABLE_TILE_ID ? (
        <img width="50" src={gold} alt="Unbreakable Tile" /> // Törhetetlen csempe képe
      ) : tile ? (
        <img width="50" src={candies[tile]} alt="" />
      ) : null}
    </S.Cell>
  );
};

export default Tile;
import React, { useState } from 'react';
import { useWeb3React } from '@web3-react/core/core/core';
import styled from 'styled-components';
import { Modal, Flex, IconButton,ChevronLeftIcon, ChevronRightIcon } from '@pancakeswap/uikit';
import { useTranslation } from 'contexts/Localization';
import useTheme from 'hooks/useTheme';
import useToast from 'hooks/useToast';
 
interface ModalInfoProps {
  onDismiss?: () => void;
  TooltipComponent: React.ElementType;
}

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  height: auto;
  margin: 0 auto;

  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
`;

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
`;

const ArrowButton = styled(IconButton)`
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: white;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 32px;
    height: 32px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  ${({ theme }) => theme.mediaQueries.md} {
    width: 40px;
    height: 40px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    width: 48px;
    height: 48px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const ModalInfo: React.FC<ModalInfoProps> = ({ onDismiss }) => {
  const { t } = useTranslation();
  const { account } = useWeb3React();
  const { theme } = useTheme();
  const { toastError, toastSuccess } = useToast();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [
    '/images/charity/charity2/1.jpg',
    '/images/charity/charity2/2.jpg',
    '/images/charity/charity2/6.png',
    '/images/charity/charity2/3.jpg',
    '/images/charity/charity2/5.jpg',
  ];

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <Modal title={t('Charity 2')} onDismiss={onDismiss}>
      <CarouselContainer>
        <ArrowButton onClick={handlePrevClick}>
          <ChevronLeftIcon />
        </ArrowButton>
        <ImageWrapper>
          <img src={images[currentImageIndex]} alt={`Charity ${currentImageIndex + 1}`} />
        </ImageWrapper>
        <ArrowButton onClick={handleNextClick}>
          <ChevronRightIcon />
        </ArrowButton>
      </CarouselContainer>
    </Modal>
  );
};

export default ModalInfo;

import React from 'react'
import { Modal, Text, Flex, } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'

interface ModalInfoProps {
  onDismiss?: () => void
  TooltipComponent: React.ElementType
}

const ModalInfo: React.FC<ModalInfoProps> = ({ onDismiss }) => {
  const { t } = useTranslation()

  return (
    <Modal title={t('About LiveGreen Coin')} onDismiss={onDismiss}>

          <Flex alignItems="left" justifyContent="left">
  <Text fontSize="15px" >  {t('LiveGreen Coin is european initiative to reform the crypto currency market with special emphasis on environmental and green aspects.Behind the project are HAPPY PANDA Handels GmbH, founded in Austria, and the international companies attached to that, what’s more, the token even functions as a share in HAPPY PANDA Handels GmbH. In addition, it is based on a tender ecosystem, fron which the non-profit and for-profit tenders can benefit, driven partly by investors (DAO = Decentralized Autonomous Organization) and judged partly by the management of LGC. Aim to create a social platform that is a combination of a commercial platform (marketplace) and a social profile. This is a crypto - friendly platform, where ads are filtered through pre-inspection to prevent many unpleasant abuses. We intend to create a platform where the user can actually feel safe, and, of course, the LGC Token will be the basis of this.')} </Text>
      </Flex>

    </Modal>
  )
}

export default ModalInfo
import React from 'react'
import styled, { keyframes } from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { escapeRegExp } from '../../utils/index3'
 
const hoverEffect = keyframes`
  0% {
    transform: translateX(0);
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
  }
  100% {
    transform: translateX(0);
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.7);
  }
`;

const activeEffect = keyframes`
  0% {
    transform: translateX(0);
    box-shadow: 0 0 5px rgba(83, 178, 140, 0.3);
  }
  100% {
    transform: translateX(0);
    box-shadow: 0 0 15px rgba(83, 178, 140, 0.7);
  }
`;

const StyledInput = styled.input<{ error?: boolean; fontSize?: string; align?: string; isActive?: boolean }>`
  color: ${({ error, theme }) => (error ? theme.colors.failure : theme.colors.text)};
  width: 0;
  position: relative;
  font-weight: 500;
  outline: none;
  border: none;
  flex: 1 1 auto;
  background-color: transparent;
  font-size: ${({ fontSize }) => fontSize || '16px'};
  text-align: ${({ align }) => align || 'left'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px;
  -webkit-appearance: textfield;
  transition: all 0.3s ease;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.primary};
  }

  animation: ${({ isActive }) => (isActive ? activeEffect : hoverEffect)} 0.5s forwards;

  &:hover {
    animation: ${({ isActive }) => (isActive ? activeEffect : hoverEffect)} 0.5s forwards;
  }

  &:focus {
    animation: ${activeEffect} 0.5s forwards;
    background-color: ${({ theme }) => theme.colors.background};
  }
`;


const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group

export const Input = React.memo(function InnerInput({
  value,
  onUserInput,
  placeholder,
  ...rest
}: {
  value: string | number
  onUserInput: (input: string) => void
  error?: boolean
  fontSize?: string
  align?: 'right' | 'left'
} & Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'>) {
  const enforcer = (nextUserInput: string) => {
    if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
      onUserInput(nextUserInput)
    }
  }

  const { t } = useTranslation()

  return (
    <StyledInput
      {...rest}
      value={value}
      onChange={(event) => {
        // replace commas with periods, because we exclusively uses period as the decimal separator
        enforcer(event.target.value.replace(/,/g, '.'))
      }}
      // universal input options
      inputMode="decimal"
      title={t('Token Amount')}
      autoComplete="off"
      autoCorrect="off"
      // text-specific options
      type="text"
      pattern="^[0-9]*[.,]?[0-9]*$"
      placeholder={placeholder || '0.0'}
      minLength={1}
      maxLength={79}
      spellCheck="false"
    />
  )
})

export default Input

import { ethers } from 'ethers';
import { ChainId } from '@pancakeswap/sdk';

const NETWORK_URLS: { [chainId in ChainId]: string[] } = {
  [ChainId.MAINNET]: [
    'https://bsc-dataseed1.defibit.io',
    'https://bsc-dataseed2.defibit.io',
    'https://bsc-dataseed3.defibit.io',
    'https://bsc-dataseed1.ninicoin.io',
    'https://bsc-dataseed2.ninicoin.io',
    'https://bsc-dataseed3.ninicoin.io',
    'https://rpc.ankr.com/bsc',
    'https://bscrpc.com',
    'https://bsc-dataseed4.binance.org',  // Alternatív Binance node
    'https://rpc-bsc.bnb48.club',
    'https://bsc.nodereal.io',
    'https://rpc-bsc.42l.club',
    'https://bsc.publicnode.com'
  ],
  [ChainId.TESTNET]: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
};

// Választ egy véletlenszerű URL-t
const getRandomUrl = (chainId: ChainId): string => {
  const urls = NETWORK_URLS[chainId];
  return urls[Math.floor(Math.random() * urls.length)];
};

// Hoz létre egy providert, újrapróbál ha hiba lép fel
export const getProvider = (chainId: ChainId): ethers.providers.JsonRpcProvider => {
  const urls = [...NETWORK_URLS[chainId]];
  
  const createProvider = (): ethers.providers.JsonRpcProvider | null => {
    if (urls.length === 0) return null;
    
    const url = urls.splice(Math.floor(Math.random() * urls.length), 1)[0];
    const provider = new ethers.providers.JsonRpcProvider(url);
    
    provider.on("error", () => createProvider());  // Automatikus újrapróbálkozás hiba esetén
    return provider;
  };

  return createProvider() ?? new ethers.providers.JsonRpcProvider(getRandomUrl(chainId));
};

// Exportált CHAIN_ID a kódban máshol történő használathoz
export const CHAIN_ID = parseInt(process.env.NEXT_PUBLIC_CHAIN_ID || `${ChainId.MAINNET}`, 10);
import React, { FC, useEffect, useState } from "react";
import * as S from "./GameInfoBoard.styles";
import { displayTime } from "./GameInfoBoard.utils";
import { CandyData } from "../../data/candyColors";
import { candies } from "../../assets";

interface GameInfoBoardProps {
  moves: number;
  tilesToGo: number;
  tileToSearch: CandyData | null;
  resetTime: boolean;
  level: number; // Új prop a szintszámhoz
}

let timeInterval: NodeJS.Timeout;

const GameInfoBoard: FC<GameInfoBoardProps> = ({
  moves,
  tilesToGo,
  tileToSearch,
  resetTime,
  level, // Új prop destructuring
}) => {
  const [time, setTime] = useState(0);

  const startCount = (): void => {
    timeInterval = setInterval(() => {
      setTime(prev => prev + 1);
    }, 1000);
  };

  const stopCount = (): void => clearInterval(timeInterval);

  useEffect(() => {
    if (resetTime) setTime(0);
  }, [resetTime]);

  useEffect(() => {
    startCount();
    return () => stopCount();
  }, []);

  return (
    <S.Wrapper>
      <p>Level: {level}</p> {/* Szintszám megjelenítése */}
      <p>Moves: {moves}</p>
      <p>Time: {displayTime(time)}</p>
      {tileToSearch?.tileId && (
        <p>
          To go:{" "}
          <img
            src={candies[tileToSearch.tileId]}
            width="30"
            alt="Tile to search"
          />
          <span className="lover"> x </span> {tilesToGo}
        </p>
      )}
    </S.Wrapper>
  );
};

export default GameInfoBoard;

import React from 'react'
import { Link } from 'react-router-dom'
import { Box , Flex , Button, Skeleton, Text} from '@pancakeswap/uikit'
import styled, { keyframes } from 'styled-components'
import Balance from 'components/Balance'
import { usePriceATALIRABusd, usePricebabyBusd, usePriceBnbBusd, usePriceBTCBusd, usePricebuildBusd, usePriceBVCBusd, usePriceCakeBusd, usePriceETCBusd, usePriceETHBusd, usePriceEXZOBusd, usePriceHVIBusd, usePriceLameaBusd, usePriceLGCBusd, usePriceliraBusd, usePriceLTCBusd, usePriceskeletonBusd, usePricetripBusd } from 'state/farms/hooks'
import { useTranslation } from 'contexts/Localization'
import Page from '../PageChart'
import App from './App'

const ButtonText = styled(Text)`
  display: none;
  ${({ theme }) => theme.mediaQueries.xs} {
    display: block;
  }
`
const ImageWrapper = styled.div`
flex: none;
margin: 0 auto;
margin-right: 5px;
${({ theme }) => theme.mediaQueries.md} {
  flex: none;
  margin: 0 auto;
  margin-right: 5px;

}
`
const Partnership: React.FC = () => {
  const { t } = useTranslation()
  const bnbprice0 = usePriceBnbBusd()
  const bnbprice = bnbprice0.toNumber()
  const hviprice0 = usePriceHVIBusd()
  const hviprice = hviprice0.toNumber()
  const lameaprice0 = usePriceLameaBusd()
  const lameaprice = lameaprice0.toNumber()
  const ehtprice0 = usePriceETHBusd()
  const ethprice = ehtprice0.toNumber()
  const lgcprice0 = usePriceLGCBusd()
  const lgcprice = lgcprice0.toNumber()
  const etcprice0 = usePriceETCBusd()
  const etcprice = etcprice0.toNumber()
  const ltcprice0 = usePriceLTCBusd()
  const ltcprice = ltcprice0.toNumber()
  const bvcprice0 = usePriceBVCBusd()
  const bvcprice = bvcprice0.toNumber()
  const buildprice0 = usePricebuildBusd()
  const buildprice = buildprice0.toNumber()
  const liraprice0 = usePriceliraBusd()
  const liraprice  = liraprice0.toNumber()
 const vizslaprice = usePriceCakeBusd()
 const vizslaprice0 = vizslaprice.toNumber()
 const exzoprice0 = usePriceEXZOBusd()
 const exzoprice = exzoprice0.toNumber()
 const ataliraprice0 = usePriceATALIRABusd()
 const ataliraprice = ataliraprice0.toNumber()
 const tripprice0 = usePricetripBusd()
 const tripprice = tripprice0.toNumber()
 const skeletonprice0 = usePriceskeletonBusd()
 const skeletonprice = skeletonprice0.toNumber()
 const babyprice0 = usePricebabyBusd()
 const babyprice = babyprice0.toNumber()
 const btc0 = usePriceBTCBusd()
 const btc = btc0.toNumber()

  return (
    <Page >  
      <Flex   alignItems="center" >
      <Button as={Link} width={120} mr="7px" height={50} variant="tertiary" to="/charts">
      <ImageWrapper>
        <img src="/images/wallet/2.png" alt="ifo bunny" width="27px" height="27px"/>
      </ImageWrapper>
      <Flex flexDirection="column" alignItems="left" >
      <ButtonText color="primary" bold fontSize="12px">
          {t('VIZSLASWAP')} 
        </ButtonText>
        {vizslaprice0 ? (
          <Balance fontSize="15px" prefix="$" decimals={5} value={vizslaprice0} />
        ) : (
          <Skeleton width={60} height={16} />
        )}   
         </Flex >
      </Button>
      <Button as={Link} width={120}  height={50} variant="tertiary" to="/BNB-0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c">
      <ImageWrapper> 
        <img src="/images/wallet/1.png" alt="ifo bunny" width="27px" height="27px"/>
      </ImageWrapper>
      <Flex flexDirection="column" alignItems="left" >
      <ButtonText color="primary" bold fontSize="12px">
          {t('BNB')}
        </ButtonText>
        {bnbprice ? (
          <Balance fontSize="15px" prefix="$" decimals={2} value={bnbprice} />
        ) : (
          <Skeleton width={60} height={16} />
        )}
        </Flex >
      </Button>
      </Flex> 
      <App  />   
      <iframe  title="gr" width="100%"  height="800" frameBorder="0" scrolling="no" src="https://coinbrain.com/embed/bnb-0xF15c8d610E02602caAA9959BF5ecfEb8B3D229bE?theme=custom&accent=3ad5a3&padding=16&background=000000&chart=1&trades=1&simple=0"/>
      <Skeleton width={0} height={50} />

      </Page> 
     )
    }   



export default Partnership

import { Connector } from '@web3-react/types'
import { Web3ReactHooks } from '@web3-react/core/core/core'

export enum ConnectionType {
  INJECTED = 'injected',
  COINBASE_WALLET = 'Walletlink',
  WALLET_CONNECT = 'walletconnect',
  NETWORK = 'NETWORK',
   GNOSIS_SAFE = 'GNOSIS_SAFE',
   BitgetConnect = "BitgetConnect",
 }

export interface Connection {
  connector: Connector
  hooks: Web3ReactHooks
  type: ConnectionType
}

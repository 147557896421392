import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import {
  Flex,
  Button,
  LogoutIcon,
  UserMenu as UIKitUserMenu,
  UserMenuItem,
  useModal,
} from '@pancakeswap/uikit'
import useAuth from 'hooks/useAuth'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { FetchStatus, useGetBnbBalance } from 'hooks/useTokenBalance'
import { useTranslation } from 'contexts/Localization'
import { createClient } from '@supabase/supabase-js'
import WalletModal, { WalletView, LOW_BNB_BALANCE } from './WalletModal'
import WalletModal2, { WalletView2, LOW_BNB_BALANCE2 } from './WalletModal2'
import WalletUserMenuItem from './WalletUserMenuItem'

// Create Supabase client
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY
const supabase = createClient(supabaseUrl, supabaseKey)

const UserMenu = () => {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const { logout } = useAuth()
  const { balance, fetchStatus } = useGetBnbBalance()
  const history = useHistory()
  const [onPresentWalletModal] = useModal(<WalletModal2 initialView={WalletView2.WALLET_INFO} />)
  const [onPresentHarvestModal] = useModal(<WalletModal initialView={WalletView.HARVEST} />)
  const [onPresentTransactionModal] = useModal(<WalletModal2 initialView={WalletView2.TRANSACTIONS} />)
  const hasLowBnbBalance = fetchStatus === FetchStatus.SUCCESS && balance.lte(LOW_BNB_BALANCE)

  // State to hold user data
  const [userData, setUserData] = useState({ name: '', number: '', avatar: '' })

  const handleChatClick = () => {
    history.push('/chat')
  }

  // Fetch name and number from Supabase or from localStorage
  useEffect(() => {
    const fetchUserData = async () => {
      if (!account) return

      // Check if user data is already in localStorage
      const localStorageData = localStorage.getItem(`userData_${account}`)
      if (localStorageData) {
        // If data exists in localStorage, use it
        const storedData = JSON.parse(localStorageData)
        setUserData(storedData)
        return
      }

      // If no data in localStorage, fetch from Supabase
      try {
        const { data: userData, error } = await supabase
          .from('user_data')
          .select('name, number')
          .eq('account', account)
          .single();  // Fetch single user record

        if (error) {
          console.error('Error fetching data from Supabase:', error)
        } else if (userData) {
          const name = userData?.name || ''
          const number = userData?.number || ''

          // Pad the number correctly for the image URL
          const paddedNumber = number < 10 
            ? `0${number}`  // For numbers less than 10, pad with 1 zero (e.g., 02.jpg)
            : `${number}`.padStart(3, '0')  // For numbers >= 10, pad to 3 digits (e.g., 011.jpg for 11)

          const avatarUrl = `images/pixel/${paddedNumber}.jpg`

          const newUserData = { name, number, avatar: avatarUrl }

          // Save data in state and localStorage
          setUserData(newUserData)
          localStorage.setItem(`userData_${account}`, JSON.stringify(newUserData))
        }
      } catch (error) {
        console.error('Unexpected error fetching user data:', error)
      }
    }

    fetchUserData()
  }, [account])

  // Display the full name if available, otherwise fallback to shortened account
  const displayName = userData.name 
    ? userData.name  // Show full name if available
    : `${account?.substring(0, 2)}...${account?.substring(account.length - 4)}`  // Otherwise, show shortened account

  if (!account) {
    return <ConnectWalletButton scale="sm" />
  }

  return (
    <>
      <Button variant="gold" onClick={handleChatClick} mr="10px" style={{ height: '30px', width: '50px' }}>
        Chat
      </Button>
      <UIKitUserMenu 
        text={displayName}  // Pass the name or shortened address here
        avatarSrc={userData.avatar || 'images/pixel/200.png'}
      >
        <WalletUserMenuItem hasLowBnbBalance={hasLowBnbBalance} onPresentWalletModal={onPresentWalletModal} />
        <UserMenuItem as="button" onClick={onPresentTransactionModal}>
          {t('Activity')}
        </UserMenuItem>
        <UserMenuItem as="button" onClick={onPresentHarvestModal}>
          {t('Harvest')}
        </UserMenuItem>
        <UserMenuItem as="button" onClick={logout}>
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            {t('Disconnect')}
            <LogoutIcon />
          </Flex>
        </UserMenuItem>
      </UIKitUserMenu>
    </>
  )
}

export default UserMenu

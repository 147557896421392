import _ from "lodash";

// Definiáljuk a törhetetlen csempét jelképező konstans értéket
const UNBREAKABLE_TILE_ID = -1;

export const generateUnbreakableTiles = (
  board: number[][],
  count: number
): number[][] => {
  const newBoard = _.cloneDeep(board);
  const boardSize = newBoard.length * newBoard[0].length; // Tábla teljes mérete
  
  // Ellenőrizzük, hogy nem kérünk-e több törhetetlen csempét, mint a tábla összesen
  const tilesToPlace = Math.min(count, boardSize);
  let tilesPlaced = 0;

  while (tilesPlaced < tilesToPlace) {
    const row = Math.floor(Math.random() * newBoard.length);
    const col = Math.floor(Math.random() * newBoard[0].length);

    // Csak akkor helyezünk le törhetetlen csempét, ha az adott helyen még nincs ilyen
    if (newBoard[row][col] !== UNBREAKABLE_TILE_ID) {
      newBoard[row][col] = UNBREAKABLE_TILE_ID;
      tilesPlaced++;
    }
  }
  return newBoard;
};


// Törhetetlen csempe azonosítójának ellenőrzése
export const isUnbreakableTile = (tile: number): boolean => {
  return tile === UNBREAKABLE_TILE_ID;
};

// Törhetetlen csempe hozzáadásához használt azonosító exportálása
export { UNBREAKABLE_TILE_ID };

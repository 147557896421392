import React from 'react';
import styled, { keyframes } from 'styled-components';
import Page from '../Layout/Page';

// Define keyframes for the puzzle animation
const pieceAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

const Wrapper = styled(Page)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const ImageWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 50px);
  grid-template-rows: repeat(4, 50px);
  gap: 0;
  width: 200px;  // Ensure the container is always 200px wide
  height: 200px; // Ensure the container is always 200px tall
  min-width: 200px;
  max-width: 200px;
  min-height: 200px;
  max-height: 200px;

  div {
    animation: ${pieceAnimation} 1s ease-in-out forwards;
    position: relative;
    width: 50px;
    height: 50px;
    overflow: hidden;
  }

  div:nth-child(1) {
    animation-delay: 0.1s;
  }
  div:nth-child(2) {
    animation-delay: 0.2s;
  }
  div:nth-child(3) {
    animation-delay: 0.3s;
  }
  div:nth-child(4) {
    animation-delay: 0.4s;
  }
  div:nth-child(5) {
    animation-delay: 0.5s;
  }
  div:nth-child(6) {
    animation-delay: 0.6s;
  }
  div:nth-child(7) {
    animation-delay: 0.7s;
  }
  div:nth-child(8) {
    animation-delay: 0.8s;
  }
  div:nth-child(9) {
    animation-delay: 0.9s;
  }
  div:nth-child(10) {
    animation-delay: 1.0s;
  }
  div:nth-child(11) {
    animation-delay: 1.1s;
  }
  div:nth-child(12) {
    animation-delay: 1.2s;
  }
  div:nth-child(13) {
    animation-delay: 1.3s;
  }
  div:nth-child(14) {
    animation-delay: 1.4s;
  }
  div:nth-child(15) {
    animation-delay: 1.5s;
  }
  div:nth-child(16) {
    animation-delay: 1.6s;
  }

  img {
    position: absolute;
    width: 200px;
    height: 200px;
    min-width: 200px;
    max-width: 200px;
    min-height: 200px;
    max-height: 200px;
  }
`;

const puzzleImage = '/images/pixel/LogoDark.png';

const pieces = [
  { top: 0, left: 0 },
  { top: 0, left: -50 },
  { top: 0, left: -100 },
  { top: 0, left: -150 },
  { top: -50, left: 0 },
  { top: -50, left: -50 },
  { top: -50, left: -100 },
  { top: -50, left: -150 },
  { top: -100, left: 0 },
  { top: -100, left: -50 },
  { top: -100, left: -100 },
  { top: -100, left: -150 },
  { top: -150, left: 0 },
  { top: -150, left: -50 },
  { top: -150, left: -100 },
  { top: -150, left: -150 },
];

const generateKey = (index) => `puzzle-piece-${index}`;

const PageLoader: React.FC = () => {
  return (
    <Wrapper>
      <ImageWrapper>
        {pieces.map((piece, index) => (
          <div key={generateKey(index)}>
            <img
              src={puzzleImage}
              alt="puzzle piece"
              style={{
                top: `${piece.top}px`,
                left: `${piece.left}px`,
              }}
            />
          </div>
        ))}
      </ImageWrapper>
    </Wrapper>
  );
};

export default PageLoader;

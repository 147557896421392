import React, { useState, useEffect, useCallback } from 'react';
import { ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core/core/core';
import { useHistory } from 'react-router-dom'; // Import for routing
import styled from 'styled-components';
import contractAbi from './Privategift.json'; // Adjust the import if needed

const contractAddress = "0x2E9Adf161a15D84363d322BF7C5eAE9F789a9E80";

const ImageContainer = styled.div`
  display: flex;
  margin-left: 10px;
    margin-top: 3px;
  justify-content: center;
  align-items: center;
  background-color: #000;
`;

const StyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const StyledImage = styled.img`
  width: 30px;  /* Image size small like an emoji */
  height: 30px;
`;

const GiftImagePage: React.FC = () => {
  const { account } = useWeb3React(); // Using Web3 context to get the user's account
  const [provider, setProvider] = useState<ethers.providers.Web3Provider | null>(null);
  const [contract, setContract] = useState<ethers.Contract | null>(null);
  const [imageSrc, setImageSrc] = useState<string>('/images/pixel/P/00.png'); // Default image
  const [gifts, setGifts] = useState<any[]>([]); // Store gifts information
  const history = useHistory(); // For navigation

  // Initialize provider and contract when account changes
  useEffect(() => {
    const initializeProviderAndContract = async () => {
      if (account) {
        const providerInstance = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(providerInstance);
        const contractInstance = new ethers.Contract(contractAddress, contractAbi, providerInstance.getSigner());
        setContract(contractInstance);
      }
    };
    initializeProviderAndContract();
  }, [account]);

  // Fetch the gifts and update the image accordingly
  const fetchGifts = useCallback(async () => {
    if (!account || !contract) return;

    try {
      // Fetch last 10 gifts from the contract
      const lastGifts = await contract.getLastTenGifts(account);
      setGifts(lastGifts);

      // Determine the image based on the number of gifts
      let imageIndex = '00'; // Default image if no gifts

      const totalGifts = lastGifts.length;

      if (totalGifts > 0 && totalGifts <= 10) {
        imageIndex = `0${totalGifts}`; // Image based on number of gifts (1-9)
      } else if (totalGifts > 10) {
        imageIndex = '10'; // Max image for 10 or more gifts
      }

      setImageSrc(`/images/pixel/P/${imageIndex}.png`);
      
    } catch (error) {
      console.error('Error fetching gifts:', error);
    }
  }, [account, contract]);

  // Fetch gifts when account or contract changes
  useEffect(() => {
    if (account && contract) {
      fetchGifts();
    }
  }, [account, contract, fetchGifts]);

  // Navigate to the /gift page
  const goToGiftPage = () => {
    history.push('/gift');
  };

  return (
    <ImageContainer>
      <StyledButton onClick={goToGiftPage}>
        <StyledImage src={imageSrc} alt="Gift image" />
      </StyledButton>
    </ImageContainer>
  );
};

export default GiftImagePage;
